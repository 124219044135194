import React, { useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { changeFormData, changeSocData } from "../../store/actions"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Row,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
} from "reactstrap"
import UndoRedo from "./UndoRedo"

const QuestionHours = props => {
  const [jobHoursPerWeek, setJobHoursPerWeek] = useState(props.defaultHours)
  const { changeFormData, socForm } = props

  const handleClick = () => {
    let res = { ...socForm }
    if (!res.firstQuestion) res.firstQuestion = 'jobHoursPerWeek'
    res.jobHoursPerWeek = jobHoursPerWeek
    changeFormData(res)
    return
  }

  return (
    <Card className="text-center w3-animate-right mb-0 border">
      <CardBody className="p-5">
        <Row>
          <Col>
            <Form
              onSubmit={e => {
                e.preventDefault()
                handleClick()
              }}
            >
              <React.Fragment>
                <div className="form-group">
                  <h3 className="text-primary mb-3">
                    How many hours per week is the job?
                  </h3>

                  <div>
                    <Row className="d-flex justify-content-center">
                      <div style={{ width: "170px" }}>
                        <InputGroup>
                          <Input
                            className="form-control-lg text-muted text-left pl-4 pt-4 pb-4 bg-light border-0"
                            type="number"
                            step="0.5"
                            min="1"
                            max="100"
                            defaultValue={props.defaultHours}
                            id="example-number-input"
                            onChange={e =>
                              setJobHoursPerWeek(parseFloat(e.target.value))
                            }
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText className="text-white bg-primary border-0">
                              {" "}
                              hrs/wk
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </div>
                    </Row>
                  </div>
                </div>
              </React.Fragment>
              <div className="mt-3">
                <Button
                  color="light"
                  className="waves-effect waves-light w-sm p-4 ml-1 shadow border-0 bg-light w3-hover-zoom"
                  type="submit"
                  outline={false}
                >
                  Continue
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="bg-transparent text-left">
        
        {socForm.firstQuestion && socForm.firstQuestion !== 'jobHoursPerWeek' ? (
          <UndoRedo />
        ) : (
          <Button
            color="light"
            className="waves-effect waves-light mr-2 btn-sm text-muted"
            outline
            onClick={() => props.history.goBack()}
          >
            <i className="bx bx-undo align-middle font-size-18"></i> Previous
          </Button>
        )}

      </CardFooter>
    </Card>
  )
}

const mapStatetoProps = state => {
  return { ...state.Forms.present, ...state.Search }
}

export default connect(mapStatetoProps, {
  changeFormData,
})(withRouter(QuestionHours))
