import React, { useEffect } from "react"

import { CardBody, Card, Alert, CardHeader } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError } from "../../store/actions"

const Login = (props) => {
  useEffect(() => {
    return () => {
      props.apiError("")
    }
  }, [])

  // handleValidSubmit
  function handleValidSubmit(event, values) {
    props.loginUser(values, props.history, "/dashboard-soc-assessments")
  }

  return (
    <Card className="overflow-hidden mr-2 ml-2" style={{ width: "500px" }}>
      <CardHeader className="bg-soft-primary border-bottom pt-3">
        <h5 className="text-primary text-center">
          <i className="mdi mdi-account align-middle font-size-18" /> WELCOME
          BACK
        </h5>
      </CardHeader>
      <CardBody>
        <h2 className="text-dark text-center mb-3">Sign In</h2>
        <div className="p-2 mt-2 ">
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleValidSubmit(e, v)
            }}
          >
            {props.error && props.error ? (
              <Alert color="danger">{props.error}</Alert>
            ) : null}

            <div className="form-group">
              <AvField
                name="email"
                value=""
                className="form-control "
                placeholder="Email Address"
                type="email"
                required
                style={{ height: "50px" }}
              />
            </div>

            <div className="form-group">
              <AvField
                name="password"
                value=""
                type="password"
                required
                placeholder="Password (6 or more characters)"
                style={{ height: "50px" }}
              />
            </div>

            <div className="mt-3 pt-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light btn-lg"
                type="submit"
              >
                Continue to My Assessments
                <i className="bx bx-chevrons-right ml-1 align-middle font-size-24"></i>
              </button>
            </div>

            <div className="d-flex justify-content-between align-items-bottom flex-wrap">
              <div className="custom-control custom-checkbox mt-2">
                <input
                  type="checkbox"
                  className="custom-control-input "
                  id="customControlInline"
                />
                <label
                  className="custom-control-label text-muted"
                  htmlFor="customControlInline"
                >
                  <div style={{ fontWeight: "400" }}> Remember me</div>
                </label>
              </div>

              <div className="mt-2 text-right">
                <Link to="/forgot-password" className="text-muted">
                  Need help?
                </Link>
              </div>
            </div>

            <div className="mt-4 text-center">
              New to What Visa?{" "}
              <Link to="#" onClick={() => props.setAuthType && props.setAuthType("register")}>
                Create a free account now
              </Link>
            </div>
          </AvForm>
        </div>
      </CardBody>
    </Card>
  )
}

const mapStatetoProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStatetoProps, { loginUser, apiError })(Login)
)
