import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { changeSearchTerm } from "../../store/actions"
import { FormGroup, CardBody, Card } from "reactstrap"
import { Typeahead } from "react-bootstrap-typeahead"
import "react-bootstrap-typeahead/css/Typeahead.css"
import jsonApiData from "./jsonApiData.json"

const BigSearchCard = props => {
  const [singleSelections, setSingleSelections] = useState([
    { customOption: true, title: props.searchTerm, id: "new-id-1" },
  ])
  const [showX, setShowX] = useState((props.searchTerm))

  useEffect(() => {
    singleSelections[0]
      ? props.changeSearchTerm(singleSelections[0].title)
      : props.changeSearchTerm("")
  }, [singleSelections])

  const ref = useRef()

  return (
    <>
      <Card color="white" className="shadow">
        <CardBody>
          <h4 className="text-dark pb-3">
            Search for an occupation code by job title...
          </h4>

          <FormGroup>
            <div className="search-box bg-grey">
              <div className="position-relative bg-grey">
                <Typeahead
                  maxResults={7}
                  minLength={3}
                  id="basic-typeahead-single"
                  labelKey="title"
                  onChange={setSingleSelections}
                  options={jsonApiData}
                  placeholder="Search..."
                  selected={singleSelections}
                  allowNew
                  newSelectionPrefix={
                    <>
                      <i className="bx bx-search mr-1 align-middle font-size-14" />
                      Search by keyword:{" "}
                    </>
                  }
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      setSingleSelections([
                        {
                          customOption: true,
                          title: e.target.defaultValue,
                          id: "new-id-1",
                        },
                      ])
                      ref.current.clear()
                      return
                    } else if (e.key === "Backspace") {
                      return
                    } else setShowX(true)
                  }}
                  ref={ref}
                  style={{ animationDuration: "0s" }}
                  autoFocus={!props.searchTerm}
                />
                 <i
                  className="bx bx-search search-icon font-size-18 text-black"
                  onClick={e =>
                    setSingleSelections([
                      {
                        customOption: true,
                        title: e.target.defaultValue,
                        id: "new-id-1",
                      },
                    ])
                  }
                ></i>
                {showX && <i
                  className="bx bx-x search-icon-right font-size-24 text-black"
                  onClick={() => {
                    setSingleSelections([])
                    ref.current.clear()
                    setShowX(false)
                  }}
                  style={{ cursor: "pointer" }}
                ></i>}
              </div>
            </div>
          </FormGroup>
        </CardBody>
      </Card>
    </>
  )
}

const mapStatetoProps = state => {
  return { ...state.Search }
}

export default connect(mapStatetoProps, {
  changeSearchTerm,
})(BigSearchCard)
