import React from "react"
import { Card, CardTitle, CardBody } from "reactstrap"
import getMinSalaryRange from "../../helpers/get-min-salary-range"
import ReactTooltip from "react-tooltip"

const MinimumSalaryRangeCard = ({ socData }) => {
  if (!socData) return <>Loading</>

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle>Minimum salary range<span data-tip="Indicative minimum salary range - we'll determine the exact minimum in the next stage">
              <i className="bx bx-info-circle font-size-14 ml-1 text-info" />
              <ReactTooltip className='tooltip'/>
            </span></CardTitle>
          <p className="mb-2 text-muted font-size-14">{getMinSalaryRange(socData)}</p>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default MinimumSalaryRangeCard
