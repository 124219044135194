import React, { useEffect } from "react"
import { CardBody, Card, Alert, CardHeader, CardFooter } from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"

const Register = props => {
  // handleValidSubmit
  function handleValidSubmit(event, values) {
    console.log("handleValidSubmit ran 000")
    props.registerUser(values)
  }

  useEffect(() => {
    // set registerUserFailed to '' on load
    // props.registerUserFailed("");
  })

  useEffect(() => {
    return () => {
      if (props.registrationError !== "") props.registerUserFailed("")
      props.apiError("")
    }
  }, [])

  return (
    <Card className="overflow-hidden mr-2 ml-2" style={{ maxWidth: "500px" }}>
      <CardHeader className="bg-soft-primary border-bottom pt-3">
        <h5 className="text-primary text-center">
          <i className='mdi mdi-file-document-outline align-middle font-size-18' /> COMPLETE YOUR ASSESSMENT AND SEE NEXT STEPS 
        </h5>
      </CardHeader>
      <CardBody>
      <h5 className="text-dark text-center mb-3">
      Create an Account
        </h5>
        <div className="p-2 mt-2">
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleValidSubmit(e, v)
            }}
          >
            {props.user && props.user ? (
              <Alert color="success">Registered User Successfully</Alert>
            ) : null}
            {props.registrationError && props.registrationError ? (
              <Alert color="danger">{props.registrationError}</Alert>
            ) : null}

            <div className="form-group" >
              <AvField
                name="email"
                className="form-control"
                placeholder="Enter your email address"
                type="email"
                required
                style={{height: '50px'}}
              />
            </div>

            {/* <div className="form-group">
                          <AvField
                            name="username"
                            label="Username"
                            type="text"
                            required
                            placeholder="Enter username"
                          />
                        </div> */}

            <div className="form-group">
              <AvField
                name="password"
                type="password"
                required
                placeholder="Enter your password (5 or more characters)"
                style={{height: '50px'}}
              />
            </div>
            <div className="mt-4">
              <button
                className="btn btn-primary btn-block waves-effect waves-light btn-lg"
                type="submit"
              >
                Sign Up & Continue
                <i className="bx bx-chevrons-right ml-1 align-middle font-size-24"></i>
              </button>
            </div>

            <div className="mt-4 text-center">
              
                <i className="bx bx-log-in mr-1"></i> Already have an account? 
                <Link to="#" onClick={() => props.setAuthType && props.setAuthType("login")}> Sign In
              </Link>
            </div>
          </AvForm>
        </div>
      </CardBody>
      <CardFooter className="bg-transparent">
      <div className="mt-0 text-center ">
              <p className="m-0 font-size-12">
                By clicking Agree & Sign Up, you agree to the What Visa{" "}
                <Link
                  to="#"
                  className="text-secondary waves-effect waves-light"
                >
                  User Agreement, Privacy Policy, and Cookie Policy
                </Link>
              </p>
            </div>
      </CardFooter>
    </Card>
  )
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register)
