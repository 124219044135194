import React, { useState } from "react"
import { connect } from "react-redux"
import { resetFormData } from "../../store/actions"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Input,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

import firebase from "firebase/app"

const DashboardHoldingPage = props => {
  const [signedUp, setSignedUp] = useState(false)
  const [email, setEmail] = useState("")

  const db = firebase.firestore()

  const handleSaveEmail = email => {
    db.collection("mailinglist")
      .doc("subscribers")
      .update(
        "emails",
        firebase.firestore.FieldValue.arrayUnion({
          email: email,
          date: new Date(),
        })
      )
      .catch(err => {
        console.log("Firestore error:", err)
      })
  }

  return (
    <>
      <div className="page-content">
        <Container>
          <Row>
            <Col xl="12">
            <Card className="pb-0">
                <CardBody style={{zIndex: 1}}>
                  <Col lg="8">
                    <h1 className="text-muted w3-animate-opacity">
                      <i className="bx bxs-quote-left font-size-18 align-top " />
                      Why do visas have to be so
                      <span className="text-success">
                        <strong> complicated?</strong>
                        <i className="bx bxs-quote-right font-size-18 align-top text-secondary" />
                      </span>
                    </h1>
                  </Col>
                  <div className="text-center" style={{marginTop: 120}}>
                    <h5 className="text-muted mb-3 pt-1">
                      After working with the UK’s immigration system for over a
                      decade, we grew tired of hearing that question...
                    </h5>
                  </div>
                  <div className="text-center" style={{paddingBottom: 120}}>
                    <h5 className="text-muted">So we're fixing it</h5>
                  </div>
                  <CardFooter className="bg-transparent border-top pb-0">
                    <div className="text-right text-muted w3-animate-right">
                      <h4>
                        What Visa<sup>?</sup> Visas made{" "}
                        <span className="text-primary">simple</span>
                      </h4>
                    </div>
                  </CardFooter>
                </CardBody>
                
              </Card>

              <Card>
                {/* <CardHeader className="text-center">
                  <h6>Want more info?</h6>
                </CardHeader> */}
                <CardBody>
                  <Col>
                    <h5 className="text-muted mb-4 mt-3 text-center">
                      Sign up to receive updates
                    </h5>
                  </Col>

                  <form
                    className="form-inline"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onSubmit={e => {
                      e.preventDefault()
                      if (e.target[0].value.length < 5) return
                      handleSaveEmail(e.target[0].value)
                      setSignedUp(true)
                      setEmail("")
                      // setTimeout(() => {
                      //   setSignedUp(false)
                      // }, 5000)
                    }}
                  >
                    <div className="input-group bg-light rounded">
                      <input
                        type="email"
                        value={email}
                        className="form-control bg-transparent border-0"
                        placeholder="Your email"
                        aria-label="Recipient's username"
                        aria-describedby="button-addon2"
                        style={{
                          minWidth: 350,
                        }}
                        onChange={e => {
                          setEmail(e.target.value)
                        }}
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary rounded">
                          <i className="bx bxs-paper-plane"></i>
                        </button>
                      </div>
                    </div>
                  </form>

                  {signedUp && !email && (
                    <div className="text-center w3-animate-opacity">
                      <p className="text-success mb-3 mt-2">
                        Thanks for signing up! Your email has been added to our
                        mailing list
                      </p>
                    </div>
                  )}

                  <div className="text-center mb-3 mt-3">
                    We’re hard at work, breaking down the UK’s immigration
                    system into bite-size chunks to better support visa
                    applicants and employers. What Visa? is an independent
                    organisation run by immigration industry experts with over a
                    decade of experience. We are not an official Governmental
                    body.
                  </div>
                </CardBody>
              </Card>

              {/* <Card>
          <CardBody className="p-4">
            <div className="text-center">
              <div className="avatar-md mx-auto mb-4">
                <div className="avatar-title bg-light rounded-circle text-primary h1">
                  <i className="mdi mdi-email-open"></i>
                </div>
              </div>

              <Row className="justify-content-center">
                <Col xl={10}>
                  <h4 className="text-primary">Sign up !</h4>
                  <p className="text-muted font-size-14 mb-4">
                  Sign up to receive emails and stay updated 
                  </p>

                  <div className="input-group bg-light rounded">
                    <input
                      type="email"
                      className="form-control bg-transparent border-0"
                      placeholder="Enter Email address"
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary rounded"
                        type="button"
                        id="button-addon2"
                      >
                        <i className="bx bxs-paper-plane"></i>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default connect(null, { resetFormData })(
  withTranslation()(DashboardHoldingPage)
)
