import React from "react";
import { connect } from "react-redux";
import { changeFormData} from "../../store/actions";
import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Row,
  Button,
} from "reactstrap";
import UndoRedo from "./UndoRedo"

const QuestionRate = (props) => {
  const {
    changeFormData,
    socForm,
    socData: { goingRates },
  } = props;

  const handleClick = (rate) => {
    let res = { ...socForm };
    if (!res.firstQuestion) res.firstQuestion = "rateBand"
    res.rateBand = rate;
    changeFormData(res);
    return;
  };

  const getRateBandList = (jobLocation) => {
    let res = [];
    if (goingRates.length > 0) {
      goingRates.forEach((rate) => {
        if (rate[jobLocation]) res.push(rate.rateBand);
      });
    }
    return res;
  };

  return (
    <React.Fragment>
      <Card className="w3-animate-right">
        <CardBody className="text-center p-5">
          <h3 className="text-primary mb-3">The rate band / level is:</h3>
          <Row className="d-flex justify-content-center">
            {!socForm.jobLocation ? (
              <div>No location selected</div>
            ) : (
              <Col xl={6} lg={7} sm={9} className="">
                <div className="text-muted mb-4">
                  {getRateBandList(socForm.jobLocation).map((rate, i) => (
                    <Button
                      color="light"
                      outline={false}
                      className="text-left mb-2 p-3 btn-block shadow bg-light"
                      key={i}
                      onClick={() => {
                        handleClick(rate);
                      }}
                    >
                      {rate}
                    </Button>
                  ))}
                </div>
              </Col>
            )}
          </Row>
        </CardBody>
        <CardFooter className="bg-transparent text-left">
        {socForm.firstQuestion && socForm.firstQuestion !== "rateBand" ? (
            <UndoRedo />
          ) : (
            <Button
              color="light"
              className="waves-effect waves-light mr-2 text-primary"
              outline
              onClick={() => props.history.push('/dashboard-soc-info')}
            >
             <i className="bx bx-undo align-middle font-size-18"></i> Previous
            </Button>
          )}
        </CardFooter>
      </Card>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  return { ...state.Forms.present, ...state.Search };
};

export default connect(mapStatetoProps, {
  changeFormData,
})(withRouter(QuestionRate));
