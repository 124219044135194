import React, { useMemo,useEffect, useState } from "react"
import { PDFDownloadLink } from "@react-pdf/renderer"
import MyDocSummary from "./MyDocSummary"
import {
  Spinner
} from "reactstrap"

const PDFRenderer = props => {

  const {socForm, socData } = props
  const [isOpen, setIsOpen] = useState(false)

  let timer

  useEffect(() => {
    if (props.isOpen) {
      timer = setTimeout(() => {
        setIsOpen(true)
      }, 3000);
      return timer
    }
    if (!props.isOpen) {
      clearTimeout(timer)
      setIsOpen(false)
    }
    return () => {
      clearTimeout(timer)
      setIsOpen(props.isOpen)
    }
  }, [props.isOpen])
  

  return useMemo(
    () => (
      isOpen ? 
      <PDFDownloadLink
      key={Math.random()}
        document={
          <MyDocSummary
            socData={socData}
            socForm={socForm}
            key={socForm.id+socForm.status}
          />
        }
        fileName="What_Visa-Summary_Assessment_Report.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            <><div className="btn btn-primary btn-sm waves-effect waves-light w-lg disabled" style={{opacity: 1}}>
            Generating <Spinner size='sm' className='ml-1'/> 
          </div></>
          ) : (
            <div className="btn btn-primary btn-sm waves-effect waves-light w-lg">
              Download now 
              <i className="bx bx-download align-middle ml-1" />
            </div>
          )
        }
      </PDFDownloadLink> : 
      <div className="btn btn-primary btn-sm waves-effect waves-light w-lg disabled" style={{opacity: 1}}>
            Generating  <Spinner size='sm' className='ml-1'/>
          </div>
    ),
    [isOpen]
  )
}

export default PDFRenderer
