import React from "react"
import { connect } from "react-redux"
import {
  Card,
  CardBody,
  CardTitle,
  Badge,
  CardFooter,
  Button,
  CardHeader,
} from "reactstrap"
import UndoRedo from "./UndoRedo"

const YourAnswers = props => {
  const {
    socData,
    socData: {
      soc,
      socTitle,
      getsGoingRateFromPayScale,
      goingRates,
      relevantMinSalary,
      isPhdeligible,
      isPostDoceligible,
      isEducationCode,
      solRolesUK,
      solRolesScotland,
    },
  } = props

  const {
    isSolJob,
    jobLocation,
    rateBand,
    jobHoursPerWeek,
    jobPercentFTE,
    applicantHasPhd,
    phdIsRelevant,
    phdIsStem,
    agreedToProceedAsNewEntrant,
  } = props.socForm

  return (
    <Card>
      <CardBody>
        <CardTitle>Your Answers</CardTitle>
        {socTitle && (
          <div>
            <Badge pill className="badge-soft-info mt-2">
              {soc + " - " + socTitle}
            </Badge>
          </div>
        )}
        {rateBand !== "All jobs in this code" && (
          <div>
            <Badge pill className="badge-soft-info mt-2">
              {rateBand}
            </Badge>
          </div>
        )}{" "}
        {jobHoursPerWeek && (
          <div>
            <Badge pill className="badge-soft-info mt-2">
              {jobHoursPerWeek} hours/week
            </Badge>
          </div>
        )}{" "}
        {jobPercentFTE > 0 && (
          <div>
            <Badge pill className="badge-soft-info mt-2">
              {jobPercentFTE} % FTE
            </Badge>
          </div>
        )}{" "}
        {phdIsRelevant && (
          <div>
            <Badge pill className="badge-soft-info mt-2">
              Relevant PHd
            </Badge>
          </div>
        )}{" "}
        {phdIsStem && (
          <div>
            <Badge pill className="badge-soft-info mt-2">
              Relevant STEM PHd
            </Badge>
          </div>
        )}{" "}
        {agreedToProceedAsNewEntrant && (
          <div>
            <Badge pill className="badge-soft-info mt-2">
              New Entrant
            </Badge>
          </div>
        )}{" "}
        <p></p>
        {/* {(props.newEntrantQuestionsDone() ||
          (props.socForm.jobHoursPerWeek !== "" &&
            props.socForm.jobPercentFTE !== "" &&
            !props.socForm.agreedToMoreQuestions)) && ( */}
          <Button
            color="light"
            className="waves-effect waves-light btn-sm"
            onClick={props.handleReset}
            outline
          >
            <i className="mdi mdi-close align-middle font-size-14"></i> Reset
          </Button>
         {/* )} */}
      </CardBody>

      {(props.newEntrantQuestionsDone() ||
        (props.socForm.jobHoursPerWeek !== "" &&
          props.socForm.jobPercentFTE !== "" &&
          !props.socForm.agreedToMoreQuestions)) && (
        <CardFooter className="bg-transparent border-top">
          <UndoRedo />
        </CardFooter>
      )}
    </Card>
  )
}

const mapStatetoProps = state => {
  return { ...state.Forms.present, ...state.Search }
}

export default connect(mapStatetoProps)(YourAnswers)
