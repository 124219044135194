import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  CardFooter,
  Button,
  Badge,
} from "reactstrap"
import { changeFormData, resetFormData } from "../../store/actions"
import getMinSalary from "../../helpers/get-min-salary"
import AssessmentSalaryTable from "./AssessmentSalaryTable"

const BasicAssessment = props => {
  const minSalary = getMinSalary(props)

  const {
    socData: { socTitle },
    socForm: {
      soc,
      isSolJob,
      jobHoursPerWeek,
      jobPercentFTE,
      phdIsRelevant,
      phdIsStem,
      agreedToProceedAsNewEntrant,
    },
    socForm,
  } = props

  return (
    <>
      <Row className="mt-1">
        <Col>
          <p>Occupation code:</p>
        </Col>
        <Col className="mb-3">
          <Badge color="light" className="font-size-12">
            {soc}
          </Badge>{" "}
          - {socTitle}
        </Col>
      </Row>
      <Row>
        <Col>
          <p>Is the job on the Shortage Occupation list?</p>{" "}
        </Col>
        <Col>
          <Badge color={isSolJob ? `soft-info` : `light`} className="font-size-12">
            {isSolJob ? `Yes` : "No"}
            {/* <i
              className={
                isSolJob
                  ? `bx bx-check align-middle`
                  : `bx bx-x align-middle`
              }
            />   */}
          </Badge>
        </Col>
      </Row>

      <Row>
        <Col>
          <p>Weekly hours:</p>
        </Col>
        <Col>
          <Badge color="light" className="font-size-12">
            {jobHoursPerWeek !== null &&
              jobPercentFTE !== null &&
              `${jobPercentFTE}% FTE (${jobHoursPerWeek} hrs)`}
            {jobHoursPerWeek !== null &&
              jobPercentFTE === null &&
              `${jobHoursPerWeek} hrs`}
            {jobHoursPerWeek === null &&
              jobPercentFTE !== null &&
              `${jobPercentFTE}% FTE`}
          </Badge>
        </Col>
      </Row>

      {jobHoursPerWeek > 48 && (
        <p>
          N.B. Only salary from the first 48 hours per week will be counted
          towards the relevant general salary threshold. This has been factored
          into the minimum salary calculation below
        </p>
      )}

      <Row>
        <Col>
          <p className="mb-1 mt-2">Minimum salary threshholds:</p>
        </Col>
      </Row>

      <Row>
        <Col>
          <AssessmentSalaryTable />
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="mt-3">
            You confirmed that the job will pay at least: <br />
            <Badge color="light" className="font-size-14">
              {agreedToProceedAsNewEntrant === true
                ? minSalary.newEntrant
                : phdIsStem === true
                ? minSalary.stem
                : phdIsRelevant === true
                ? minSalary.phd
                : minSalary.default}
            </Badge>
          </p>
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="text-success">
            From the information provided, this job is eligible for sponsorship
            under the Skilled Worker scheme
          </p>
        </Col>
      </Row>

      <Row>
        <Col>
          <p >
          Complete our full points assessment below to determine whether the applicant gains enough points to qualify for a Skilled Worker visa
          </p>
        </Col>
      </Row>

      <Row>
        <Col>
          {agreedToProceedAsNewEntrant === true && (
            <p>
              N.B. As you have indicated that the annual salary will be at least{" "}
              {minSalary.newEntrant} but lower than {minSalary.default}, you
              will only be able to apply for a visa of up to 4 years, assuming
              the applicant hasn’t held a Tier 2 or Skilled Worker visa before.
            </p>
          )}
        </Col>
      </Row>
    </>
  )
}

const mapStatetoProps = state => {
  return { ...state.Forms.present, ...state.Search }
}

export default connect(mapStatetoProps, {
  changeFormData,
  resetFormData,
})(withRouter(BasicAssessment))
