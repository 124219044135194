import React, { useState, useEffect, createRef } from "react"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import {
  changeFormData,
  changeSocCode,
  resetFormData,
  showAssessmentBar,
} from "../../store/actions"
import {
  Card,
  CardBody,
  CardTitle,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Input,
  Modal,
  Button,
} from "reactstrap"
import BasicAssessment from "./BasicAssessment"
import FullPointsAssessment from "./FullPointsAssessment"
import AssessmentBar from "./AssessmentBar"
import PagesPricing from "./pages-pricing"

const AssessmentCard = props => {
  const [stage, setStage] = useState(1)
  const [title, setTitle] = useState(undefined)
  const [modal_center, setmodal_center] = useState(false)
  const [openOnDefault, setOpenOnDefault] = useState(1)
  
  const {
    socForm: {
      isOver65,
      hasPhysicalMentalCondition,
      gotPastVisaWithB1,
      applicantCountrySpeaksEnglish,
      hasUKDegree,
      hasEnglishGcseEquivalent,
      studiedAtForeignUniInEnglish,
      hasForeignDegree,
      hasOrWillPassLanguageTest,
      assessmentTitle,
      id,
      status
    },
    socForm,
    soc,
    socData,
    changeFormData,
    resetFormData,
    assessmentBar,
    showAssessmentBar
  } = props

  useEffect(() => {
    setTitle(assessmentTitle)
  }, [])

  useEffect(() => {
    scrollToTitle(400)
  }, [])

  useEffect(() => {
    if (status === "basic assessment complete") setOpenOnDefault(1)
    if (status === "full points assessment complete") setOpenOnDefault(3)
    return () => {
      setOpenOnDefault(1)
      showAssessmentBar(null)
    }
  }, [soc])


const scrollToTitle = (num) => {
    if (!socForm.soc || !socForm.status || socForm.status === "archived") return
    let a = document.getElementById("title").offsetTop
    let b = document.getElementById("table-bottom").offsetTop
    let y = a + b + 30
    if (b < 300) return
    setTimeout(function () {
      window.scrollTo({
        top: y,
        left: 0,
        behavior: "smooth",
      })
    },
        num)
  }

  useEffect(() => {
    if (
      //Language question is answered
      isOver65 === true ||
      hasPhysicalMentalCondition === true ||
      gotPastVisaWithB1 === true ||
      applicantCountrySpeaksEnglish === true ||
      hasUKDegree === true ||
      hasEnglishGcseEquivalent === true ||
      hasForeignDegree === true ||
      hasOrWillPassLanguageTest === true ||
      (isOver65 === false &&
        hasPhysicalMentalCondition === false &&
        gotPastVisaWithB1 === false &&
        applicantCountrySpeaksEnglish === false &&
        hasUKDegree === false &&
        hasEnglishGcseEquivalent === false &&
        (hasForeignDegree === false ||
          studiedAtForeignUniInEnglish === false) &&
        hasOrWillPassLanguageTest === false)
    ) {
      return setStage(2)
    } else {
      setStage(1)
    }
  })

  const tog_center = () => {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const handleSave = () => {
    let res = { ...socForm }
    if (title.length > 0) res.assessmentTitle = title
    changeFormData(res)
    tog_center()
    return
  }

  const handleArchive = () => {
    let res = { ...socForm }
    res.status = "archived"
    changeFormData(res)
  }

  const handleRestore = () => {
    let res = { ...socForm }
    res.status = "basic assessment complete"
    changeFormData(res)
  }

  if (!socForm.soc || !socForm.status)
    return (
      <Card className="w3-animate-opacity">
        <CardBody className="text-muted">
          <i className="bx bx-list-ul font-size-18 align-top"></i> No assessment
          loaded. Select an assessment from the table above to see details here
        </CardBody>
      </Card>
    )

  if (socForm.status === "archived")
    return (
      <>
        <Card className="w3-animate-opacity">
          <CardBody className="text-info">
            <i className="bx bx-info-circle font-size-18 align-top text-info"></i>{" "}
            The selected assessment is archived. Click a button below if you
            want to restore it or delete it forever
            <div className="mt-2" id="title">
              <Button
                outline
                color="light"
                className="btn btn-sm mt-1"
                onClick={handleRestore}
              >
                Restore
              </Button>
              <Button
                color="light"
                className="btn btn-sm mt-1 ml-2"
                onClick={() => props.handleDelete(id)}
              >
                <i className="bx bx-trash font-size-14 text-grey mr-1 align-top" />
                Delete forever
              </Button>
            </div>
          </CardBody>
        </Card>
      </>
    )

  return (
    <>
      <Card  className="w3-animate-opacity">
        <CardBody className="pb-2">
          <CardTitle className="text-dark d-flex pb-2">
            <div className="mt-1 flex-grow-1 h4" id="title">
              {socForm.assessmentTitle}
              <i
                className="mdi mdi-square-edit-outline ml-1 align-top font-size-16"
                style={{ cursor: "pointer" }}
                onClick={() => tog_center()}
              ></i>
            </div>
            <Modal
              isOpen={modal_center}
              toggle={() => {
                tog_center()
              }}
              centered={true}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0">Edit title</h5>
                <button
                  type="button"
                  onClick={() => {
                    setmodal_center(false)
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p className="mb-1">Title name:</p>
                <p>
                  <Input
                    name="title"
                    id="title"
                    value={title}
                    onChange={e => {
                      setTitle(e.target.value)
                    }}
                    onKeyDown={e => {
                      if (e.key === "Enter") handleSave()
                    }}
                  />
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    // setTitle(assessmentTitle)
                    tog_center()
                  }}
                  className="btn btn-secondary waves-effect"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </div>
            </Modal>

            <div>
              <UncontrolledDropdown className="mb-2">
                <DropdownToggle
                  color="white"
                  className="btn btn-sm font-size-16 btn-link text-muted mt-n2"
                >
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>

                <DropdownMenu right>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={() => {
                      tog_center()
                    }}
                  >
                    Rename
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={resetFormData}
                  >
                    Close
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={handleArchive}
                  >
                    Archive
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </CardTitle>
          <AssessmentBar
            number={1}
            text={
              <>
                <i className="mdi mdi-currency-gbp h4 text-warning mr-1" />
                Basic assessment and salary threshold determination
              </>
            }
            status={"done"}
            openOnDefault={status === "basic assessment complete"}
          >
            <BasicAssessment />
          </AssessmentBar>

          <AssessmentBar
            number={2}
            text={
              <>
                <i className="mdi mdi-crosshairs-question h3 text-info mr-1 align-middle" />
                Full points assessment
              </>
            }
            status={stage === 1 ? "todo" : "done"}
            openOnDefault={(openOnDefault === 2)}
            socFormStatus={socForm.status}
          >
            <FullPointsAssessment scrollToTitle={scrollToTitle}/>
          </AssessmentBar>

          <AssessmentBar
            number={3}
            text={
              <>
                <i className="mdi mdi-file-download-outline h3 text-success mr-1 align-middle" />
                Download PDF reports
              </>
            }
            status={stage !== 2 ? "pending" : "todo"}
            openOnDefault={status === "full points assessment complete"}
            socFormStatus={socForm.status}
          >
            <PagesPricing
              soc={soc}
              socTitle={socData && socData.socTitle}
              socData={socData}
              socForm={socForm}
            />
          </AssessmentBar>

          <AssessmentBar
            text={
              <>
                <i className="bx bx-trophy h3 text-primary mr-1 align-middle" />
                Prepare draft Certificate of Sponsorship
              </>
            }
            status={stage !== 3 ? "pending" : "todo"}
            number={4}
            openOnDefault={stage === 4}
          >
            <>Prepare draft Certificate of Sponsorship</>
          </AssessmentBar>
        </CardBody>
      </Card>
    </>
  )
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  const { assessmentBar } = state.Layout
  return {
    ...state.Forms.present,
    ...state.Search,
    ...state.Account,
    error,
    success,
    assessmentBar
  }
}

export default connect(mapStatetoProps, {
  changeFormData,
  resetFormData,
  changeSocCode,
  showAssessmentBar
})(withRouter(AssessmentCard))
