import React, { useState } from "react"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Media,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const Faqs = () => {
  const [activeTab, setactiveTab] = useState("1")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="FAQs" />

          <div className="checkout-tabs">
            <Row>
              <Col lg="2">
                <Nav className="flex-column" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        setactiveTab("1")
                      }}
                    >
                      <i className="mdi mdi-account-question-outline d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">Skilled Worker immigration route</p>
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        setactiveTab("2")
                      }}
                    >
                      <i className="bx bx-check-shield d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">Privacy Policy</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        setactiveTab("3")
                      }}
                    >
                      <i className="bx bx-support d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">Support</p>
                    </NavLink>
                  </NavItem> */}
                </Nav>
              </Col>
              <Col lg="10">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <CardTitle className="mb-5">
                        Questions
                        </CardTitle>
                        <Media className="faq-box mb-4">
                          <div className="faq-icon mr-3">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                              What is a Skilled Worker visa?
                            </h5>
                            <p className="text-muted">
                              The{" "}
                              <a
                                href="https://www.gov.uk/skilled-worker-visa"
                                target="_blank"
                              >
                                <u>
                                  Skilled Worker visa route
                                  <i className="bx bx-link-external ml-1 font-size-14 align-middle" />
                                </u>
                              </a>{" "}
                              replaced the Tier 2 (General) route in December
                              2020 and is part of the Home Office’s new ‘Points
                              Based System’ under which both EU and non-EU
                              nationals can apply for permission to work in the
                              UK. The Skilled Worker route allows UK-based
                              employers who are approved by the Home Office to
                              ‘sponsor’ a migrant worker to work in a particular
                              role, enabling them to apply for a visa to work in
                              the UK in that role. The migrant worker can also
                              bring their family members.
                            </p>
                            <p className="text-muted">
                              Successfully applying for a Skilled Worker visa
                              requires cooperation between the employer (the
                              ‘sponsor’) and the applicant, and detailed steps
                              must be completed by each party in a specific
                              order. The Skilled Worker system is complex, and
                              it is easy to make a mistake which can have
                              significant repercussions for both the employer
                              and the applicant later on.
                            </p>
                          </Media>
                        </Media>
                        <Media className="faq-box mb-4">
                          <div className="faq-icon mr-3">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                              Why would I use the Skilled Worker route? Isn’t
                              there a better option?
                            </h5>
                            <p className="text-muted">Probably not...</p>
                            <p className="text-muted">
                              Whilst the UK’s immigration system has numerous
                              routes and schemes, most of these require the
                              applicant to have a specific ‘characteristic’ or
                              connection to the UK, e.g.
                            </p>
                            <p className="text-muted">
                              If you are from a commonwealth country and you
                              have a grandparent born in the UK...
                              <br />
                              ...You probably qualify for an Ancestry visa
                            </p>
                            <p className="text-muted">
                              If you are married to a British citizen or someone
                              with Indefinite Leave to Remain...
                              <br />
                              ...You probably qualify for a spouse visa
                            </p>
                            <p className="text-muted">
                              If you have £2m to invest in the UK...
                              <br />
                              ...You probably qualify for an Investor visa
                            </p>
                            <p className="text-muted">
                              If you’re a leader or potential leader in your
                              industry...
                              <br />
                              ...You might qualify for a Global Talent visa
                            </p>
                            <p className="text-muted">
                              If you meet the requirements of these routes,
                              applying for one of these visas is nearly always
                              preferable over a Skilled Worker visa, either
                              because the application is easier and/or cheaper,
                              or because the visa is far more flexible with what
                              you can do in the UK. Even if you’re an employer,
                              it’s normally worth supporting an employee with
                              one of these visa applications if they qualify –
                              it’s true that they won’t be tied to you as an
                              employer, but they also won’t be restricted from
                              changing roles within your organisation, and the
                              application process could be a lot easier.
                            </p>
                            <p className="text-muted">
                              To summarise, it’s often necessary to make use of
                              the Skilled Worker visa route simply because the
                              applicant doesn’t qualify for a better visa.
                            </p>
                          </Media>
                        </Media>
                        <Media className="faq-box mb-4">
                          <div className="faq-icon mr-3">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                              What do I do if I don’t have an employer willing
                              to sponsor me?
                            </h5>
                            <p className="text-muted">
                              Finding an employer to sponsor you is an essential
                              part of the process of applying for a Skilled
                              Worker visa, and you cannot submit an application
                              without one. Applying for a job in the UK when you
                              require sponsorship can be a daunting task and
                              many applicants ask themselves ‘why would an
                              employer sponsor me when they could just hire a
                              local worker?’ The majority of UK employers are
                              keen to demonstrate a commitment to equality and
                              diversity, but ultimately it too often boils down
                              to the question ‘can I get the skills,
                              qualifications and experience I need from a
                              resident worker or someone on their own visa
                              without having to overpay?’ If the answer is yes,
                              then spending thousands on sponsoring someone for
                              a Skilled Worker visa is rarely an attractive
                              solution.
                            </p>
                            <p className="text-muted">
                              It often comes down to possessing skills,
                              qualifications and experience that are in high
                              demand and low supply in the UK.
                            </p>
                            <p className="text-muted">
                              Two recent changes have altered this dynamic in
                              the favour of applicants:
                            </p>
                            <p className="text-muted">
                              a) Employers no longer need to conduct a Resident
                              Labour Market Test. This test required that the
                              job be advertised for 28 days, with employers not
                              allowed to reject resident workers that met the
                              stated requirements. This limited employers’
                              ability to determine who was the best applicant.
                              With this requirement gone, employers now have
                              more flexibility to simply hire the best person
                              for the role, even if they require sponsorship.
                            </p>
                            <p className="text-muted">
                              b) With Brexit, the UK’s resident workforce has
                              effectively shrunk – EU citizens can no longer
                              move to the UK and work freely without a visa. EU
                              citizens who are not already living in the UK are
                              now in the same boat as non-EU citizens and must
                              find an employer to sponsor them where they don’t
                              qualify for another type of visa.
                            </p>
                            <p className="text-muted">
                              Sponsorship can also be a daunting task for
                              employers – particularly those who don’t have a
                              Sponsor Licence or only use it infrequently. This
                              unfamiliarity with the rules – all too often not
                              knowing where to start - can add to employers’
                              reluctance to sponsor an application.
                            </p>
                            <p className="text-muted">
                              What Visa? helps individuals and employers by
                              first determining that an application is viable
                              and then setting out, in plain English, what’s
                              involved with the application and how to proceed.
                              We find that this helps remove the mystery of
                              sponsorship for many employers, and that they are
                              more willing to sponsor as a result.
                            </p>
                          </Media>
                        </Media>
                        <Media className="faq-box mb-4">
                          <div className="faq-icon mr-3">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                              Is it really that difficult to sponsor a Skilled
                              Worker visa?
                            </h5>
                            <p className="text-muted">
                              Not if you know what you’re doing!
                            </p>
                            <p className="text-muted">
                              The challenges for first-time or infrequent users
                              of the UK’s immigration system are that the law is
                              complex, expansive, changes frequently and the
                              consequences of getting it wrong are significant,
                              particularly with employer led sponsorship. This
                              means it’s not particularly easy or advisable to
                              ‘muddle’ through an application to sponsor a
                              migrant worker.
                            </p>
                            <p className="text-muted">
                              To give an example, if as a small employer you
                              ‘muddle’ through submitting your tax return and
                              get it wrong, the probable worst-case outcome is a
                              demand for more tax and maybe a small fine. If you
                              do the same with a Skilled Worker visa and get it
                              wrong, the best-case outcome is that the
                              application is refused, losing potentially
                              £thousands in visa application fees, and you make
                              the application again, successfully. The
                              worst-case outcome is that the application is
                              actually successful, but then the Home Office
                              conduct an audit at some point over the next few
                              years, discover your error, revoke your Sponsor
                              Licence and any of your staff who hold a Skilled
                              Worker or Tier 2 visa lose their visa and need to
                              leave the UK.
                            </p>
                            <p className="text-muted">
                              Traditionally, employers have had to choose
                              between two ways of navigating these complexities
                            </p>
                            <p className="text-muted">
                              a) Invest hours and hours of time reading (and
                              re-reading) all of the relevant guidance and
                              Immigration rules to obtain a reasonable degree of
                              understanding of how the sponsorship system works.
                              This amounts to many hundreds of pages, and
                              typically sees changes every couple of months
                            </p>
                            <p className="text-muted">or</p>
                            <p className="text-muted">
                              b) Use an external lawyer to support the process.
                              This typically costs in the region of £750 (for a
                              company that already has a Sponsor Licence) to
                              several £thousands (for a company without a
                              Sponsor Licence)
                            </p>
                            <p className="text-muted">
                              At What Visa?, we believe there’s another option.
                              We use a smart assessment engine that asks simple
                              questions to determine whether a Skilled Worker
                              application is viable, and then show you the
                              information and guidance you need to know,
                              condensed from the official rules and guidance
                              documents. This enables a far wider range of
                              employers to sponsor migrant workers without
                              paying £thousands for lawyers and be confident
                              that they’re not making mistakes. You might still
                              decide to use an external lawyer, but by using
                              What Visa? you’ll have a much better understanding
                              of what you’re paying for.
                            </p>
                          </Media>
                        </Media>

                        <Media className="faq-box mb-4">
                          <div className="faq-icon mr-3">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                              Is my employer approved by the Home Office?
                            </h5>
                            <p className="text-muted">
                              Thankfully, this is very easy to check – you can
                              find a full list of employers that hold a Sponsor
                              Licence on the official website{" "}
                              <a
                                href="https://www.gov.uk/government/publications/register-of-licensed-sponsors-workers"
                                target="_blank"
                              >
                                <u>
                                  here
                                  <i className="bx bx-link-external ml-1 font-size-14 align-middle" />
                                </u>
                              </a>
                              . You’ll need to verify that your employer or
                              prospective employer has a Sponsor Licence with
                              ‘Worker (A rating)’ in the Type & Rating column,
                              and ‘Skilled Worker’ in the Route column. If they
                              do – you’re good to go – your employer can log in
                              to their  {" "}
                              <a
                                href="https://www.points.homeoffice.gov.uk/gui-sms-jsf/home/SMS-003-Home.faces"
                                target="_blank"
                              >
                                <u>
                                 Sponsor Management System
                                  <i className="bx bx-link-external ml-1 font-size-14 align-middle" />
                                </u>
                              </a>  {" "}and assign a
                              Certificate of Sponsorship to you, once they’ve
                              verified that sponsoring you is within the rules
                              of the Skilled Worker immigration route.
                            </p>
                            <p className="text-muted">
                              If your employer is listed but doesn’t have an A
                              rated Sponsor Licence covering the Skilled Worker
                              route, they will likely need to apply to add this
                              route to their Sponsor Licence.
                            </p>
                            <p className="text-muted">
                              If your employer isn’t listed at all, they’ll need
                              to {" "}
                              <a
                                href="https://www.points.homeoffice.gov.uk/gui-sponsor-jsf/SponsorHome.faces"
                                target="_blank"
                              >
                                <u>
                                 apply
                                  <i className="bx bx-link-external ml-1 font-size-14 align-middle" />
                                </u>
                              </a>  {" "} for a Sponsor Licence.
                            </p>
                          </Media>
                        </Media>

                        <Media className="faq-box mb-4">
                          <div className="faq-icon mr-3">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                              Is this job eligible for sponsorship?
                            </h5>
                            <p className="text-muted">
                              In December 2020, the Home Office widened the list
                              of eligible jobs significantly, and it now covers
                              everything from roofers and plumbers to doctors,
                              accountants and CEOs. The full list of eligible
                              jobs can be found in the Immigration Rules... Or
                              you can just{" "}
                              <Link to="dashboard-soc-search">
                                search for your job on What Visa?
                              </Link>{" "}
                              and we’ll tell you whether it’s eligible for
                              sponsorship.
                            </p>
                          </Media>
                        </Media>
                        <Media className="faq-box mb-4">
                          <div className="faq-icon mr-3">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                              The job isn’t in a Shortage Occupation….. Is an
                              application even possible?
                            </h5>
                            <p className="text-muted">Yes</p>
                            <p className="text-muted">
                              This is one of the most commonly misunderstood
                              aspects of the Skilled Worker immigration route.
                              There is absolutely no requirement that the job be
                              on the{" "}
                              <a
                                href="https://www.gov.uk/guidance/immigration-rules/immigration-rules-appendix-shortage-occupation-list"
                                target="_blank"
                              >
                                <u>
                                  Shortage Occupation List
                                  <i className="bx bx-link-external ml-1 font-size-14 align-middle" />
                                </u>
                              </a>{" "}
                              for a Skilled Worker application to be viable.
                              Certain jobs are on the Shortage Occupation List -
                              think scientists, engineers and teachers - and
                              applications for these jobs benefit from a lower
                              minimum salary threshold and a slightly cheaper
                              application fee, but that’s it. The What Visa?
                              assessment engine will tell you whether a job is
                              on the list and the impact this has on the minimum
                              salary threshold.
                            </p>
                            <p className="text-muted">
                              Also, since December 2020, there’s no longer a
                              requirement to advertise the role and show there
                              are no suitable British or settled workers who can
                              do the job. Of course the role still has to be
                              genuine, but if a migrant worker has a particular
                              skill set or just great experience within the
                              company itself, there’s no reason they can’t be
                              sponsored for a Skilled Worker visa. Sure, it’s
                              expensive, but the key change is that whether or
                              not to sponsor the migrant worker is now primarily
                              a commercial decision by the employer, rather than
                              simply being prohibited.
                            </p>
                          </Media>
                        </Media>
                        <Media className="faq-box mb-4">
                          <div className="faq-icon mr-3">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                              What’s the minimum salary for this job? Do I need
                              to be paid £25,600?
                            </h5>
                            <p className="text-muted">
                              This is one of the more complicated aspects of the
                              new Skilled Worker system. If you read the news,
                              you’ve probably heard that migrant workers need to
                              be paid at least £25,600, but this is really a
                              gross oversimplification. There are plenty of
                              scenarios in which the applicant can be paid less
                              than this, e.g. if they have a relevant PhD or
                              under 26. But you also have to take into account
                              the ‘Going Rate’ for the job and how many hours
                              the applicant will work, and in many cases the
                              salary will need to be more than £25,600. There
                              are a lot of factors to consider – of course these
                              are all detailed in the Immigration Rules.
                              Alternatively, in less than 5 minutes, the What
                              Visa? assessment engine will guide you through the
                              process, asking you relevant questions and
                              calculating the exact minimum salary threshold for
                              the job and applicant.
                            </p>
                          </Media>
                        </Media>
                        <Media className="faq-box">
                          <div className="faq-icon mr-3">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                              What’s a Certificate of Sponsorship and how do I
                              get one?
                            </h5>
                            <p className="text-muted">
                              A Certificate of Sponsorship, often abbreviated to
                              ‘CoS’, is a crucial part of a Skilled Worker
                              application. It is essentially a ‘virtual’
                              document issued by the employer that contains
                              information relating to the applicant, such as
                              their passport details and the role the applicant
                              will be performing, such as the job title, hours
                              and salary. By issuing or ‘assigning’ - to use the
                              same term as the Home Office - a CoS, an employer
                              is committing to sponsor the named individual in
                              that specific role. Any changes to the information
                              contained on the CoS must normally be reported to
                              the Home Office and in some cases, such as some
                              changes to job title, the Home Office must approve
                              the change before it can occur.
                            </p>
                            <p className="text-muted">
                              An employer must have a Sponsor Licence before
                              they can assign a Certificate of Sponsorship.
                              Every employer that has a Sponsor Licence will
                              have credentials to login to the {" "}
                              <a
                                href="https://www.points.homeoffice.gov.uk/gui-sms-jsf/home/SMS-003-Home.faces"
                                target="_blank"
                              >
                                <u>
                                 Sponsor Management System
                                  <i className="bx bx-link-external ml-1 font-size-14 align-middle" />
                                </u>
                              </a>  {" "} – essentially a Home Office
                              online portal the employer can use to assign and
                              manage Certificates of Sponsorship.{" "}
                            </p>
                            <p className="text-muted">
                              Certificates of Sponsorship for a Skilled Worker
                              application come in one of two varieties:{" "}
                            </p>
                            <p className="text-muted">
                              <em>Defined CoS (‘DCoS’)</em> – These are for
                              Skilled Worker visa applications to be submitted
                              from outside of the UK. This includes situations
                              where the applicant is currently outside the UK or
                              is in the UK but has a visa that does not enable
                              switching inside the UK, such as a visitor visa.
                              Employers must request each DCoS from the Home
                              Office before they can assign it to the applicant,
                              including details of the job and salary with each
                              request. These requests are typically considered
                              within 24 hours, and once granted, the employer
                              can assign the DCoS straight away. If they have
                              all the relevant information relating to the job
                              and applicant to hand, this can be done in 10
                              minutes.
                            </p>
                            <p className="text-muted">
                              <em>
                                Undefined CoS (normally just referred to as CoS)
                              </em>{" "}
                              – These are for Skilled Worker visa applications
                              to be submitted from inside the UK, e.g. where the
                              applicant is inside the UK with a visa that allows
                              them to switch or extend from within the UK, e.g.
                              a Tier 2 or Tier 4 visa.
                            </p>
                          </Media>
                        </Media>
                      </TabPane>

                      <TabPane tabId="2">
                        <CardTitle className="mb-5">Privacy Policy</CardTitle>

                        <Media className="faq-box mb-4">
                          <div className="faq-icon mr-3">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                              Where does it come from?
                            </h5>
                            <p className="text-muted">
                              Everyone realizes why a new common language would
                              be desirable one could refuse to pay expensive
                              translators.
                            </p>
                          </Media>
                        </Media>
                        <Media className="faq-box mb-4">
                          <div className="faq-icon mr-3">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                              Where can I get some?
                            </h5>
                            <p className="text-muted">
                              To an English person, it will seem like simplified
                              English, as a skeptical Cambridge friend of mine
                              told me what Occidental
                            </p>
                          </Media>
                        </Media>
                        <Media className="faq-box mb-4">
                          <div className="faq-icon mr-3">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                              What is Lorem Ipsum?
                            </h5>
                            <p className="text-muted">
                              New common language will be more simple and
                              regular than the existing European languages. It
                              will be as simple as occidental.
                            </p>
                          </Media>
                        </Media>
                        <Media className="faq-box mb-4">
                          <div className="faq-icon mr-3">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <Media body>
                            <h5 className="font-size-15">Why do we use it?</h5>
                            <p className="text-muted">
                              Their separate existence is a myth. For science,
                              music, sport, etc, Europe uses the same
                              vocabulary.
                            </p>
                          </Media>
                        </Media>
                        <Media className="faq-box">
                          <div className="faq-icon mr-3">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                              Where can I get some?
                            </h5>
                            <p className="text-muted">
                              If several languages coalesce, the grammar of the
                              resulting language is more simple and regular than
                              that of the individual languages.
                            </p>
                          </Media>
                        </Media>
                      </TabPane>
                      <TabPane tabId="3">
                        <CardTitle className="mb-5">Support</CardTitle>

                        <Media className="faq-box mb-4">
                          <div className="faq-icon mr-3">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                              Where can I get some?
                            </h5>
                            <p className="text-muted">
                              To an English person, it will seem like simplified
                              English, as a skeptical Cambridge friend of mine
                              told me what Occidental
                            </p>
                          </Media>
                        </Media>
                        <Media className="faq-box mb-4">
                          <div className="faq-icon mr-3">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                              Where does it come from?
                            </h5>
                            <p className="text-muted">
                              Everyone realizes why a new common language would
                              be desirable one could refuse to pay expensive
                              translators.
                            </p>
                          </Media>
                        </Media>

                        <Media className="faq-box mb-4">
                          <div className="faq-icon mr-3">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <Media body>
                            <h5 className="font-size-15">Why do we use it?</h5>
                            <p className="text-muted">
                              Their separate existence is a myth. For science,
                              music, sport, etc, Europe uses the same
                              vocabulary.
                            </p>
                          </Media>
                        </Media>
                        <Media className="faq-box mb-4">
                          <div className="faq-icon mr-3">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                              Where can I get some?
                            </h5>
                            <p className="text-muted">
                              If several languages coalesce, the grammar of the
                              resulting language is more simple and regular than
                              that of the individual languages.
                            </p>
                          </Media>
                        </Media>

                        <Media className="faq-box">
                          <div className="faq-icon mr-3">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <Media body>
                            <h5 className="font-size-15">
                              What is Lorem Ipsum?
                            </h5>
                            <p className="text-muted">
                              New common language will be more simple and
                              regular than the existing European languages. It
                              will be as simple as occidental.
                            </p>
                          </Media>
                        </Media>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Faqs
