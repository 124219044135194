import {
	CHANGE_SEARCH_TERM,
	CHANGE_SOC_CODE,
	CHANGE_SOC_DATA
} from "./actionTypes";

export const changeSearchTerm = searchTerm => ({
	type: CHANGE_SEARCH_TERM,
	payload: searchTerm
});

export const changeSocCode = soc => ({
	type: CHANGE_SOC_CODE,
	payload: soc
});

export const changeSocData = socData => ({
	type: CHANGE_SOC_DATA,
	payload: socData
});




