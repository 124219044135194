import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import axios from "axios";

const JobDescription = ({ soc }) => {
  const [appState, setAppState] = useState({ loading: false });

  useEffect(() => {
    setAppState({ loading: true });
    const apiUrl = `https://api.lmiforall.org.uk/api/v1/soc/code/${soc}`;
    axios.get(apiUrl).then((res) => {
      setAppState({ loading: false, ...res.data });
    });
  }, []);

  return (
    <React.Fragment>
        <Card>
          <CardBody>
            <CardTitle>Description</CardTitle>
                    <blockquote className="blockquote">
                      <p> {appState.description && appState.description}</p>
                      <footer className="blockquote-footer">
                        description from{" "}
                        <cite title="Source Title">
                          Office for National Statistics
                        </cite>
                      </footer>
                    </blockquote>
          </CardBody>
        </Card>
    </React.Fragment>
  );
};

export default JobDescription;
