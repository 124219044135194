import React, { useState } from "react"
import {
  Card,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import classnames from "classnames"

import img1 from "../../../assets/images/small/img-2.jpg"
import img2 from "../../../assets/images/small/img-6.jpg"
import img3 from "../../../assets/images/small/img-1.jpg"
import { Link } from "react-router-dom"

const BlogGrid = () => {
  const [activeTab, toggleTab] = useState("1")

  const toggle = tab => {
    if (activeTab !== tab) toggleTab(tab)
  }
  return (
    <React.Fragment>
      {/* <Col xl={12} lg={12}> */}
      <Card>
        <div>
          <Row className="justify-content-center mt-4">
            <Col xl={10}>
              <div>
                <div className="row align-items-center">
                  <div className="col-4">
                    <div>
                      <h5 className="mb-0">Latest news</h5>
                    </div>
                  </div>
                  <Col xs={8}></Col>
                </div>

                <hr className="mb-4" />

                <Row>
                  <Col sn={6}>
                    <Card className="p-1 border shadow-none">
                      <div className="p-3">
                        <h5>
                          <Link to="blog/how-to-choose-an-occupation-code" className="text-dark">
                            How to choose an occupation code when making a
                            Skilled Worker application?
                          </Link>
                        </h5>
                        <p className="text-muted mb-0">23 Feb, 2021</p>
                      </div>

                      <div className="position-relative">
                      <Link to="blog/how-to-choose-an-occupation-code">
                            
                            <img src={img1} alt="" className="img-thumbnail" />
                          </Link> 
                      </div>

                      <div className="p-3">
                        <ul className="list-inline">
                          <li className="list-inline-item mr-3">
                            <Link to="blog/how-to-choose-an-occupation-code" className="text-muted">
                              <i className="bx bx-purchase-tag-alt align-middle text-muted mr-1"></i>{" "}
                              Skilled worker visas
                            </Link>
                          </li>
                          {/* <li className="list-inline-item mr-3">
                                  <Link to="#" className="text-muted">
                                    <i className="bx bx-comment-dots align-middle text-muted mr-1"></i>{" "}
                                    12 Comments
                                  </Link>
                                </li> */}
                        </ul>
                        <p>
                          The first step in assessing the viability of an
                          application for a Skilled Worker visa is always to
                          choose the most appropriate occupation code for the
                          job in question.
                        </p>

                        <div>
                          <Link to="blog/how-to-choose-an-occupation-code" className="text-primary">
                            Read more <i className="mdi mdi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </Card>
                  </Col>

                  <Col sm={6}>
                    <Card className="p-1 border shadow-none">
                      <div className="p-3">
                        <h5>
                          <Link to="blog/tier-2-is-no-more" className="text-dark">
                            Tier 2 is no more – what does the new Skilled Worker
                            route means for employers and applicants?
                          </Link>
                        </h5>
                        <p className="text-muted mb-0">1 Mar, 2021</p>
                      </div>

                      <div className="position-relative">

                      <Link to="blog/tier-2-is-no-more">
                      <img src={img2} alt="" className="img-thumbnail" />
                          </Link>
                        
                      </div>
                      <div className="p-3">
                        <ul className="list-inline">
                          <li className="list-inline-item mr-3">
                            <Link to="blog/tier-2-is-no-more" className="text-muted">
                              <i className="bx bx-purchase-tag-alt align-middle text-muted mr-1"></i>{" "}
                              Skilled worker visas
                            </Link>
                          </li>
                        </ul>

                        <p>
                          On 1 December 2020 the Skilled Worker route replaced
                          Tier 2 of the UK’s old Points-Based System. As part of
                          the Government’s new post-Brexit points-based
                          immigration system
                        </p>

                        <div>
                          <Link to="blog/tier-2-is-no-more" className="text-primary">
                            Read more <i className="mdi mdi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
      {/* </Col> */}
    </React.Fragment>
  )
}
export default BlogGrid
