import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { resetFormData } from "../../store/actions"
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  Button,
  Media,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

import Breadcrumbs from "../../components/Common/Breadcrumb"

const AboutUs = props => {
  useEffect(() => {
    // props.resetFormData()
  }, [])

  const [customActiveTab, setcustomActiveTab] = useState("1")

  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  return (
    <div className="page-content">
      <Container>

      <Breadcrumbs
          title={"Home"}
          breadcrumbItem={"About us"}
        />

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                  
                {/* <Col><h5 className='text-center'>About us...</h5></Col> */}

                <Col lg={9}>
                  <p className="text-muted mb-4 mt-3">
                    Employers and applicants have traditionally had two options
                    when it comes to using the UK’s immigration system…
                  </p>
                </Col>

                <Col lg={7}>
                  <p className="text-muted mb-1">
                    Spend hours and hours looking through 100s of pages of
                    guidance and rules to understand what they need to do, and
                    then submitting the application{" "}
                    <strong>on their own</strong>...
                  </p>
                </Col>

                <div className="text-center">
                  <p className="text-muted mb-3">or</p>
                </div>

                <Col><div className="text-right">
                  <p className="text-muted mb-4">
                    ...use a lawyer to prepare and submit the application, often
                    costing in the region of <strong>£750-£1,000’s</strong>
                  </p>
                </div></Col>

                <div className="text-center">
                  <h4 className="mb-4">
                    What Visa<sup>?</sup> offers an <span className='text-primary'>intelligent </span> alternative
                  </h4>
                </div>

                <Col><div className="text-left">
                  <p className="text-muted mb-2 mt-3">
                    We condense the relevant guidance on rules from those
                    hundreds of pages and only show you what you need to know,
                    calculating whether <strong>your</strong> application is
                    viable
                  </p>
                </div></Col>

                <Col><div className='text-center mb-4'><Link to={"/dashboard-soc-search"}>
                    <Button outline color="primary" className="btn btn-lg p-3">
                      Let's get started
                    </Button>
                  </Link></div></Col>

                <CardFooter className="bg-transparent border-top">
                  <div className="text-right">
                    <h4>
                      What Visa<sup>?</sup> Visas made{" "}
                      <span className="text-primary">simple</span>
                    </h4>
                  </div>
                </CardFooter>
              </CardBody>
            </Card>

           
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default connect(null, { resetFormData })(
  withTranslation()(AboutUs)
)
