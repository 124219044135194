import React, { useState } from "react"
import { connect } from "react-redux"
import { changeFormData } from "../../store/actions"
import { withRouter } from "react-router-dom"
import { Card, CardBody, CardFooter, Col, Row, Button } from "reactstrap"
import UndoRedo from "../Dashboard-soc-questions/UndoRedo"
import QuestionDegree from "./question-degree"
import YesOrNoQuestion from "../Dashboard-soc-questions/question-yes-no"
import ReactTooltip from "react-tooltip"

const QuestionEnglish = props => {
  const [questionToShow, setQuestionToShow] = useState("hasUKDegree")
  const {
    changeFormData,
    socForm,
    socForm: {
      showEnglishOptions,
      hasUKDegree,
      studiedAtForeignUniInEnglish,
      degreeCountry,
      hasEnglishGcseEquivalent,
      hasForeignDegree,
    },
  } = props

  const handleClick = value => {
    let res = { ...socForm }
    res.showEnglishOptions = false
    setQuestionToShow(value)
    changeFormData(res)
    return
  }

  let options = [
    { title: "Degree from the UK", value: "hasUKDegree" },
    { title: "Degree from outside the UK, taught in English", value: "hasForeignDegree", value2: "studiedAtForeignUniInEnglish" },
    {
      title: "GCSE or A-Level English qualification",
      value: "hasEnglishGcseEquivalent",
    },
    { title: "English Language test", value: "hasOrWillPassLanguageTest" },
  ]

  options = options.filter(i => {
    if (socForm[i.value] === false) return false
    if (socForm[i.value2] === false) return false
    if (socForm[i.value + "Docs"] === false) return false
    return true
  })

  const getNextQuestion = () => {
    if (questionToShow === "hasUKDegree" && hasUKDegree === "")
      return (
        <YesOrNoQuestion
          key={8}
          question={
            <>
              <div className="font-size-24 pb-3">
                Does the applicant hold a bachelor’s degree, master’s degree or
                doctorate awarded in the UK?
              </div>
            </>
          }
          property={"hasUKDegree"}
        />
      )

    if (questionToShow === "hasUKDegree")
      return (
        <YesOrNoQuestion
          key={1}
          question={
            <>
              <div className="font-size-20 pb-2">
                Will the applicant be able to submit <strong>one</strong> of the
                following documents with their application:
              </div>
              <div className="font-size-18 pt-2">
                - a certificate from the awarding body
                <br /> - a transcript issued by the university or college that
                awarded the qualification
                <br /> - an official letter from the university or college that
                awarded the qualification containing information equivalent to a
                degree certificate
              </div>
            </>
          }
          property={"hasUKDegreeDocs"}
        />
      )

    if (
      questionToShow === "hasEnglishGcseEquivalent" &&
      hasEnglishGcseEquivalent === ""
    )
      return (
        <YesOrNoQuestion
          key={2}
          question={
            <>
              <div className="font-size-20 pb-2">
                Does the applicant hold one of the following qualifications in
                the subject of English or English Literature, awarded by an{" "}
                <a
                  href="https://www.gov.uk/government/organisations/ofqual"
                  target="_blank"
                >
                  <u>
                    Ofqual
                    <i className="bx bx-link-external ml-1 font-size-14 align-middle" />
                  </u>
                </a>
                ,{" "}
                <a href="https://www.sqa.org.uk/sqa/70972.html" target="_blank">
                  <u>
                    SQA
                    <i className="bx bx-link-external ml-1 font-size-14 align-middle" />
                  </u>
                </a>
                ,{" "}
                <a
                  href="https://www.qualificationswales.org/english/"
                  target="_blank"
                >
                  <u>
                    Qualifications Wales
                    <i className="bx bx-link-external ml-1 font-size-14 align-middle" />
                  </u>
                </a>{" "}
                or{" "}
                <a href="https://ccea.org.uk/" target="_blank">
                  <u>
                    CCEA
                    <i className="bx bx-link-external ml-1 font-size-14 align-middle" />
                  </u>
                </a>{" "}
                regulated awarding body and awarded following education
                undertaken in a UK school while under 18?
              </div>
              <div className="font-size-18 pb-4">
                - GCSE
                <br /> - A-Level
                <br /> - Scottish National Qualification at level 4 or 5
                <br /> - Scottish Higher or Advanced Higher
              </div>
            </>
          }
          property={"hasEnglishGcseEquivalent"}
        />
      )

    if (questionToShow === "hasEnglishGcseEquivalent")
      return (
        <YesOrNoQuestion
          key={3}
          question={
            <>
              <div className="font-size-22 pb-3">
                For the qualification in the subject of English or English
                Literature, will the applicant be able to submit one of the
                following documents with their application:
              </div>
              <div className="font-size-18 pb-2">
                - a certificate from the awarding body
                <br /> - an official transcript issued by the awarding body
              </div>
            </>
          }
          property={"hasEnglishGcseEquivalentDocs"}
        />
      )

      if (questionToShow === "hasForeignDegree" && studiedAtForeignUniInEnglish === "")
      return (
        <YesOrNoQuestion
          key={4}
          question={
            <>
              <div className="font-size-24 pb-3">
              Does the applicant have a degree-level qualification from outside the UK, taught in English?
              </div>
            </>
          }
          property={"studiedAtForeignUniInEnglish"}
        />
      )

    if (questionToShow === "hasForeignDegree" && studiedAtForeignUniInEnglish === true && degreeCountry === "")
      return <QuestionDegree key={8} />

    if (
      studiedAtForeignUniInEnglish === true &&
      questionToShow === "hasForeignDegree" &&
      degreeCountry === "Other" &&
      hasForeignDegree === ""
    )
      return (
        <YesOrNoQuestion
          key={5}
          question={
            <>
              <div className="font-size-24 pb-3">
                Does this degree meet or exceed the recognised standard of a UK
                bachelor’s degree, master’s degree or doctorate and was it
                taught or researched in English?
              </div>
            </>
          }
          property={"hasForeignDegree"}
        />
      )

    if (questionToShow === "hasForeignDegree" && degreeCountry === "Other")
      return (
        <YesOrNoQuestion
          key={6}
          question={
            <>
              <div className="font-size-20 pb-2">
                Will the applicant be able to submit the following documents issued
                by{" "}
                <a
                  href="https://www.naric.org.uk/Qualifications/VAN/"
                  target="_blank"
                >
                  <u>
                    UK NARIC
                    <i className="bx bx-link-external ml-1 font-size-14 align-middle" />
                  </u>
                </a>
                :
              </div>
              <div className="font-size-18 pb-4">
                - an Academic Qualification Level Statement (AQUALS); and
                <br /> - an English Language Proficiency Statement (ELPS)
              </div>
              <div className="font-size-20 pb-2">
                The applicant will also need to submit <strong>one</strong> of
                the following documents with their application:
              </div>
              <div className="font-size-18">
                - a certificate from the awarding body
                <br /> - a transcript issued by the university or college that
                awarded the qualification
                <br /> - an official letter from the university or college that
                awarded the qualification containing information equivalent to a
                degree certificate
              </div>
            </>
          }
          property={"hasForeignDegreeDocs"}
        />
      )

    if (
      questionToShow === "hasForeignDegree" &&
      studiedAtForeignUniInEnglish === true &&
      degreeCountry !== "Other" &&
      hasForeignDegree === ""
    )
      return (
        <YesOrNoQuestion
          key={7}
          question={
            <>
              <div className="font-size-24 pb-3">
                Does this degree meet or exceed the recognised standard of a UK
                bachelor’s degree, master’s degree or doctorate?
              </div>
            </>
          }
          property={"hasForeignDegree"}
        />
      )

    if (questionToShow === "hasForeignDegree" &&  studiedAtForeignUniInEnglish === true && degreeCountry !== "Other")
      return (
        <YesOrNoQuestion
          key={8}
          question={
            <>
              <div className="font-size-20 pb-2">
                Will the applicant be able to submit the following document
                issued by{" "}
                <a
                  href="https://www.naric.org.uk/Qualifications/VAN/"
                  target="_blank"
                >
                  <u>
                    UK NARIC
                    <i className="bx bx-link-external ml-1 font-size-14 align-middle" />
                  </u>
                </a>{" "}
                with their application:
              </div>
              <div className="font-size-18 pb-4">
                - an Academic Qualification Level Statement (AQUALS)
              </div>
              <div className="font-size-20 pb-2">
                Will the applicant also be able to submit <strong>one</strong>{" "}
                of the following documents with their application:
              </div>
              <div className="font-size-18">
                - a certificate from the awarding body
                <br /> - a transcript issued by the university or college that
                awarded the qualification
                <br /> - an official letter from the university or college that
                awarded the qualification containing information equivalent to a
                degree certificate
              </div>
            </>
          }
          property={"hasForeignDegreeDocs"}
        />
      )

    if (questionToShow === "hasOrWillPassLanguageTest")
      return (
        <YesOrNoQuestion
          key={9}
          question={
            <>
              <div className="font-size-22 pb-3">
                Has the applicant passed, or will they pass a{" "}
                <a
                  href="https://www.gov.uk/guidance/prove-your-english-language-abilities-with-a-secure-english-language-test-selt"
                  target="_blank"
                >
                  <u>
                    Home Office
                    <i className="bx bx-link-external ml-1 font-size-14 align-middle" />
                  </u>
                </a>{" "}
                approved English Language Test and submit their application
                within the validity of the test result (2 years)?
                <span data-tip="It is crucial that you carefully review the list of approved tests before booking a test">
                  <i className="bx bx-info-circle font-size-22 ml-1 text-info" />
                  <ReactTooltip className="tooltip" />
                </span>
              </div>
              <div className="font-size-18 pb-2">
               
                The applicant must pass the test in reading, writing, speaking
                and listening at level B1 or above
              </div>
            </>
          }
          property={"hasOrWillPassLanguageTest"}
        />
      )
    return
  }

  return (
    <React.Fragment>
      {showEnglishOptions === true && (
        <Card className="w3-animate-right border">
          <CardBody className="text-center p-5">
            <div className="h3 pb-3 text-primary">
              From the information provided, the applicant will need to provide
              evidence that they meet the English language skills requirement.
            </div>
            <div className="font-size-18 pb-2 mb-3 text-primary">
              There are several ways to do this, and you may wish to consider
              each option to determine before progressing:
            </div>
            <Row className="d-flex justify-content-center">
              <Col xl={6} lg={7} sm={9} className="">
                <div className="text-muted mb-4">
                  {options.map((option, i) => (
                    <Button
                      color="light"
                      outline={false}
                      className="text-left mb-2 p-3 btn-block shadow bg-light"
                      key={i}
                      onClick={() => handleClick(option.value)}
                    >
                      {option.title}
                    </Button>
                  ))}
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="bg-transparent text-left">
            <UndoRedo />
          </CardFooter>
        </Card>
      )}
      {showEnglishOptions === false && getNextQuestion()}
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  return { ...state.Forms.present, ...state.Search }
}

export default connect(mapStatetoProps, {
  changeFormData,
})(withRouter(QuestionEnglish))
