import React, { useState } from "react"
import { connect } from "react-redux"
import { changeFormData } from "../../store/actions"
import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Row,
  Button,
  InputGroup,
  Input,
  Form,
  InputGroupAddon,
  InputGroupText
} from "reactstrap"
import UndoRedo from "../Dashboard-soc-questions/UndoRedo"
import { englishNativeCountries } from "../../common/data/index"

const QuestionNationality = props => {
  const {
    changeFormData,
    socForm,
  } = props

  const [nationality, setNationality] = useState(socForm.nationality)

  const handleClick = () => {
    if (nationality === "") return
    let res = { ...socForm, showEnglishOptions: true }
    if (!res.firstQuestion) res.firstQuestion = "nationality"
    res.nationality = nationality
    res.applicantCountrySpeaksEnglish = (nationality !== 'Other')
    changeFormData(res)
    return
  }

  return (

    <React.Fragment>
      <Card className="text-center w3-animate-right mb-0 border">
        <CardBody className="p-5">
          <Row>
            <Col>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  handleClick()
                }}
              >
                <React.Fragment>
                  <div className="form-group">
                    <h3 className="text-primary mb-3">
                      What is the applicant's nationality?
                    </h3>

                    <div>
                      <Row className="d-flex justify-content-center pt-3 pb-3">
                        <div>
                          <InputGroup>

                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="text-light bg-primary border-0">
                            <i className="bx bx-world align-middle font-size-18" /> 
                            </InputGroupText>
                          </InputGroupAddon>
                            <Input
                            className="form-control-lg bg-light border-0 text-left pl-4"
                              type="select"
                              name="select"
                              id="exampleSelect"
                              onChange={e => setNationality(e.target.value)}
                              style={{height: '3rem'}}
                              defaultValue='default value'
                              placeholder='placeholder'
                            >
                              <option value="">Select country...</option>
                              {englishNativeCountries.map((country, i) => (
                                <option key={i}>{country}</option>
                              ))}
                              <option>Other</option>
                            </Input>
                          </InputGroup>
                        </div>
                      </Row>
                    </div>
                  </div>
                </React.Fragment>
                <div className="mt-3">
                  <Button
                    color="light"
                    className="waves-effect waves-light w-sm p-4 ml-1 shadow bg-light"
                    type="submit"
                    outline={false}
                  >
                    Continue
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="bg-transparent text-left">
            <UndoRedo />
        </CardFooter>
      </Card>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  return { ...state.Forms.present, ...state.Search }
}

export default connect(mapStatetoProps, {
  changeFormData,
})(withRouter(QuestionNationality))
