import React from "react"
import { Container, Row, Col } from "reactstrap"

import Footer1 from "../../pages/Dashboard-what-visa/Footer/footer"

const Footer = () => {
  return (
    <React.Fragment>
      <Footer1 />
      {/* <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © What Visa<sup>?</sup>.</Col>
            <Col md={6}>
              <div className="text-sm-right d-none d-sm-block">
                Design & Developed by What Visa<sup>?</sup>
              </div>
            </Col>
          </Row>
        </Container>
      </footer> */}
    </React.Fragment>
  )
}

export default Footer
