import React from "react";
import { connect } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { changeFormData } from "../../store/actions";
import AssessmentSalaryTable from "../Dashboard-soc-assessments/AssessmentSalaryTable";

const SalaryReferenceCard = () => {

  return (
    <React.Fragment>
      <Card className="w3-animate-right">
        <CardBody>
          The minimum salary specified above has been calculated from the
          information you have given us. For reference, the minimum salary
          thresholds in all circumstances are listed below. <br />
          <br />
          < AssessmentSalaryTable />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  return { ...state.Forms.present, ...state.Search };
};

export default connect(mapStatetoProps, {
  changeFormData,
})(SalaryReferenceCard);
