import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { changeFormData } from "../../store/actions"
import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Row,
  Button,
  InputGroup,
  Input,
  Form,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap"
import UndoRedo from "../Dashboard-soc-questions/UndoRedo"
import { englishTeachingCountries } from "../../common/data/index"

const QuestionDegree = props => {
  const { changeFormData, socForm } = props

  const [degreeCountry, setDegreeCountry] = useState(socForm.degreeCountry)

  const handleClick = () => {
    if (degreeCountry === "") return
    let res = { ...socForm }
    if (!res.firstQuestion) res.firstQuestion = "degreeCountry"
    res.degreeCountry = degreeCountry
    changeFormData(res)
    return
  }

  return (
    <React.Fragment>
      <Card className="text-center w3-animate-right mb-0 border">
        <CardBody className="p-5">
          <Row>
            <Col>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  handleClick()
                }}
              >
                <React.Fragment>
                  <div className="form-group">
                    <h3 className="text-primary mb-3">
                      Where was the applicant’s degree taught?
                    </h3>

                    <div>
                      <Row className="d-flex justify-content-center pt-3 pb-3">
                        <div>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText className="text-light pt-0 pb-0 border-0 bg-primary">
                                <i className="mdi mdi-school align-middle font-size-16 p-0 m-0" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              className="form-control-lg text-primary text-left pl-4 bg-light border-0"
                              type="select"
                              name="select"
                              id="exampleSelect"
                              onChange={e => setDegreeCountry(e.target.value)}
                              style={{ height: "3rem" }}
                            >
                              <option value="">Select country...</option>
                              {englishTeachingCountries.map((country, i) => (
                                <option key={i}>{country}</option>
                              ))}
                              <option>Other</option>
                            </Input>
                          </InputGroup>
                        </div>
                      </Row>
                    </div>
                  </div>
                </React.Fragment>
                <div className="mt-3">
                  <Button
                    color="light"
                    className="waves-effect waves-light w-sm p-4 ml-1 shadow bg-light"
                    type="submit"
                    outline={false}
                  >
                    Continue
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="bg-transparent text-left">
          <UndoRedo />
        </CardFooter>
      </Card>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  return { ...state.Forms.present, ...state.Search }
}

export default connect(mapStatetoProps, {
  changeFormData,
})(withRouter(QuestionDegree))
