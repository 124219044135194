import undoable from "redux-undo"
// import { v4 as uuidv4 } from 'uuid';

// @flow
import { CHANGE_FORM_DATA, RESET_FORM_DATA } from "./actionTypes"

const INIT_STATE = {
  socForm: {
    soc: "",
    minSalary: "",
    dateCreated: "",
    status: "",
    isSolJob: "",
    solTitle: "",
    jobLocation: "",
    jobLocationName: "",
    rateBand: "",
    jobHoursPerWeek: "",
    jobPercentFTE: "",
    applicantHasPhd: "",
    phdIsRelevant: "",
    phdIsStem: "",
    willApplicantBeUnder26: "",
    isApplicantBeingQualified: "",
    isApplicantMovingIntoPhd: "",
    agreedToProceedAsNewEntrant: "",
    isApplicantUkStudent: "",
    applicantsLastVisaWasStudent: "",
    isApplicantNewGraduate: "",
    isApplicantSwitchingFromTier1: "",
    isJobRegulated: "",
    applicantWillBeGettingQualified: "",
    applicantWillBeGettingRegistered: "",
    agreedToMoreQuestions: "",
    employerisARated: "",
    employerWillGetLicence: "",
    isOver65: "",
    hasPhysicalMentalCondition: "",
    gotPastVisaWithB1: "",
    showEnglishOptions: true,
    nationality: "",
    applicantCountrySpeaksEnglish: "",
    hasUKDegree: "",
    hasUKDegreeDocs: "",
    hasEnglishGcseEquivalent: "",
    hasEnglishGcseEquivalentDocs: "",
    studiedAtForeignUniInEnglish: "",
    degreeCountry: "",
    hasForeignDegree: "",
    foreignDegreeMeetsUkStandards: "",
    hasForeignDegreeDocs: "",
    hasOrWillPassLanguageTest: "",
    id: "",
    assessmentTitle: ""
  },
}

const Forms = (state = { ...INIT_STATE }, action) => {
  switch (action.type) {
    case CHANGE_FORM_DATA:
      return {
        ...state,
        socForm: action.payload,
      }
    case RESET_FORM_DATA:
      return { ...INIT_STATE }
    default:
      return state
  }
}

const undoableForms = undoable(Forms)

export default undoableForms
