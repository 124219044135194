import React, { useState } from "react";
import { connect } from "react-redux";
import { changeFormData } from "../../store/actions";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Row,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
} from "reactstrap";
import { withRouter } from "react-router-dom"
import UndoRedo from "./UndoRedo"

const QuestionFte = (props) => {
  const [jobPercentFTE, setJobPercentFTE] = useState(100);
  const { changeFormData, socForm } = props;

  const handleClick = () => {
    let res = { ...socForm };
    if (!res.firstQuestion) res.firstQuestion = 'jobPercentFTE'
    res.jobPercentFTE = jobPercentFTE;
    changeFormData(res);
    return;
  };

  return (
    <React.Fragment>
      <Card className="text-center w3-animate-right mb-0 border">
        <CardBody className="p-5">
          <Row>
            <Col>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleClick();
                }}
              >
                <React.Fragment>
                  <div className="form-group">
                    <h3 className="text-primary mb-3">
                      What % of FTE (full-time equivalent) is this role?
                    </h3>
                    <div>
                      <Row className="d-flex justify-content-center">
                        <div style={{width: '130px'}}>
                          <InputGroup >
                            <Input
                              className="form-control-lg text-primary text-left p-4 bg-light border-0"
                              type="number"
                              min="1"
                              max="100"
                              defaultValue={100}
                              id="example-number-input"
                              onChange={(e) =>
                                setJobPercentFTE(parseInt(e.target.value))
                              }
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText className="text-primary border-0 bg-primary text-light">
                                {" "}
                                %
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </div>
                      </Row>
                    </div>
                  </div>
                </React.Fragment>
                <div className="mt-3">
                  <Button
                    color="light"
                    className="waves-effect waves-light w-sm p-4 ml-1 shadow bg-light"
                    type="submit"
                    outline={false}
                  >
                    Next
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="bg-transparent text-left">

        {socForm.firstQuestion && socForm.firstQuestion !== 'jobPercentFTE' ? (
          <UndoRedo />
        ) : (
          <Button
            color="light"
            className="waves-effect waves-light mr-2 btn-sm text-muted"
            outline
            onClick={() => props.history.goBack()}
          >
           <i className="bx bx-undo align-middle font-size-18"></i> Previous
          </Button>
        )}

        </CardFooter>
      </Card>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  return { ...state.Forms.present, ...state.Search };
};

export default connect(mapStatetoProps, {
  changeFormData,
})(withRouter(QuestionFte));
