import React from "react"
import { Card, CardBody, Row, Col } from "reactstrap"

const SocSpecialInfo = props => {
  const hasSpecialInfo = [2119, 5434]

  const getInfo = (soc) => {
  switch (soc) {
    case 2119:
      return 'For Skilled Worker purposes, occupation code 2119 includes researchers in research organisations other than universities.'
      break;
    case 5434:
      return 'N.B. Only chef jobs with a salary of £29,570 are on the Shortage Occupation List, whereas the minimum salary for this occupation code is normally £25,600 (or £20,480 for New Entrants). As such, the only real benefit of the job being on the Shortage Occupation List is a small reduction in the visa application fee, e.g. a discount of £146 for a 3 year visa application submitted from outside of the UK.'
      break;
    default:
      break;
  }
}

  return (
    <>
      {hasSpecialInfo.includes(props.soc) && (
        <Row>
          <Col xl="12">
          <Card className="w3-animate-right">
              <CardBody className="text-info">
              <i className="bx bx-info-circle font-size-14 mr-1" /> {getInfo(props.soc)}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  )
}

export default SocSpecialInfo
