import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Card, CardBody, CardTitle, Button, CardFooter } from "reactstrap"
import { MDBDataTableV5 } from "mdbreact"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import dayjs from "dayjs"
import {
  changeFormData,
  resetFormData,
  changeSocCode,
  changeSocData,
} from "../../store/actions"
import CreateNewAssessmentCard from "./CreateNewAssessmentCard"
import firebase from "firebase/app"

const SavedJobsTable = props => {
  const [assessments, setAssessments] = useState([])
  const [reload, setReload] = useState(false)

  const { changeFormData, changeSocCode, socForm } = props

  const handleClick = assessment => {
    // if (assessment.id === socForm.id) return
    let res = { ...assessment }
    changeSocCode(assessment.soc)
    changeFormData(res)
  }

  let a = document.getElementsByName("selected")
  if (a.length === 1) {
    a[0].parentElement.parentElement.className =
      "table-custom border-left border-bottom-0 border-info"
  }

  let b = document.getElementsByName("unselected")
  for (let i = 0; i < b.length; i++) {
    b[i].parentElement.parentElement.classList.remove("table-custom")
    b[i].parentElement.parentElement.classList.remove("border-left")
    b[i].parentElement.parentElement.classList.remove("border-info")
  }

  const compare = (a, b) => {
    if (a.dateCreated > b.dateCreated) {
      return -1
    }
    if (a.dateCreated < b.dateCreated) {
      return 1
    }
    return 0
  }

  const db = firebase.firestore()

  useEffect(() => {
    setTimeout(() => {
      assessments.length < 1 && setReload(true)
    }, 1000)
  }, [])

  useEffect(() => {
    if (firebase.auth().currentUser) {
      //Subscribe to changes
      db.collection("users")
        .doc(firebase.auth().currentUser.uid)
        .onSnapshot(doc => {
          let res = Object.entries(doc.data().assessments).map(i => i[1])
          let unarchived = [...res]
            .filter(i => i.status !== "archived")
            .sort(compare)
          let archived = [...res]
            .filter(i => i.status === "archived")
            .sort(compare)
          setAssessments([...unarchived, ...archived])
        })
    }
    return () => {
      //Unsubscribe from changes
      if (firebase.auth().currentUser) {
        db.collection("users")
          .doc(firebase.auth().currentUser.uid)
          .onSnapshot(() => {})
      }
    }
  }, [reload])

  const passedAssessment = (
    hasEnglishB1,
    employerisARated,
    employerWillGetLicence
  ) => {
    return (
      hasEnglishB1 === true &&
      (employerisARated === true || employerWillGetLicence === true)
    )
  }

  const myData = {
    columns: [
      {
        label: "Code",
        field: "soc",
        width: 100,
      },
      {
        label: "Assessment name",
        field: "assessmentTitleWithArrow",
        width: 170,
      },

      {
        label: "Min. salary",
        field: "minSalary",
        width: 150,
        minimal: "sm",
      },
      {
        label: "Status",
        field: "status",
        width: 150,
      },
      {
        label: "Date created",
        field: "dateCreated",
        width: 150,
      },
    ],
    rows: assessments.map((assessment, i) => {
      let {
        assessmentTitle,
        status,
        dateCreated,
        hasEnglishB1,
        employerisARated,
        employerWillGetLicence,
      } = assessment
      let res = {
        ...assessment,
        clickEvent: () => handleClick(assessment),
        assessmentTitleWithArrow: (
          <div
            className="mb-1"
            name={assessment.id === socForm.id ? "selected" : "unselected"}
            style={{maxWidth: 580}}
          >
            {assessmentTitle}
          </div>
        ),
      }

      // min salary column
      res.dateCreated = dayjs(dateCreated).format("DD MMM, YY")

      // eligible for sponsorship column
      res.status = (
        <>
          {status == "archived" ? (
            <div>
              <div className="d-inline-flex bg-soft-secondary pl-2 pr-2 rounded text-muted font-size-10 z-depth-5">
                {status}
              </div>
            </div>
          ) : status === "full points assessment complete" &&
            !passedAssessment(
              hasEnglishB1,
              employerisARated,
              employerWillGetLicence
            ) ? (
            <div>
              <div className="d-inline-flex bg-soft-danger pl-2 pr-2 rounded text-danger font-size-10 z-depth-5">
                {status}
              </div>
            </div>
          ) : (
            <div>
              <div className="d-inline-flex bg-soft-success pl-2 pr-2 rounded text-success font-size-10 z-depth-5">
                {status}
              </div>
            </div>
          )}
        </>
      )
      return res
    }),
  }

  return (
    <>
      <Card className="bg-transparent shadow-none mb-1 pb-2 pt-2">
        <CardBody className="mb-0 pb-0 pt-0 mt-0">
          <CardTitle className="text-dark d-flex">
            <div className="mt-1 flex-grow-1 h4 mb-0">Saved assessments</div>
            <div>
              <Button
                color="white"
                className="btn btn-sm font-size-18 btn-link text-muted mt-n2"
                // onClick={props.setLoading}
              >
                {/* <i className="mdi mdi-refresh"></i> */}
              </Button>
            </div>
          </CardTitle>

          {assessments.length < 1 ? (
            <div className="pb-5">Found 0 assessments...</div>
          ) : (
            <>
              <MDBDataTableV5
                hover
                entries={5}
                // pagesAmount={10}
                data={myData}
                searchTop={false}
                searchBottom={false}
                responsive={true}
                sortable={false}
                // sorting={true}
                // striped
                style={{ cursor: "pointer" }}
                // pagingTop
                paging={false}
                // order={["dateCreated", "desc"]} //important
              />
              <div
                style={{ position: "absolute", bottom: "16px", left: "18px" }}
              >
                <CreateNewAssessmentCard />{" "}
              </div>
            </>
          )}
        </CardBody>
      </Card>
      <div id="table-bottom"></div>
    </>
  )
}

const mapStatetoProps = state => {
  return {
    ...state.Forms.present,
    ...state.Search,
  }
}

export default connect(mapStatetoProps, {
  changeFormData,
  resetFormData,
  changeSocCode,
  changeSocData,
})(SavedJobsTable)
