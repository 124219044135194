import React, { useEffect, useState, useMemo } from "react"
import { connect } from "react-redux"
import { Container, Row, Col } from "reactstrap"
import { Link } from "react-router-dom"
// import PDFRenderer from './PDFRenderer'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Pricing Cards
import PricingCard from "./pricing-card"

const PagesPricing = props => {
  // const [loading, setLoading] = useState(true)
  // useEffect(() => {
  //   if (props.isOpen && props.socData && props.socForm) setLoading(false)
  // }, [])

  const {
    socForm: {
      hasEnglishB1,
      employerWillGetLicence
    }
  } = props


  const pricings = [
    {
      id: 1,
      title: "Summary Assessment Report",
      description: (
        <>
          <i className="bx bxl-adobe mr-1" />
          PDF format
        </>
      ),
      icon: "bx-file",
      price: "FREE",
      duration: "",
      link: "",
      features: [
        {
          title:
            "Get a quick summary of your assessment and eligibility for the Skilled Worker visa route",
        },
        {
          title:
            "Useful for demonstrating to your prospective employer that sponsorship is viable",
        },
        {
          title: "Fully customised based on your answers",
        },
        {
          title: "3 pages",
        },
      ],
    },
    {
      id: 2,
      title: "Detailed Assessment Report",
      description: (
        <>
          <i className="bx bxl-adobe mr-1" />
          PDF format
        </>
      ),
      icon: "bx-show-alt",
      price: "FREE",
      duration: "",
      link: "",
      features: [
        {
          title:
            "See a more detailed version of your assessment, including references to the official immigration rules and further guidance on meeting the requirements",
        },
        {
          title:
            "Useful for applicants and employers to double check the finer details of the Skilled Worker visa route",
        },

        {
          title: "Fully customised based on your answers",
        },
        {
          title: "6-8 pages",
        },
      ],
    },
    {
      id: 3,
      title: "Detailed Employer Guide",
      description: (
        <>
          <i className="bx bxl-adobe mr-1" />
          PDF format
        </>
      ),
      icon: "bx-task",
      price: "20",
      duration: (
        <>
          <br />
          (FREE for a limited time)
        </>
      ),
      link: "",
      features: [
        {
          title:
            "A detailed guide on how to sponsor the applicant in the job outlined in your assessment",
        },
        {
          title:
            "Useful for helping a prospective employer who is unfamiliar with the process of sponsorship",
        },

        {
          title: "Fully customised based on your answers",
        },
        {
          title: "20+ pages",
        },
      ],
    },
  ]

  if (hasEnglishB1 === false || employerWillGetLicence === false) return (
    <div className="flex-center">
        <p className="text-info text-left">
                <i className="bx bx-info-circle font-size-18 align-top"></i> This job has not passed the Full Points assessment. Please submit a successful assessment in order to download PDF reports here
              </p>
      </div>
  )

  return <>
      <div className="flex-center">
        <Row className="justify-content-center">
          <Col lg={12}>
            <div className="text-center mb-5">
              <h4>Choose your Report types</h4>
              <p className="text-muted">
                All of our reports are 100% unique and fully customised based on
                the answers you have provided for this assessment
              </p>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center">
          {pricings.map((pricing, key) => (
            <PricingCard pricing={pricing} key={"_pricing_" + key} isOpen={props.isOpen} />
          ))}
        </Row>

        <Row className="justify-content-center">
          <Col>
            <p className="text-center">
              Interested in pricing and functionality for businesses that use
              the Skilled Worker route frequently?{" "}
              <Link to="services" onClick={() => {}}>
                Click here to find out more
              </Link>{" "}
            </p>
          </Col>
        </Row>
      </div>
      {/* {!loading && <PDFRenderer socData={props.socData} socForm={props.socForm}/>} */}
    </>

          }

export default PagesPricing
