import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Badge, Collapse } from "reactstrap"
import { connect } from "react-redux"
import axios from "axios"

const Earning = ({ socData, soc }) => {
  const [appState, setAppState] = useState({ loading: false })
  const [col1, setcol1] = useState(false)

  useEffect(() => {
    setAppState({ loading: true })
    const apiUrl = `https://api.lmiforall.org.uk/api/v1/soc/code/${soc}`
    axios.get(apiUrl).then(res => {
      setAppState({ loading: false, ...res.data })
    })
  }, [soc])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle>Related job titles</CardTitle>
          <div className="mt-3 mb-3 text-muted">
            {socData.relatedJobTitles &&
              socData.relatedJobTitles.map((title, i) => (
                <p key={i}>{title}</p>
              ))}
          </div>
          <div id="accordion">
            <Collapse isOpen={col1}>
              {appState.add_titles &&
                appState.add_titles.map((title, i) => <p key={i}>{title}</p>)}
            </Collapse>
          </div>
          <h6 className="m-0 font-14" id="headingOne">
            <span
              onClick={() => {
                setcol1(!col1)
              }}
              style={{ cursor: "pointer" }}
              className="text-dark"
            >
              <span className="btn btn-light waves-effect waves-light btn-sm">
                {col1 ? (
                  <React.Fragment>
                    View Less <i className="mdi mdi-chevron-up"></i>
                  </React.Fragment>
                ) : (
                  <div>
                    View More <i className="mdi mdi-chevron-down"></i>
                  </div>
                )}
              </span>
            </span>
          </h6>
          <p>
            <Badge pill className="badge-soft-info mt-2">
              {appState.add_titles && appState.add_titles.length + "  total"}{" "}
            </Badge>
          </p>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  return { ...state.Search }
}

export default connect(mapStatetoProps, {})(Earning)
