import React from "react";
import { connect } from "react-redux";
import { changeFormData } from "../../store/actions";
import { Card, CardBody, Col, Row, Button,CardFooter } from "reactstrap";
import { withRouter } from "react-router-dom"

const QuestionSol = (props) => {
  const { changeFormData, socData, socForm } = props;

  const handleClick = (answer, item) => {
    let res = { ...socForm };
    if (!res.firstQuestion) res.firstQuestion = 'isSolJob'
    res.isSolJob = answer;
    if (item) res.solTitle = item;
    changeFormData(res);
  };

  return (
    <React.Fragment>
      <Card className="w3-animate-right">
        <CardBody className="text-center p-5">
          <h3 className="text-primary mb-3">
            Is the job one of these shortage occupation list roles?
          </h3>
          <Row className="d-flex justify-content-center">
            {!socData.solRolesUK.length > 0 &&
            !socData.solRolesScotland.length > 0 ? (
              <div>No Sol roles in the category</div>
            ) : (
              <Col xl={6} lg={7} sm={9} className="">
                <div className="text-muted mb-4">
                  {socData.solRolesUK[0] &&
                    socData.solRolesUK.map((item, i) => (
                      <Button
                        color="light"
                        outline={false}
                        className="text-left mb-2 p-3 btn-block shadow bg-light"
                        key={i}
                        onClick={() => handleClick(true, item)}
                      >
                        {item}
                      </Button>
                    ))}
                  {socData.solRolesScotland[0] &&
                    socData.solRolesScotland.map((item, i) => (
                      <Button
                        color="light"
                        outline={false}
                        className="text-left mb-2 p-3 btn-block shadow bg-light"
                        key={i}
                        onClick={() => handleClick(true, item)}
                      >
                        {item} (where the job is in Scotland)
                      </Button>
                    ))}
                  <Button
                    color="light"
                    outline={false}
                    className="text-left mb-2 p-3 btn-block shadow bg-light"
                    onClick={() => handleClick(false, undefined)}
                  >
                    No
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        </CardBody>
        <CardFooter className="bg-transparent text-left">
        
          <Button
            color="light"
            className="waves-effect waves-light mr-2 text-muted btn-sm"
            outline
            onClick={() => props.history.goBack()}
          >
            <i className="bx bx-undo align-middle font-size-18"></i> Previous
          </Button>
          
        </CardFooter>
      </Card>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  return { ...state.Forms.present, ...state.Search };
};

export default connect(mapStatetoProps, {
  changeFormData,
})(withRouter(QuestionSol));
