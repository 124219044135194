import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"

// import images
import img1 from "../../assets/images/small/img-2.jpg"
import avtar1 from "../../assets/images/users/avatar-2.jpg"

const HowToChooseAnOccupationCode = () => {
  return (
    <React.Fragment>
      <Row className="pt-5 mt-5">
        <Col lg={12}>
          <Card>
            <CardBody>
              <div className="pt-3 mt-3">
                <div className="row justify-content-center">
                  <div className="col-xl-8">
                    <div>
                      <div className="text-center">
                        <div className="mb-4">
                          <Link
                            to="#"
                            className="badge badge-light font-size-12"
                          >
                            <i className="bx bx-purchase-tag-alt align-middle text-muted mr-1"></i>{" "}
                            Skilled Worker visas
                          </Link>
                        </div>
                        <h4>
                          How to choose an occupation code when making a Skilled
                          Worker application
                        </h4>
                        <p className="text-muted mb-4">
                          <i className="bx bx-compass mr-1"></i>From the What
                          Visa<sup>?</sup> blog
                        </p>
                      </div>

                      <hr />

                      <div className="text-center">
                        <Row>
                          <Col sm={4}>
                            <div>
                              <p className="text-muted mb-2">Categories</p>
                              <h4 className="font-size-15">
                                Skilled Worker visas
                              </h4>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="mt-4 mt-sm-0">
                              <p className="text-muted mb-2">Date</p>
                              <h4 className="font-size-15">23 Feb, 2021</h4>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="mt-4 mt-sm-0">
                              <p className="text-muted mb-2">Post by</p>
                              <h4 className="font-size-15">Tim Whittaker</h4>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <hr />

                      <div className="my-5">
                        <img
                          src={img1}
                          alt=""
                          className="img-thumbnail mx-auto d-block"
                          style={{ maxWidth: 800 }}
                        />
                      </div>

                      <hr />

                      <div className="mt-4">
                        <div className="text-muted font-size-14">
                          <p>
                            So you've decided you want to apply for a Skilled
                            Worker visa. What should you do next? The first step
                            in assessing the viability of any application is
                            always to choose the most appropriate
                            <strong> occupation code</strong> for the job in
                            question. It’s important to get this right from the
                            outset, because the occupation code you choose
                            determines not only the ‘skill level’ of the job in
                            the Home Office’s view, but also the minimum salary
                            that a migrant worker will need to be paid to be
                            sponsored in the role. Trying to correct a mistake
                            at a later stage can prove extremely problematic.
                          </p>
                          <blockquote className="p-4 border-light border rounded mb-4">
                            <div className="d-flex">
                              <div className="mr-3">
                                <i className="bx bxs-quote-alt-left text-dark font-size-24"></i>
                              </div>
                              <div>
                                <p className="mb-0 font-size-16 text-primary">
                                  {" "}
                                  It is the employer’s responsibility to ensure
                                  that the most appropriate occupation code is
                                  selected. Where more than one code is relevant
                                  to the job in question, the employer should
                                  choose the one that is closest to the job.
                                </p>
                              </div>
                            </div>
                          </blockquote>
                          <h4 className="mb-3">
                            Ok, so what is an occupation code?
                          </h4>
                          <p className="mb-3">
                            An occupation code is a 4-digit code that aligns
                            with the Office for National Statistics’ Standard
                            Occupation Classification (SOC) system. Put simply,
                            there is a different code for each category of jobs.
                            Here are a few examples:
                          </p>
                          <ul className="mt-2">
                            <li>1132 - Marketing and sales directors</li>
                            <li>3113 - Engineering technicians</li>
                            <li>5431 – Butchers</li>
                          </ul>
                          <p>
                            In the past, Home Office guidance used to refer to
                            these codes as ‘SOC codes’, matching the ONS
                            nomenclature. Current guidance instead uses the term
                            ‘occupation codes’, but rest assured they are
                            exactly the same thing.
                          </p>
                          <p className="mb-4">
                            As well as the 4-digit code and the name of the
                            code, the Home Office publishes the following extra
                            snippets of information for each occupation code:
                          </p>
                          <ul className="mt-2">
                            <li>
                              Around 5-10 alternative job titles that also fit
                              within the code
                            </li>
                            <li>
                              The Going Rate for the occupation code – that is,
                              the minimum salary that a migrant worker must be
                              paid to be sponsored for a Skilled Worker visa to
                              perform that job
                            </li>
                            <li>
                              Whether jobs fitting within the code are eligible
                              for a Skilled Worker application
                            </li>
                            <li>
                              Whether migrant workers can receive additional
                              points for holding a PhD when making an
                              application for a job within the code.
                            </li>
                          </ul>
                          <p>
                            There are currently 369 occupation codes, and up
                            until December 2020, only a quarter of occupation
                            codes were eligible for sponsorship. In December the
                            Home Office opened the Skilled Worker route up to a
                            much wider range of occupations - two thirds of
                            occupation codes are now eligible for sponsorship.
                          </p>
                          <h4 className="mb-3">
                            How do employers pick the right code?
                          </h4>
                          <p className="mb-4">
                            The obvious (if somewhat time-consuming) approach is
                            simply to look through the 369 occupation codes
                            listed on the Home Office’s website, perhaps
                            searching within the 1,500 or so example job titles
                            given there. Frequently this turns up several
                            similar occupation codes and it becomes necessary to
                            compare the codes to see which is the most
                            appropriate.
                          </p>
                          <p className="mb-4">
                            Faced with what often ends up being a laborious
                            process for employers, we created a comprehensive
                            occupation code search engine. In addition to
                            allowing users to search from all the data contained
                            on the Home Office website and displaying similar
                            codes alongside one another, we also search from the
                            much larger ONS dataset. This contains over 24,000
                            example job titles along with example job activities
                            and an overarching description of the occupation
                            code – information that is simply not available from
                            the Home Office website. This makes it significantly
                            quicker and easier to find the right occupation
                            code.
                          </p>
                          <h4 className="mb-3">
                            What happens if the wrong code is chosen?
                          </h4>
                          <p className="mb-4">
                            It is the employer’s responsibility to ensure that
                            the most appropriate occupation code is selected.
                            Where more than one code is relevant to the job in
                            question, the employer should choose the one that is
                            closest to the job. If asked, the Home Office will
                            not offer advice on which code an employer should
                            use, so it is important to consider all relevant
                            occupation codes before picking one, regardless of
                            the skill level and Going Rate attached to the code.
                          </p>
                          <p className="mb-4">
                            In particular, the Home Office is on the look out
                            for employers who deliberately use an inappropriate
                            occupation code to enable sponsorship where it would
                            otherwise have been impossible, e.g.
                          </p>
                          a) Where the chosen code has a lower Going Rate than
                          the more appropriate ‘correct’ code
                          <br />
                          b) Where the chosen code is eligible for sponsorship
                          and the more appropriate ‘correct’ code is not,
                          usually because it is considered to be ‘lower-skilled’
                          <br />
                          <p className="mt-3">
                            Where this goes wrong, there are a few possible
                            outcomes, but they are all difficult, impossible or
                            expensive to fix.
                          </p>
                          <p className="mb-4">
                            If you choose an occupation code that is eligible
                            for the Skilled Worker route and the Home Office
                            later decides, perhaps via a site-visit, that a
                            different occupation code is more appropriate for
                            the job in question, this can result in your Sponsor
                            Licence being downgraded or revoked. In the latter
                            case, all employees holding Tier 2 or Skilled Worker
                            status would also have their visas revoked.
                          </p>
                          <p className="mb-4">
                            Where the employee would like to change jobs at a
                            later date, even within the same employer, if the
                            new job fits within a different occupation code, a
                            whole new Skilled Worker application is required –
                            the employer must assign a new Certificate of
                            Sponsorship and the employee must make an
                            application for Further Leave to Remain in the UK.
                            Careful review of the relevant occupation codes at
                            the start of the process can in some scenarios offer
                            more flexibility going forward – allowing job
                            changes without necessitating a whole new
                            application.
                          </p>
                          <p className="mb-4">
                            For a simpler, quicker and more comprehensive
                            approach to selecting an occupation code for your
                            Skilled Worker application, start your assessment
                            with What Visa?{" "}
                            <Link to="/dashboard-soc-search">here</Link>,
                            completely free of charge.
                          </p>
                          {/* <div className="mt-4">
                            <h4 className="mb-3">Title: </h4>

                            <div>
                              <div className="row">
                                <div className="col-lg-4 col-sm-6">
                                  <div>
                                    <ul className="pl-4">
                                      <li className="py-1">
                                        Donec sodales sagittis
                                      </li>
                                      <li className="py-1">
                                        Sed consequat leo eget
                                      </li>
                                      <li className="py-1">
                                        Aliquam lorem ante
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                  <div>
                                    <ul className="pl-4">
                                      <li className="py-1">
                                        Aenean ligula eget
                                      </li>
                                      <li className="py-1">
                                        Cum sociis natoque
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                          <hr />
                          {/* <div className="mt-5">
                          <h4 className="font-size-15">
                            <i className="bx bx-message-dots text-muted align-middle mr-1"></i>{" "}
                            Comments :
                          </h4>

                          <div>
                            <div className="media py-3">
                              <div className="avatar-xs mr-3">
                                <div className="avatar-title rounded-circle bg-light text-primary">
                                  <i className="bx bxs-user"></i>
                                </div>
                              </div>
                              <div className="media-body">
                                <h4 className="font-size-14 mb-1">
                                  Delores Williams{" "}
                                  <small className="text-muted float-right">
                                    1 hr Ago
                                  </small>
                                </h4>
                                <p className="text-muted">
                                  If several languages coalesce, the grammar of
                                  the resulting language is more simple and
                                  regular than that of the individual
                                </p>
                                <div>
                                  <Link to="#" className="text-success">
                                    <i className="mdi mdi-reply"></i> Reply
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="media py-3 border-top">
                              <div className="avatar-xs mr-3">
                                <img
                                  src={avtar1}
                                  alt=""
                                  className="img-fluid d-block rounded-circle"
                                />
                              </div>
                              <div className="media-body">
                                <h4 className="font-size-14 mb-1">
                                  Clarence Smith{" "}
                                  <small className="text-muted float-right">
                                    2 hrs Ago
                                  </small>
                                </h4>
                                <p className="text-muted">
                                  Neque porro quisquam est, qui dolorem ipsum
                                  quia dolor sit amet
                                </p>
                                <div>
                                  <Link to="#" className="text-success">
                                    <i className="mdi mdi-reply"></i> Reply
                                  </Link>
                                </div>

                                <div className="media pt-3">
                                  <div className="avatar-xs mr-3">
                                    <div className="avatar-title rounded-circle bg-light text-primary">
                                      <i className="bx bxs-user"></i>
                                    </div>
                                  </div>
                                  <div className="media-body">
                                    <h4 className="font-size-14 mb-1">
                                      Silvia Martinez{" "}
                                      <small className="text-muted float-right">
                                        2 hrs Ago
                                      </small>
                                    </h4>
                                    <p className="text-muted">
                                      To take a trivial example, which of us
                                      ever undertakes laborious physical
                                      exercise
                                    </p>
                                    <div>
                                      <Link to="#" className="text-success">
                                        <i className="mdi mdi-reply"></i> Reply
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          {/* <div className="media py-3 border-top">
                              <div className="avatar-xs mr-3">
                                <div className="avatar-title rounded-circle bg-light text-primary">
                                  <i className="bx bxs-user"></i>
                                </div>
                              </div>
                              <div className="media-body">
                                <h4 className="font-size-14 mb-1">
                                  Keith McCoy{" "}
                                  <small className="text-muted float-right">
                                    12 Aug
                                  </small>
                                </h4>
                                <p className="text-muted">
                                  Donec posuere vulputate arcu. phasellus
                                  accumsan cursus velit
                                </p>
                                <div>
                                  <Link to="#" className="text-success">
                                    <i className="mdi mdi-reply"></i> Reply
                                  </Link>
                                </div>
                              </div>
                            </div> */}
                          {/* </div> */}
                        </div>

                        {/* <div className="mt-4">
                          <h4 className="font-size-16 mb-3">Leave a Message</h4>

                          <Form>
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <Label htmlFor="commentname-input">
                                    Name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="commentname-input"
                                    placeholder="Enter name"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label htmlFor="commentemail-input">
                                    Inputl
                                  </Label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="commentemail-input"
                                    placeholder="Enter email"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <FormGroup>
                              <Label htmlFor="commentmessage-input">
                                Message
                              </Label>
                              <textarea
                                className="form-control"
                                id="commentmessage-input"
                                placeholder="Your message..."
                                rows="3"
                              ></textarea>
                            </FormGroup>

                            <div className="text-right">
                              <button
                                type="submit"
                                className="btn btn-success w-sm"
                              >
                                Submit
                              </button>
                            </div>
                          </Form>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default HowToChooseAnOccupationCode
