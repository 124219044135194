import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Card, CardBody, CardTitle, Media, Badge } from "reactstrap";

class ShortageOccupationList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!this.props.socData.solRolesUK) return <React.Fragment />;
    if (
      !this.props.socData.solRolesUK[0] &&
      !this.props.socData.solRolesScotland[0]
    )
      return <React.Fragment />;
    else
      return (
        <Card>
          <CardBody>
            <CardTitle className="mb-3">
              Shortage Occupation List
              {/* job count badge */}
              {this.props.socData.solRolesUK[0] !== "all" && (
                <h6>
                  <Badge pill className="badge-soft-info">
                    {this.props.socData.solRolesUK.length +
                      this.props.socData.solRolesScotland.length}{" "}
                    qualifying job
                    {this.props.socData.solRolesUK.length === 1 ? "" : "s"}{" "}
                    total
                  </Badge>
                </h6>
              )}
            </CardTitle>
                      {this.props.socData.solRolesUK[0] === "all" ? (
                        <div className="mb-2 text-info">
                          All jobs in this code are on the Shortage Occupation
                          List
                        </div>
                      ) : (
                        <div>
                          {this.props.socData.solRolesUK.map((item, i) => (
                            <div key={i} className="mb-2 text-info">
                              {item}
                            </div>
                          ))}
                        </div>
                      )}
                      {!this.props.socData.solRolesScotland[0] ? (
                        <div />
                      ) : this.props.socData.solRolesScotland[0] === "all" ? (
                        <div className="mb-2 text-info">
                          All jobs in this code are on the Shortage Occupation
                          List (Scotland only)
                        </div>
                      ) : (
                        this.props.socData.solRolesScotland.map((item, i) => (
                          <div key={i} className="mb-2 text-info">
                            {item} (where the job is in Scotland{" "}
                            <i className="bx bxs-map font-size-12 text-primary"></i>
                            )
                          </div>
                        ))
                      )}
          </CardBody>
        </Card>
      );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Search };
};

export default connect(mapStatetoProps, {})(ShortageOccupationList);
