import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { Prompt } from 'react-router'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap"
import { changeFormData, resetFormData, changeSearchTerm } from "../../store/actions"
import YourAnswers from "./your-answers"
import QuestionSol from "./question-sol"
import QuestionHours from "./question-hours"
import QuestionLocation from "./question-location"
import QuestionRate from "./question-rate"
import QuestionFte from "./question-fte"
import YesOrNoQuestion from "./question-yes-no"
import SalaryReferenceCard from "./salary-reference-card"
import getMinSalary from "../../helpers/get-min-salary"
import ResultsCard from "./results-card"
import "./styles.css"
import { v4 as uuidv4 } from 'uuid';

const DashBoardSocQuestions = props => {
  const [minSalary, setMinSalary] = useState('')
  
  useEffect(() => {
    setMinSalary(getMinSalary(props))
  }, [props])
  
  const {
    soc,
    socData: {
      socTitle,
      solRolesUK,
      solRolesScotland,
      getsGoingRateFromPayScale,
      isEducationCode,
      isPhdeligible,
      isPostDoceligible,
      goingRates,
    },
    socForm: {
      isSolJob,
      jobLocation,
      rateBand,
      jobHoursPerWeek,
      jobPercentFTE,
      applicantHasPhd,
      phdIsRelevant,
      phdIsStem,
      willApplicantBeUnder26,
      isApplicantBeingQualified,
      isApplicantMovingIntoPhd,
      isApplicantUkStudent,
      applicantsLastVisaWasStudent,
      isApplicantNewGraduate,
      isApplicantSwitchingFromTier1,
      isJobRegulated,
      applicantWillBeGettingQualified,
      applicantWillBeGettingRegistered,
      agreedToMoreQuestions,
      agreedToProceedAsNewEntrant
    },
    socForm,
    changeFormData,
    resetFormData,
    changeSearchTerm
  } = props

  const [socQuestionsAreComplete, setSocQuestionsAreComplete] = useState(false)
  const [skipPrompt, setSkipPrompt] = useState(false)

  useEffect(() => {
    // set default answers
    let res = { ...socForm, soc }
    res.id = uuidv4()
    res.dateCreated = new Date().toISOString()
    res.assessmentTitle = `Assessment for ${socTitle}`
    if (!solRolesUK.length > 0 && !solRolesScotland.length > 0)
      res.isSolJob = false
    if (solRolesUK[0] === "all") res.isSolJob = true
    if (solRolesUK[0] === "all") res.solTitle = socTitle
    // if (getsGoingRateFromPayScale === false)
    if (goingRates[0].nationalRate)
      (res.jobLocation = "nationalRate"), (res.jobLocationName = "UK")
    if (getsGoingRateFromPayScale === false) res.rateBand = null
    if (isEducationCode === true) res.jobHoursPerWeek = null
    if (isEducationCode === false) res.jobPercentFTE = null
    if (isPhdeligible === false) res.applicantHasPhd = null
    if (isPhdeligible === false) res.phdIsStem = null
    if (isPhdeligible === false) res.phdIsRelevant = null
    if (isPostDoceligible === false) res.isApplicantMovingIntoPhd = null
    changeFormData(res)
    changeSearchTerm('')
  }, [])

  const newEntrantQuestionsDone = () => {
    if (
      // applicant qualifies for new entrant
      willApplicantBeUnder26 === true ||
      isApplicantBeingQualified === true ||
      isApplicantNewGraduate === true ||
      isApplicantSwitchingFromTier1 === true ||
      applicantWillBeGettingQualified === true ||
      applicantWillBeGettingRegistered === true ||
      isApplicantMovingIntoPhd === true
    )
      return true
      
    if (
    // applicant doesn't qualify for new entrant
      willApplicantBeUnder26 === false &&
      isApplicantSwitchingFromTier1 === false &&
      applicantWillBeGettingRegistered === false &&
      (isApplicantMovingIntoPhd === false || isApplicantMovingIntoPhd === null)
    ) {
      if (agreedToProceedAsNewEntrant === '') {
      //Set New Entrant flag to false  
      let res = { ...socForm }
      res.agreedToProceedAsNewEntrant = false
      changeFormData(res)
      }
      return true
      }
    else return false
  }

  const handleReset = () => {
    // setSkipPrompt(true)
    // resetFormData()
    props.history.push("/dashboard-soc-info")
  }

  const handleNextStep = (salary, newEntrant) => {
    let res = { ...socForm }
    res.minSalary = salary
    res.agreedToProceedAsNewEntrant = newEntrant
    if (getsGoingRateFromPayScale === true) res.agreedToProceedAsNewEntrant = false
    if (minSalary.default === "£20,480" && isSolJob === true) res.agreedToProceedAsNewEntrant = ''
    if (willApplicantBeUnder26 === true) res.isOver65 = false
    res.status = 'basic assessment complete'
    props.changeFormData(res)
    setSocQuestionsAreComplete(true)
  };

  useEffect(() => {
    socQuestionsAreComplete && props.history.push("/dashboard-soc-summary");
  }, [socQuestionsAreComplete])

  const getNextQuestion = () => {
    if (isSolJob === "") return <QuestionSol />
    if (jobLocation === "") return <QuestionLocation />
    if (rateBand === "") return <QuestionRate />
    if (jobHoursPerWeek === "" || (jobPercentFTE > 100 && !jobHoursPerWeek > 0))
      return (
        <QuestionHours defaultHours={goingRates[0].standardWeeklyHours || 39} />
      )
    if (jobPercentFTE === "") return <QuestionFte />
    if (agreedToMoreQuestions === "") return <InitialResultsCard />
    if (newEntrantQuestionsDone())
      return (
        <>
          <ResultsCard handleReset={handleReset} handleNextStep={handleNextStep}/>
          <SalaryReferenceCard />
        </>
      )
    if (applicantHasPhd === "" && isSolJob === false)
      return (
        <YesOrNoQuestion
          key={0}
          question={"Does the applicant hold a PhD?"}
          property={"applicantHasPhd"}
        />
      )
    if (applicantHasPhd === true && phdIsRelevant === "")
      return (
        <YesOrNoQuestion
          key={1}
          question={"Is this PhD relevant to the job?"}
          property={"phdIsRelevant"}
        />
      )
    if (phdIsRelevant === true && phdIsStem === "")
      return (
        <YesOrNoQuestion
          key={2}
          question={"Is the PhD in a STEM subject?"}
          property={"phdIsStem"}
        />
      )
    if (willApplicantBeUnder26 === "")
      return (
        <YesOrNoQuestion
          key={3}
          question={
            "Will the applicant be under 26 when they submit their application?"
          }
          property={"willApplicantBeUnder26"}
        />
      )
    if (isApplicantUkStudent === "")
      return (
        <YesOrNoQuestion
          key={4}
          question={"Is the applicant currently in the UK as a student?"}
          property={"isApplicantUkStudent"}
        />
      )
    if (isApplicantUkStudent === false && applicantsLastVisaWasStudent === "")
      return (
        <YesOrNoQuestion
          key={5}
          question={
            "Was the applicant's last UK visa a student visa which expired less than 2 years ago?"
          }
          property={"applicantsLastVisaWasStudent"}
        />
      )
    if (
      (isApplicantUkStudent === true ||
        applicantsLastVisaWasStudent === true) &&
      isApplicantNewGraduate === ""
    )
      return (
        <YesOrNoQuestion
          key={6}
          question={
            <>
              <div className="font-size-22 pb-2">
                Has the applicant completed (or is fewer than 3 months away from
                completing) one of the following courses in the UK?
              </div>
              <div className="font-size-18 pt-2">
                - a UK bachelor’s degree
                <br /> - a UK master’s degree
                <br /> - a UK PhD or other doctoral qualification
                <br /> - a Postgraduate Certificate in Education
                <br /> - a Professional Graduate Diploma of Education
                <br />
                <br />
                If the applicant is studying for a PhD and has completed at
                least 12 months of study towards the PhD, you should also click
                'Yes'.
              </div>
            </>
          }
          property={"isApplicantNewGraduate"}
        />
      )

    if (isApplicantSwitchingFromTier1 === "")
      return (
        <YesOrNoQuestion
          key={7}
          question={
            "Is the applicant switching from a Tier 1 (Graduate Entrepeneur) visa from within the UK?"
          }
          property={"isApplicantSwitchingFromTier1"}
        />
      )

    if (isJobRegulated === "")
      return (
        <YesOrNoQuestion
          key={8}
          question={"Is the job in a UK regulated profession?"}
          property={"isJobRegulated"}
        />
      )

    if (isJobRegulated === true && applicantWillBeGettingQualified === "")
      return (
        <YesOrNoQuestion
          key={9}
          question={
            "Will the applicant be working towards a recognised profesional qualification for their job?"
          }
          property={"applicantWillBeGettingQualified"}
        />
      )

    if (applicantWillBeGettingRegistered === "")
      return (
        <YesOrNoQuestion
          key={10}
          question={
            "Will the applicant be working towards full registration or chartered status with the relevant professional body for the job they will be doing?"
          }
          property={"applicantWillBeGettingRegistered"}
        />
      )

    if (isApplicantMovingIntoPhd === "")
      return (
        <YesOrNoQuestion
          key={11}
          question={
            "Is the applicant moving directly into a postdoctoral position?"
          }
          property={"isApplicantMovingIntoPhd"}
        />
      )
  }

  const getIntialResultsCardContent = () => {
    {
      /* If pay scale, salary cannot be lower */
    }
    if (getsGoingRateFromPayScale === true)
      return (
        <>
          <p className="mb-3 mt-4">
            <i className="bx bx-info-circle text-info" />{" "}
            <strong>
              You’ll need to pay the applicant at least this amount as a basic
              salary
            </strong>{" "}
            – you can give the applicant additional allowances or benefits in
            kind but they cannot be counted towards this minimum salary.
          </p>
          <p className="mb-0">
            <i className="mdi mdi-comment-question-outline text-info" />{" "}
            <strong>What about applying with a PhD or as a New Entrant?</strong>{" "}
            This occupation code has a specified payscale and the applicant must be
            paid this salary, even if they are a New Entrant or have a PhD.
          </p>
        </>
      )

    {
      /* If default is £20,480 and SOL job, explain why no lower */
    }
    if (minSalary.default === "£20,480" && isSolJob === true)
      return (
        <>
          <p className="mb-3 mt-4">
            <i className="bx bx-info-circle text-info" />{" "}
            <strong>
              You’ll need to pay the applicant at least this amount as a basic
              salary
            </strong>{" "}
            – you can give the applicant additional allowances or benefits in
            kind but they cannot be counted towards this minimum salary.
          </p>
          <p className="mb-0">
            <i className="mdi mdi-comment-question-outline text-info" />{" "}
            <strong>What about applying with a PhD or as a New Entrant?</strong>{" "}
            This role is on the Shortage Occupation List and the minimum salary
            threshold is £20,480. This cannot be lowered further, even where the
            applicant has a PhD or is a New Entrant.
          </p>
        </>
      )

    {
      /* If default is £20,480, and not SOL role or payscale, report edge case */
    }
    if (minSalary.default === "£20,480")
      return (
        <>
          edge case detected, role default minimum is £20,480 but it is not
          payscale or SOL role
        </>
      )
  
     else return (
        <p className="mt-4 mb-2">
          Alternatively, by answering a few additional questions about the
          applicant, we can see if any extra salary reductions apply <br />
          <Button
            color="primary"
            className="waves-effect waves-light mt-1 shadow"
            onClick={() =>
              changeFormData({ ...socForm, agreedToMoreQuestions: true })
            }
          >
            <i className="bx bx-plus align-top font-size-20"></i>
            Answer additional questions
            <i className="bx bx-chevrons-right ml-1 align-middle font-size-20"></i>
          </Button>
        </p>
      )
  }

  const InitialResultsCard = () => {
    return (
      <Card className="w3-animate-right">
        <CardBody>
          {/* Initial result shown to all users */}
          <p>The minimum salary for this position is:</p>
          <h2 className="text-secondary mt-3 mb-0">{minSalary.default}</h2>
          If you will pay at least this amount, click here to proceed to the
          next stage of the process <br />
          <Button
            color="primary"
            className="waves-effect waves-light mt-1 shadow btn-lg"
            onClick={() => handleNextStep(minSalary.default, '')}
          >
            We’ll pay at least {minSalary.default}
            <i className="bx bx-chevrons-right ml-1 align-middle font-size-24"></i>
          </Button>
          {getIntialResultsCardContent()}
        </CardBody>
      </Card>
    )
  }

  return (
    <>
    {!socQuestionsAreComplete && <Prompt
      message='You have not completed the initial assessment. If you continue, the assessment will be lost. Are you sure you want to continue?'
    />}

    <div className="page-content">
      <Container>
        <Breadcrumbs title={"Home"} breadcrumbItem={"Calculate minimum salary for this job"} />

        <Row>
          <Col>
            {!jobHoursPerWeek && !jobPercentFTE && (
              <Card className="w3-animate-right">
                <CardBody className="text-info">
                  <i className="bx bx-check font-size-18 align-top"></i>
                  Thanks for confirming the most appropriate occupation code is {
                    soc
                  } - {socTitle}. Tell us a bit more about the job...
                </CardBody>
              </Card>
            )}

            {jobHoursPerWeek !== "" &&
              jobPercentFTE !== "" &&
              !agreedToMoreQuestions && (
                <Card className="w3-animate-right">
                  <CardBody className="text-info">
                    <i className="bx bx-check font-size-18 align-top"></i>
                    Thanks for telling us about the job...
                  </CardBody>
                </Card>
              )}

            {agreedToMoreQuestions && !newEntrantQuestionsDone() && (
              <Card className="w3-animate-right">
                <CardBody className="text-info">
                  <i className="bx bx-check font-size-18 align-top"></i>
                  Answer these additional questions to see if any extra salary
                  reductions apply...
                </CardBody>
              </Card>
            )}

            {newEntrantQuestionsDone() && (
              <Card className="w3-animate-right">
                <CardBody className="text-info">
                  <i className="bx bx-check font-size-18 align-top"></i> Thanks
                  for answering the additional questions...
                </CardBody>
              </Card>
            )}

            <div className='mb-4'>{getNextQuestion()}</div>

            <YourAnswers
              handleReset={handleReset}
              newEntrantQuestionsDone={newEntrantQuestionsDone}
            />

            {/* <UndoRedo /> */}
          </Col>
        </Row>
      </Container>
    </div>
    </>
  )
}

const mapStatetoProps = state => {
  const past = state.Forms.past
  return { ...state.Forms.present, past, ...state.Search }
}

export default connect(mapStatetoProps, {
  changeFormData,
  resetFormData,
  changeSearchTerm
})(withRouter(DashBoardSocQuestions))
