import React, { useEffect, useState } from "react"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer"
import dayjs from "dayjs"
import { englishTeachingCountries } from "../../common/data/index"
import whatvisalogo from "../../assets/images/what-visa-logo.png"
import poppins from "./Fonts/poppins.ttf"
import poppins600 from "./Fonts/poppins-600.ttf"
import arialItalic from "./Fonts/Arial-Italic.ttf"
import {
  getSalaryBoxTextPlain,
  getOtherBoxText,
  getSalaryBoxPoints,
  getOtherBoxPoints,
} from "../../helpers/get-tradeable"

const BORDER_COLOR = "#bfbfbf"
const BORDER_STYLE = "solid"
const COL1_WIDTH = 80
const COLN_WIDTH = 20

// Register font
Font.register({
  family: "Poppins",
  fonts: [
    {
      src: poppins,
    },
    {
      src: poppins600,
      fontWeight: 600,
    },
    {
      src: arialItalic,
      fontStyle: "italic",
    },
  ],
})

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    fontFamily: "Poppins",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    fontSize: 10,
    fontFamily: "Poppins",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    lineHeight: 1.3,
  },
  title: {
    textAlign: "justify",
    paddingBottom: 10,
    fontWeight: 600,
  },
  text: {
    textAlign: "left",
    paddingBottom: 10,
  },
  italic: {
    fontStyle: "italic",
  },
  bold: {
    fontWeight: 600,
  },
  centered: {
    textAlign: "center",
    paddingBottom: 10,
  },
  list: {
    textAlign: "left",
    paddingBottom: 5,
    paddingHorizontal: 65,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 15,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol1Header: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeader: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol1: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  image: {
    marginVertical: 45,
    marginHorizontal: 160,
    marginBottom: 25,
  },
})

const hyphenationCallback = word => {
  return [word]
}

Font.registerHyphenationCallback(hyphenationCallback)

const MyDocSummary = props => {
  const {
    socData,
    socData: { socTitle, hasEligibleRoles },
    socForm,
    socForm: {
      id,
      soc,
      status,
      dateCreated,
      isSolJob,
      applicantHasPhd,
      phdIsStem,
      willApplicantBeUnder26,
      agreedToProceedAsNewEntrant,
      employerisARated,
      employerWillGetLicence,
      isOver65,
      hasPhysicalMentalCondition,
      gotPastVisaWithB1,
      nationality,
      applicantCountrySpeaksEnglish,
      hasUKDegree,
      hasEnglishGcseEquivalent,
      studiedAtForeignUniInEnglish,
      hasForeignDegree,
      hasOrWillPassLanguageTest,
      hasUKDegreeDocs,
      hasEnglishGcseEquivalentDocs,
      hasForeignDegreeDocs,
      degreeCountry,
      assessmentTitle,
    },
  } = props

  const [username, setusername] = useState("")

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setusername(obj.displayName || obj.email)
    }
  }, [])

  return (
    <Document>
      <Page size="A4" style={styles.body} wrap>
        <View style={styles.section}>
          <Image style={styles.image} src={whatvisalogo} />
          <Text
            style={{
              fontSize: 30,
              textAlign: "center",
              marginTop: 0,
              marginBottom: 35,
              color: "#556ee6",
              fontWeight: 600,
            }}
          >
            Summary Assessment Report
          </Text>

          <Text
            style={{
              fontSize: 14,
              textAlign: "center",
              marginTop: 0,
              marginBottom: 0,
              fontWeight: 600,
            }}
          >
            Skilled Worker immigration route
          </Text>
          <Text
            style={{
              fontSize: 14,
              textAlign: "center",
              marginTop: 0,
              marginBottom: 20,
              fontWeight: 600,
            }}
          >
            Produced for {username} on{" "}
            {dayjs(new Date()).format("DD MMMM, YYYY")}
          </Text>

          <Text
            style={{
              fontSize: 14,
              textAlign: "center",
              marginTop: 0,
              marginBottom: 0,
              fontWeight: 600,
            }}
          >
            Assessment name:
          </Text>

          <Text
            style={{
              fontSize: 14,
              textAlign: "center",
              marginTop: 0,
              marginBottom: 35,
              fontWeight: 600,
            }}
          >
            {assessmentTitle}
          </Text>
          <Text style={styles.centered}>
            This report summarises the assessment completed by the User on
            WhatVisa.com and shows the relevant applicant and job’s eligibility
            for sponsorship under the Skilled Worker immigration route in
            several key areas:
          </Text>
          <Text style={styles.list}>
            • Checking the eligibility of the most relevant occupation (SOC)
            code
          </Text>
          <Text style={styles.list}>
            • Determination of the relevant salary threshold - how much the
            applicant will need to be paid to be eligible for a Skilled Worker
            visa
          </Text>
          <Text style={styles.list}>
            • Eligibility for non-tradeable and tradeable points for English
            Language, salary and qualifications
          </Text>
          <Text style={styles.centered}>
            These elements represent the fundamental requirements of the Skilled
            Worker route.
          </Text>

          <Text style={styles.title} break>
            <Text style={styles.centered}>Assessment outcome - Eligible</Text>
          </Text>
          <Text style={styles.text}>
            From the information provided to us as part of this assessment, the
            applicant and the job they will be performing appear to be eligible
            for sponsorship under the Skilled Worker immigration route
          </Text>
          <Text style={styles.title}>
            <Text style={styles.centered}>Points assessment</Text>
          </Text>
          <Text style={styles.text}>
            To be eligible, an applicant must be awarded 50 points from the
            mandatory section of the points table and 20 points from the
            tradeable section of the points table. From the information
            provided, the applicant is eligible for the following points:
          </Text>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1Header}>
                <Text style={styles.tableCellHeader}>
                  Mandatory points – 50 points required
                </Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Points awarded</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>
                  <Text style={styles.italic}>
                    Offer of a job by an approved sponsor
                  </Text>
                  {employerisARated === false &&
                    employerWillGetLicence === "" &&
                    "\n\n You have told us that the employer offering sponsorship does not yet have an A-Rated Sponsor Licence covering the Skilled Worker route"}
                  {employerisARated === true &&
                    "\n\n You have told us that the employer offering sponsorship has an A-Rated Sponsor Licence covering the Skilled Worker route"}
                  {employerWillGetLicence === true &&
                    "\n\n You have told us that the employer offering sponsorship does not yet have an A-Rated Sponsor Licence covering the Skilled Worker route. \n\n You have answered that you do wish to continue with the assessment. You are eligible for 20 points, conditional on the employer obtaining a Sponsor Licence before this application for sponsorship is submitted."}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  20 {employerWillGetLicence === true && "(conditional)"}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>
                  <Text style={styles.italic}>
                    Job at an appropriate skill level
                  </Text>
                  {`\n\n  You have told us that ${soc} - ${socTitle} is the most appropriate occupation code for the job`}
                  {hasEligibleRoles === "solOnly" &&
                    isSolJob &&
                    ` and that the job is on the Shortage Occupation List`}
                  {
                    ". This code meets the relevant skills threshold and the job is therefore at an appropriate skill level."
                  }
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>20</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>
                  <Text style={styles.italic}>
                    English Language skills at level B1:
                  </Text>
                  {isOver65 &&
                    `\n\n You have told us that the applicant is aged 65 or over, and so is exempt from this requirement`}
                  {hasPhysicalMentalCondition &&
                    `\n\n You have told us that the applicant has a physical or mental condition which prevents them from meeting this requirement, and so is exempt`}
                  {gotPastVisaWithB1 &&
                    `\n\n You have told us that the applicant has demonstrated English Language skills at level B1 or above in a previous successful visa application for the UK, and so meets this requirement automatically`}
                  {applicantCountrySpeaksEnglish &&
                    `\n\n You have told us that the applicant is a national of a majority English language speaking country`}
                  {hasUKDegreeDocs &&
                    `\n\n You have told us that the applicant has an eligible degree awarded in the UK`}
                  {hasEnglishGcseEquivalentDocs &&
                    `\n\n You have told us that the applicant has an eligible qualification awarded in the UK`}
                  {hasForeignDegreeDocs &&
                    englishTeachingCountries.includes(degreeCountry) &&
                    `\n\n You have told us that the applicant has an eligible degree or degree level qualification that was taught in a majority English language speaking country`}
                  {hasForeignDegreeDocs &&
                    !englishTeachingCountries.includes(degreeCountry) &&
                    `\n\n You have told us that the applicant has an eligible degree or degree level qualification that was taught in English`}
                  {hasOrWillPassLanguageTest &&
                    `\n\n You have told us that the applicant has passed or will pass an approved English Language test`}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>10</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>Total points in section</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  50{" "}
                  {employerWillGetLicence === true && "(incl. 20 conditional)"}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1Header}>
                <Text style={styles.tableCellHeader}>
                  Tradeable points – 20 points required
                </Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Points awarded</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>
                  <Text style={styles.italic}>Salary</Text>
                  {"\n\n" + getSalaryBoxTextPlain(socData, socForm)}
                  <Text style={styles.italic}>
                    {getSalaryBoxPoints(socData, socForm) < 1 &&
                      "\n\n N.B. this is the minimum salary, even though it does not gain any additional tradeable points"}
                  </Text>
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {getSalaryBoxPoints(socData, socForm)}
                </Text>
              </View>
            </View>

            {getOtherBoxText(socData, socForm) !== null && (
              <View style={styles.tableRow}>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>
                    <Text style={styles.italic}>Other</Text>
                    {"\n\n" + getOtherBoxText(socData, socForm)}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {getOtherBoxPoints(socData, socForm)}
                  </Text>
                </View>
              </View>
            )}

            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>Total points in section</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>20</Text>
              </View>
            </View>
          </View>

          <Text style={styles.text}>
            From the information provided, the applicant is eligible for the 70
            points required by the Skilled Worker immigration route.
          </Text>

          <Text style={styles.text}>
            <Text style={styles.bold}>
              For further information on all of these requirements and how the
              minimum salary has been calculated, please download your Detailed
              Assessment Report from the My Assessments page.
            </Text>
          </Text>

          <Text style={styles.title} break>
            What next?
          </Text>
          <Text style={styles.text}>
            <Text style={styles.italic}>
              If you are an applicant working with your employer or prospective
              employer to validate that a Skilled Worker application is
              possible...
            </Text>
          </Text>
          <Text style={styles.text}>
            Feel free to forward this report to the employer – it will save them
            trawling through the immigration rules and guidance to calculate the
            minimum salary for your application. You may also want to send them
            your <Text style={styles.bold}>Detailed Assessment Report</Text>{" "}
            which can be downloaded for free from the My Assessments page.
          </Text>
          <Text style={styles.text}>
            If the employer is not familiar with the sponsorship process or does
            not yet hold a Sponsor Licence, you may want to help them navigate
            the process by purchasing our{" "}
            <Text style={styles.bold}>Employer Guide</Text>. The guide contains
            detailed, easy to understand guidance on the sponsorship process,
            put together by our experts with over a decade of experience in
            helping employers sponsor migrant workers.
          </Text>

          <Text style={styles.text}>
            </Text>
          <Text style={styles.text}>
            <Text style={styles.italic}>
              If you are an employer looking to sponsor a worker...
            </Text>
          </Text>
          <Text style={styles.text}>
            If you already have a Sponsor Licence, you can now assign a
            Certificate of Sponsorship to the applicant - this will facilitate
            their application for permission to enter or remain in the UK under
            the Skilled Worker immigration route. If you are looking for further
            guidance on how to assign a Certificate of Sponsorship or the
            process, timeframe and cost associated with the application, you can
            find this in our <Text style={styles.bold}>Employer Guide</Text>{" "}
            which can be downloaded from the My Assessments page.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.title} break></Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.title} break></Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.title} break></Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.title} break>
            Disclaimer
          </Text>

          <Text style={styles.text}>
            The information contained within this Report should be read in
            conjunction with the
            <Text style={styles.bold}>Detailed Assessment Report</Text>,
            available for free on WhatVisa.com. This information is intended to
            guide potential applicants and sponsors through the various
            requirements associated with the UK’s Skilled Worker immigration
            route and explain how these requirements can be met. Unless
            otherwise noted, all guidance stems from the UK’s immigration rules
            and official guidance published by UK Visas & Immigration (UKVI).
          </Text>

          <Text style={styles.text}>
            The information and commentary contained within this report does
            not, and is not intended to, amount to legal advice to any person.
          </Text>

          <Text style={styles.text}>
            What Visa? (referred to ‘us’ and ‘we’ throughout) is an independent
            organisation run by immigration industry experts with over a decade
            of experience. We are not an official Governmental body and are not
            regulated by the OISC or the SRA.
          </Text>

          <Text style={styles.text}>
            All information relating to ‘the sponsor’, ‘the applicant’, and ‘the
            job’ originates from information entered into the WhatVisa.com
            online assessment engine by the user (referred to as ‘you’
            throughout), and we can take no responsibility for its accuracy.
          </Text>

          <Text style={styles.text}>2021 © WhatVisa? www.whatvisa.com</Text>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  )
}

export default MyDocSummary
