import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, Badge } from "reactstrap";

const CardSocTitle = ({ soc, socTitle, hasEligibleRoles }) => {
  return (
    <Card className="">
      <CardBody className="pb-3">
        <div className="d-flex justify-content-between align-items-top flex-wrap">
          <div>
            <h4 className="mb-1">
              {soc} - {socTitle}
            </h4>
          </div>

          <div className="mt-1 w3-animate-right">
            <h5 className="mb-0 mr-2">
              <Badge
                pill
                type="button"
                className="btn-rounded waves-effect waves-light"
                color={
                  hasEligibleRoles === "all"
                    ? "soft-success"
                    : hasEligibleRoles === "none"
                    ? "danger"
                    : hasEligibleRoles === "solOnly" && "warning"
                }
                size="sm"
              >
                {hasEligibleRoles === "all" && <><i className="bx bx-check-double font-size-18 align-middle mr-1"></i>Eligible for sponsorship</>}
                {hasEligibleRoles === "none" && <><i className="bx bx-error font-size-14 align-bottom mr-1"></i>Not eligible for sponsorship</>}
                {hasEligibleRoles === "solOnly" &&
                  "Only shortage occupation roles are eligible for sponsorship"}
              </Badge>
            </h5>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStatetoProps = (state) => {
  return { ...state.Search.socData };
};

export default connect(mapStatetoProps, {})(CardSocTitle);
