import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Button } from "reactstrap"
import { withRouter } from "react-router-dom"
import jsonFormatted from "./jsonFormatted.json"
import { MDBDataTableV5 } from "mdbreact"
import { connect } from "react-redux"
import { changeSocCode, changeSocData } from "../../store/actions"
import getMinSalaryRange from "../../helpers/get-min-salary-range"
import ReactTooltip from "react-tooltip"
import jsonApiData from "./jsonApiData.json"
import axios from "axios"

const SOCResultsTable = props => {
  const [sortField, setSortField] = useState("soc")
  const [sortOrderAsc, setSortOrderAsc] = useState(true)
  const [data, setData] = useState([])
  const [appState, setAppState] = useState({ loading: false })
  const [apiResults, setApiResults] = useState([]) //An array of soc codes from the apiData that match current search term
  const [myData, setMyData] = useState({})

  useEffect(() => {
    setData(jsonFormatted)
  }, [])

  const { searchTerm } = props

  useEffect(() => {
    //Populate apiResults when searchTerm changes
    setApiResults([])
    if (!searchTerm) return
    if (searchTerm.length < 2) return
    let res = []
    //Find soc codes that match search term and add them to the array
    jsonApiData.forEach(code => {
      if (code.title.toLowerCase().startsWith(searchTerm.toLowerCase())) {
        res = [...res, code.soc]
        return
      }
      //Look for matches in each individual word
        code.title.toLowerCase()
        .split(" ")
        .forEach(word => {
          if (word.startsWith(searchTerm.toLowerCase())) res = [...res, code.soc]
        })
     })
    setApiResults(res)
  }, [searchTerm])

  // useEffect(async () => {
  //   try {
  //     if (!searchTerm) return
  //     if (searchTerm.length < 2) return setApiResults([])
  //     let searchTermTrimmed = searchTerm.trim(),
  //       url
  //     if (searchTermTrimmed.includes(" "))
  //       url = `https://api.lmiforall.org.uk/api/v1/soc/search?q=%22${searchTermTrimmed}%22` //Search in quotes
  //     if (!searchTermTrimmed.includes(" "))
  //       url = `https://api.lmiforall.org.uk/api/v1/soc/search?q=${searchTermTrimmed}`
  //     let res = await axios.get(url)
  //     res = res.data.map(i => i.soc)
  //     if (res.length > 0) return setApiResults(res)

  //     //Add wildcard to search if no results found
  //     console.log('no exact results found. looking for more...')
  //     url = `https://api.lmiforall.org.uk/api/v1/soc/search?q=${searchTermTrimmed}*`
  //     res = await axios.get(url)
  //     res = res.data.map(i => i.soc)
  //     setApiResults(res)

  //   } catch (error) {
  //     console.error(error)
  //     setApiResults([])
  //   }
  // }, [searchTerm])

  const handleClick = newSortField => {
    if (newSortField === sortField) {
      setSortOrderAsc(!sortOrderAsc)
      return
    } else if (newSortField !== sortField) {
      setSortField(newSortField)
      setSortOrderAsc(true)
      return
    }
  }

  const handlePageChange = entry => {
    // props.changeSocData(entry.soc);
    props.changeSocCode(entry.soc)
    props.history.push("/dashboard-soc-info")
  }

  useEffect(() => {
    // console.log('setmydata - searchTerm:', searchTerm)
    // console.log('setmydata - apiResults:', searchTerm)
    setMyData({
      columns: [
        {
          label: <div onClick={() => handleClick("soc")}>Code</div>,
          field: "soc",
          // width: 50,
        },
        {
          label: <div onClick={() => handleClick("socTitle")}>Occupation</div>,
          field: "socTitleWithArrow",
          width: 170,
        },
        {
          label: (
            <div>
              <span onClick={() => handleClick("minSalary")}>
                Minimum salary range
              </span>
              <span data-tip="Indicative minimum salary range - we'll determine the exact minimum in the next stage">
                <i className="bx bx-info-circle font-size-14 ml-1 text-info" />
                <ReactTooltip className="tooltip" />
              </span>
            </div>
          ),
          field: "minSalary",
          width: 150,
        },
        {
          label: (
            <div onClick={() => handleClick("hasEligibleRoles")}>
              Eligibility
            </div>
          ),
          field: "eligibleRolesButton",
          width: 150,
        },
      ],
      rows: [...data]
        .map((code, i) => {
          let { hasEligibleRoles, socTitle } = code
          let res = {
            ...code,
            clickEvent: () => handlePageChange(code),
            socTitleWithArrow: (
              <p className="mb-1">
                {socTitle}
                <i className="bx bx-chevrons-right font-size-16 ml-1 align-middle text-secondary"></i>
              </p>
            ),
          }

          // min salary column
          res.minSalary = getMinSalaryRange(code)

          // eligible for sponsorship column
          res.eligibleRolesButton = (
            <>
              <Button
                type="button"
                color={
                  hasEligibleRoles === "all"
                    ? "success"
                    : hasEligibleRoles === "none"
                    ? "danger"
                    : hasEligibleRoles === "solOnly" && "warning"
                }
                size="sm"
                className="btn-rounded"
              >
                {hasEligibleRoles === "all" && (
                  <>
                    <i className="bx bx-check-double font-size-18 align-top mr-1"></i>
                    All roles are eligible
                  </>
                )}
                {hasEligibleRoles === "none" && (
                  <>
                    <i className="bx bx-error font-size-14 align-middle mr-1"></i>
                    No roles are eligible
                  </>
                )}
                {hasEligibleRoles === "solOnly" &&
                  "Only shortage occupation roles are eligible"}
              </Button>
            </>
          )
          return res
        })
        .filter(item => {
        // filter array by search term
          if (searchTerm === "" || searchTerm.length < 3) return true
          if (
            item.socTitle.toLowerCase().includes(searchTerm.toLowerCase()) || //Search term matches item soc title
            item.soc.toString().includes(searchTerm) || //Search term matches item soc code
            apiResults.includes(item.soc) //Item matches code in apiResults array
          )
            return true
          else return false
        })
        // sort table by columns
        .sort(
          sortField === "socTitle" || sortField === "hasEligibleRoles"
            ? function (a, b) {
                if (sortOrderAsc) {
                  if (a[sortField] < b[sortField]) {
                    return -1
                  }
                  if (a[sortField] > b[sortField]) {
                    return 1
                  }
                } else {
                  if (b[sortField] < a[sortField]) {
                    return -1
                  }
                  if (b[sortField] > a[sortField]) {
                    return 1
                  }
                }
                return 0
              }
            : sortOrderAsc
            ? function (a, b) {
                return a[sortField] - b[sortField]
              }
            : function (a, b) {
                return b[sortField] - a[sortField]
              }
        ),
    })
  }, [searchTerm, apiResults, data])

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-1 pb-3 border-bottom">
          {/* results found */}
          Total {myData.rows && myData.rows.length} result
          {myData.rows && myData.rows.length !== 1 && "s"}...
        </CardTitle>
        <MDBDataTableV5
          hover
          entriesOptions={[10, 25, 100, 200]}
          entries={10}
          pagesAmount={10}
          data={myData}
          searchTop={false}
          searchBottom={false}
          responsive={false}
          sortable={false}
          // striped
          order={["soc"]}
          style={{ cursor: "pointer" }}
        />
      </CardBody>
    </Card>
  )
}

const mapStatetoProps = state => {
  return { ...state.Search }
}

export default connect(mapStatetoProps, {
  changeSocCode,
  changeSocData,
})(withRouter(SOCResultsTable))
