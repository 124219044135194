import React, { useState } from "react";
import { connect } from "react-redux";
import { changeFormData } from "../../store/actions";
import { Card, CardBody, CardFooter, Col, Row, Button } from "reactstrap";
import UndoRedo from "./UndoRedo"

const YesOrNoQuestion = ({ changeFormData, socForm, question, property }) => {
  const [answer] = useState(socForm[property]);

  const handleClick = (input) => {
    let res = { ...socForm};
    res[property] = input;
    if (input === false) res.showEnglishOptions = true //Fix for the soc-assessments/question-english stage
    changeFormData(res);
  };

  return (
    <React.Fragment>
      <Card className="text-center w3-animate-right mb-0 border">
        <CardBody className="p-5">
          <Row>
            <Col xl={12} className="ml-auto">
              <div className="mt-4 mt-lg-0">
                <h3 className="text-primary mb-4">{question}</h3>
                <div className="button-items">
                  <Button
                    color="light"
                    outline={false}
                    className="waves-effect waves-light w-sm p-4 mr-1 shadow border-0 w3-hover-zoom"
                    onClick={() => {
                      handleClick(false);
                    }}
                  >
                    No
                  </Button>

                  <Button
                    color="light"
                    outline={false}
                    className="waves-effect waves-light w-sm p-4 ml-1 shadow border-0 w3-hover-zoom"
                    onClick={() => {
                      handleClick(true);
                    }}
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="bg-transparent text-left">
          <UndoRedo />
        </CardFooter>
      </Card>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  return { ...state.Forms.present };
};

export default connect(mapStatetoProps, {
  changeFormData,
})(YesOrNoQuestion);
