const englishNativeCountries = [
  "Antigua and Barbuda",
  "Australia",
  "The Bahamas",
  "Barbados",
  "Belize",
  "Canada",
  "Dominica",
  "Grenada",
  "Guyana",
  "Jamaica",
  "Malta",
  "New Zealand",
  "St Kitts and Nevis",
  "St Lucia",
  "St Vincent and the Grenadines",
  "Trinidad and Tobago",
  "United State of America",
]

const englishTeachingCountries = [
  "Antigua and Barbuda",
  "Australia",
  "The Bahamas",
  "Barbados",
  "Belize",
  "Dominica",
  "Grenada",
  "Guyana",
  "Jamaica",
  "Malta",
  "New Zealand",
  "St Kitts and Nevis",
  "St Lucia",
  "St Vincent and the Grenadines",
  "Trinidad and Tobago",
  "United States of America",
]

export { englishNativeCountries, englishTeachingCountries }