import getMinSalary from "./get-min-salary"

const getTradable = (socData, socForm) => {
const { getsGoingRateFromPayScale } = socData 
  const {
    isSolJob,
    applicantHasPhd,
    phdIsStem,
    phdIsRelevant,
    agreedToProceedAsNewEntrant,
  } = socForm

  const MinSalary = getMinSalary({ socForm, socData })

  const agreedSalary = () => {
    // return (agreedToProceedAsNewEntrant === true
    // ? MinSalary.newEntrant
    // : phdIsStem === true
    // ? MinSalary.stem
    // : phdIsRelevant === true
    // ? MinSalary.phd
    // : MinSalary.default)
    if (agreedToProceedAsNewEntrant === true) return MinSalary.newEntrant
    if (phdIsStem === true) return MinSalary.stem
    if (phdIsRelevant) return MinSalary.phd
    return MinSalary.default
  }

  const getTradableObject = () => {
    if (agreedToProceedAsNewEntrant === true)
      return {
        salaryBoxText: (
          <>
            The job will pay at least the higher of:
            <ul>
              <li>{MinSalary.generalSalaryAdjustedNewEntrant}</li>
              <li>
              {MinSalary.goingRateCalculatedNewEntrant}, i.e. 70% of the going rate for the relevant occupation code
              </li>
            </ul>
            You have told us that the annual salary will be at least {agreedSalary()}. This salary meets these requirements
          </>
        ),
        salaryBoxTextPlain: `The job will pay at least the higher of: \n - ${MinSalary.generalSalaryAdjustedNewEntrant} \n - ${MinSalary.goingRateCalculatedNewEntrant}, i.e. 70% of the going rate for the relevant occupation code \n\n You have told us that the annual salary will be at least ${agreedSalary()}. This salary meets these requirements`,
        salaryBoxPoints: 0,
        otherBoxText:
          "You have told us that the applicant qualifies as a New Entrant, will be applying for a visa of up to 4 years and has not held a Tier 2 or Skilled Worker visa before",
        otherBoxPoints: 20,
      }
    if (getsGoingRateFromPayScale)
      return {
        salaryBoxText:
          `The job is a listed health or education job and you have told us that the salary meets the relevant national pay scale, adjusted for weekly hours - ${agreedSalary()}`,
        salaryBoxTextPlain:
          `The job is a listed health or education job and you have told us that the salary meets the relevant national pay scale, adjusted for weekly hours - ${agreedSalary()}`,
        salaryBoxPoints: 20,
        otherBoxText: null,
        otherBoxPoints: 0,
      }
    if (isSolJob)
      return {
        salaryBoxText: (
          <>
            The job will pay at least the higher of:
            <ul>
              <li>{MinSalary.generalSalaryAdjustedSol}</li>
              <li>
                {MinSalary.goingRateCalculatedSol}, i.e. 80% of the going rate for the relevant occupation code
              </li>
            </ul>
            You have told us that the annual salary will be at least {agreedSalary()}. This salary meets these requirements
          </>
        ),
        salaryBoxTextPlain: `The job will pay at least the higher of:\n- ${MinSalary.generalSalaryAdjustedSol} \n - ${MinSalary.goingRateCalculatedSol}, i.e. 80% of the going rate for the relevant occupation code \n\n You have told us that the annual salary will be at least ${agreedSalary()}. This salary meets these requirements`,
        salaryBoxPoints: 0,
        otherBoxText:
          "You have told us that the role is on the Shortage Occupation List",
        otherBoxPoints: 20,
      }
    if (phdIsStem)
      return {
        salaryBoxText: (
          <>
            The job will pay at least the higher of:
            <ul>
              <li>{MinSalary.generalSalaryAdjustedStem}</li>
              <li>
              {MinSalary.goingRateCalculatedStem}, i.e. 80% of the going rate for the relevant occupation code
              </li>
            </ul>
            You have told us that the annual salary will be at least {agreedSalary()}. This salary meets these requirements
          </>
        ),
        salaryBoxTextPlain: `The job will pay at least the higher of: \n - ${MinSalary.generalSalaryAdjustedStem}  \n - ${MinSalary.goingRateCalculatedStem}, i.e. 80% of the going rate for the relevant occupation code \n\n  You have told us that the annual salary will be at least ${agreedSalary()}. This salary meets these requirements`,
        salaryBoxPoints: 0,
        otherBoxText:
          "You have told us that the applicant has a PhD in a STEM subject relevant to the job",
        otherBoxPoints: 20,
      }
    if (applicantHasPhd)
      return {
        salaryBoxText: (
          <>
            The job will pay at least the higher of:
            <ul>
              <li>{MinSalary.generalSalaryAdjustedPhd}</li>
              <li>
              {MinSalary.goingRateCalculatedPhd}, i.e. 90% of the going rate for the relevant occupation code
              </li>
            </ul>
            You have told us that the annual salary will be at least {agreedSalary()}. This salary meets these requirements
          </>
        ),
        salaryBoxTextPlain: `The job will pay at least the higher of: \n - ${MinSalary.generalSalaryAdjustedPhd} \n - ${MinSalary.goingRateCalculatedPhd}, i.e. 90% of the going rate for the relevant occupation code \n\n You have told us that the annual salary will be at least ${agreedSalary()}. This salary meets these requirements`,
        salaryBoxPoints: 10,
        otherBoxText:
          "You have told us that the applicant has a PhD in a subject relevant to the job",
        otherBoxPoints: 10,
      }
    //Default if none of the above min salary reductions apply
    return {
      salaryBoxText: (
        <>
          The job will pay at least the higher of:
          <ul>
            <li>{MinSalary.generalSalaryAdjusted}</li>
            <li>{MinSalary.goingRateCalculated}, i.e. the going rate for the profession, adjusted for weekly hours</li>
          </ul>
          You have told us that the annual salary will be at least {agreedSalary()}. This salary meets these requirements
        </>
      ),
      salaryBoxTextPlain: `The job will pay at least the higher of: \n - ${MinSalary.generalSalaryAdjusted} \n - ${MinSalary.goingRateCalculated}, i.e. the going rate for the profession, adjusted for weekly hours \n\n You have told us that the annual salary will be at least ${agreedSalary()}. This salary meets these requirements`,
      salaryBoxPoints: 20,
      otherBoxText: null,
      otherBoxPoints: 0,
    }
  }
  return getTradableObject()
}

const getSalaryBoxText = (socData, socForm) => {
  return getTradable(socData, socForm).salaryBoxText
}

const getSalaryBoxTextPlain = (socData, socForm) => {
  return getTradable(socData, socForm).salaryBoxTextPlain
}

const getOtherBoxText = (socData, socForm) => {
  return getTradable(socData, socForm).otherBoxText
}

const getSalaryBoxPoints = (socData, socForm) => {
  return getTradable(socData, socForm).salaryBoxPoints
}

const getOtherBoxPoints = (socData, socForm) => {
  return getTradable(socData, socForm).otherBoxPoints
}


export { getTradable, getSalaryBoxText, getSalaryBoxTextPlain, getOtherBoxText, getSalaryBoxPoints, getOtherBoxPoints } 