import React from 'react'
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import {
    Button,
  } from "reactstrap";
import { connect } from 'react-redux'

let UndoRedo = ({ canUndo, canRedo, onUndo, onRedo }) => (

    <><Button onClick={onUndo} disabled={!canUndo} color='light' outline className="mr-2 btn-sm text-muted">
      <i className="bx bx-undo align-middle font-size-18"></i> Previous
    </Button>
    {/* <button onClick={onRedo} disabled={!canRedo}>
      Redo
    </button> </> */}</>
)

const mapStateToProps = (state) => ({
  canUndo: state.Forms.past.length > 0,
  canRedo: state.Forms.future.length > 0
})

const mapDispatchToProps = ({
  onUndo: UndoActionCreators.undo,
  onRedo: UndoActionCreators.redo
})

UndoRedo = connect(
  mapStateToProps,
  mapDispatchToProps
)(UndoRedo)

export default UndoRedo