import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { resetFormData } from "../../store/actions"
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  Button,
  Media,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

import Breadcrumbs from "../../components/Common/Breadcrumb"

const Disclaimer = props => {
  useEffect(() => {
    // props.resetFormData()
  }, [])

  const [customActiveTab, setcustomActiveTab] = useState("1")

  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  return (
    <div className="page-content">
      <Container>

      <Breadcrumbs
          title={"Home"}
          breadcrumbItem={"Disclaimer"}
        />

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                  
                {/* <Col><h5 className='text-center'>Disclaimer</h5></Col> */}

                <Col>
                  <p className="text-muted mb-4 mt-3">
                    <i className='bx bx-info-circle mr-1 text-info' />
                  The information shown to users of WhatVisa.com is intended to guide potential applicants and sponsors through the various requirements associated with the UK’s Skilled Worker immigration route and explain how these requirements can be met. Unless otherwise noted, all guidance stems directly from the UK’s immigration rules and official guidance published by UK Visas & Immigration (UKVI). The information and commentary on this site does not, and is not intended to, amount to legal advice to any person.
                  </p>
                  <p className="text-muted mb-4 mt-3">
                  What Visa<sup>?</sup> is an independent organisation run by immigration industry experts with over a decade of experience. We are not an official Governmental body and are not regulated by the OISC or the SRA. </p>
                </Col>


                <CardFooter className="bg-transparent border-top">
                  <div className="text-right">
                    <h4>
                      What Visa<sup>?</sup> Visas made{" "}
                      <span className="text-primary">simple</span>
                    </h4>
                  </div>
                </CardFooter>
              </CardBody>
            </Card>

           
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default connect(null, { resetFormData })(
  withTranslation()(Disclaimer)
)
