import React, { useState } from "react"
import { connect } from "react-redux"
import { changeFormData } from "../../store/actions"
import { withRouter } from "react-router-dom"
import { Card, CardBody, CardFooter, Col, Row, Button } from "reactstrap"
import UndoRedo from "./UndoRedo"

const QuestionLocation = props => {
  const {
    changeFormData,
    socForm,
    socData: { goingRates },
  } = props

  const handleClick = location => {
    let res = { ...socForm }
    if (!res.firstQuestion) res.firstQuestion = "jobLocation"
    res.jobLocation = location.value
    res.jobLocationName = location.name
    changeFormData(res)
    return
  }

  const getLocationList = () => {
    let res = []
    if (goingRates && goingRates[0]) {
      goingRates[0].nationalRate &&
        res.push({ value: "nationalRate", name: "UK" })

      goingRates[0].englandRate &&
        !goingRates[0].londonInnerRate &&
        res.push({ value: "englandRate", name: "England" })

      goingRates[0].londonInnerRate &&
        res.push({
          value: "londonInnerRate",
          name: "England - London (Inner)",
        })

      goingRates[0].londonOuterRate &&
        res.push({
          value: "londonOuterRate",
          name: "England - London (Outer)",
        })

      goingRates[0].londonFringeRate &&
        res.push({
          value: "londonFringeRate",
          name: "England - London (Fringe)",
        })

      goingRates[0] &&
        goingRates[0].englandRate &&
        goingRates[0].londonInnerRate &&
        res.push({ value: "englandRate", name: "England - outside London" })

      goingRates[0] &&
        goingRates[0].scotlandRate &&
        res.push({ value: "scotlandRate", name: "Scotland" })

      goingRates[0] &&
        goingRates[0].walesRate &&
        res.push({ value: "walesRate", name: "Wales" })

      goingRates[0] &&
        goingRates[0].northernIrelandRate &&
        res.push({ value: "northernIrelandRate", name: "Northern Ireland" })
    }

    //Answer automatically if there is only one option
    // if (res.length === 1) {
    //   handleClick(res[0], true)
    //   return []
    // }
    return res
  }

  return (
    <React.Fragment>
      <Card className="w3-animate-right">
        <CardBody className="text-center p-5">
          <h2 className="text-primary mb-3">The job is based in...</h2>
          <Row className="d-flex justify-content-center">
            <Col xl={6} lg={7} sm={9} className="">
              <div className="text-muted mb-4">
                {getLocationList().map((location, i) => (
                  <Button
                    color="light"
                    outline={false}
                    className="text-left mb-2 p-3 btn-block shadow bg-light"
                    key={i}
                    onClick={() => handleClick(location)}
                  >
                    {location.name}
                  </Button>
                ))}
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="bg-transparent text-left">
          {socForm.firstQuestion && socForm.firstQuestion !== "jobLocation" ? (
            <UndoRedo />
          ) : (
            <Button
              color="light"
              className="waves-effect waves-light mr-2 text-primary btn-sm"
              outline
              onClick={() => props.history.push('/dashboard-soc-info')}
            >
             <i className="bx bx-undo align-middle font-size-18"></i> Previous
            </Button>
          )}
        </CardFooter>
      </Card>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  return { ...state.Forms.present, ...state.Search }
}

export default connect(mapStatetoProps, {
  changeFormData,
})(withRouter(QuestionLocation))
