import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { resetFormData } from "../../store/actions"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  TabContent,
  TabPane,
  Collapse,
  NavLink,
  NavItem,
  CardText,
  Nav,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap"

const InfoCard = props => {
  return (
    <Col lg={4} md={4}>
      <div
        // color="primary"
        // outline
        className="btn-lg mb-4 font-size-16 btn-block btn-soft-primary bg-light text-dark border-info border-left d-flex flex-fill text-center w3-hover-zoom"
        style={{ cursor: "default" }}
      >
        <div>
          <i className="mdi mdi-check mr-1" />
        </div>{" "}
        <div>{props.text}</div>
      </div>
    </Col>
  )
}

export default InfoCard
