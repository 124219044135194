// @flow
import {
  CHANGE_SEARCH_TERM,
  CHANGE_SOC_CODE,
  CHANGE_SOC_DATA,
} from "./actionTypes"

import jsonFormatted from "../../pages/Dashboard-soc-search/jsonFormatted.json"

const getSocData = soc => {
  let res = [ ...jsonFormatted ]
  res = res.filter(data => {
    return data.soc === soc
  })
  return res[0]
}

const INIT_STATE = {
  searchTerm: "",
  soc: "",
  socData: getSocData(),
}

const Search = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      }

    case CHANGE_SOC_CODE:
      return {
        ...state,
        soc: action.payload,
        socData: getSocData(action.payload)
      }

    case CHANGE_SOC_DATA:
      return {
        ...state,
        socData: getSocData(action.payload),
      }

    default:
      return state
  }
}

export default Search
