import React, { useState, useEffect } from "react"
import {
  Collapse,
  Card,
  CardBody,
  CardHeader,
  Button,
  Tooltip,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { showAssessmentBar } from "../../store/actions"

const AssessmentBar = props => {
  const [col1, setcol1] = useState(props.openOnDefault)
  const [tleft, settleft] = useState(false)

  const {
    socForm: {
      hasEnglishB1,
      employerisARated,
      employerWillGetLicence,
    },
    assessmentBar,
    showAssessmentBar
  } = props

  const passedAssessment = () => {
    return (
      hasEnglishB1 === true &&
      (employerisARated === true || employerWillGetLicence === true)
    )
  }

  useEffect(() => {
    if (tleft === false) return
    setTimeout(() => {
      settleft(false)
    }, 2000)
  }, [tleft])

  useEffect(() => {
    //Prevent assessment bar locking if user resets form data while downloads are open
    if (
      props.number === 3 &&
      props.socFormStatus === "basic assessment complete"
    )
      setcol1(false)
    return () => {
      setcol1(props.openOnDefault)
    }
  }, [props.socFormStatus])


  const handleClick = () => {
    if (assessmentBar === props.number) showAssessmentBar(null)
    props.status !== "pending" && setcol1(!col1)
  }

  const NumberCircle = () => {
    if (props.number === 2 && passedAssessment()) {
      return (
        <div
          className="social-list-item bg-success text-white border-success mr-2"
          style={{ minWidth: "32px" }}
        >
          {props.number}
        </div>
      )

    }
    if (props.number === 2 && !passedAssessment() && props.status === "done") {
      return (
        <div
          className="social-list-item bg-success text-white border-success mr-2"
          style={{ minWidth: "32px" }}
        >
          {props.number}
        </div>
      )
    }

    if (props.number === 3 && props.socFormStatus === "full points assessment complete" && passedAssessment())
      return (
        <div
          className="social-list-item bg-success text-white border-success mr-2"
          style={{ minWidth: "32px" }}
        >
          {props.number}
        </div>
      )

    if (props.number === 3 && props.socFormStatus === "full points assessment complete" && !passedAssessment())
      return (
        <div
          className="social-list-item bg-light text-secondary border-secondary mr-2"
          style={{ minWidth: "32px" }}
        >
          {props.number}
        </div>
      )

    if (props.status === "done")
      return (
        <div
          className="social-list-item bg-success text-white border-success mr-2"
          style={{ minWidth: "32px" }}
        >
          {props.number}
        </div>
      )

    if (props.status !== "done")
      return (
        <div
          className="social-list-item bg-light text-secondary border-secondary mr-2"
          style={{ minWidth: "32px" }}
        >
          {props.number}
        </div>
      )
  }

  const Description = () => {
    return (
      <div
        style={{ wordWrap: "break-word" }}
        className="ml-2 mr-1 text-muted rounded"
      >
        {props.text}
      </div>
    )
  }

  const StatusBadge = () => {
    if (props.status === "done" && props.number !== 2)
      return (
        <div>
          <div
            className="p-1 rounded bg-soft-success text-success text-center font-size-10 ml-1 mr-1"
            style={{ whiteSpace: "nowrap" }}
          >
            {"Complete"}
          </div>
        </div>
      )

    if (props.status === "done" && props.number === 2 && passedAssessment())
      return (
        <div className='d-flex flex-wrap flex-center'>
          <div
            className="p-1 rounded bg-soft-success text-success text-center font-size-10 ml-1"
            style={{ whiteSpace: "nowrap" }}
          >
            {"Complete"} 
          </div>
          <div
            className="p-1 rounded bg-white text-success text-center font-size-10 ml-1"
            style={{ whiteSpace: "nowrap" }}
          >
            {"Eligible"}
          </div>
        </div>
      )

    if (props.status === "done" && props.number === 2 && !passedAssessment())
      return (
        <div className='d-flex'>
          <div
            className="p-1 rounded bg-soft-success text-success text-center font-size-10 ml-1"
            style={{ whiteSpace: "nowrap" }}
          >
            {"Complete"}
            </div>
            <div
            className="p-1 rounded bg-soft-danger text-danger text-center font-size-10 ml-1"
            style={{ whiteSpace: "nowrap" }}
          >
            {"Ineligible"}
          </div>
        </div>
      )

    if (props.status === "todo" && !col1)
      return (
        <div>
          <div
            className="p-1 rounded bg-soft-primary text-primary text-center font-size-10 ml-1 mr-2"
            style={{ whiteSpace: "nowrap" }}
          >
            {props.number !== 3 ? "Ready to Start" : "Ready"}
            {/* <i className="bx bx-stopwatch align-top font-size-16 ml-1" /> */}
          </div>
        </div>
      )
  }

  const RightSideButtonClosed = () => {
    if (props.status === "done")
      return (
        <span className="btn btn-light waves-effect waves-light btn-sm shadow-md">
          <i className="mdi mdi-chevron-down"></i>
        </span>
      )

    if (props.status === "todo")
      return (
        <Button
          color="primary"
          className="btn btn-primary waves-effect waves-light shadow btn-lg font-size-14 mt-0 mb-0"
        >
          {props.number === 3 ? "Generate" : "Complete Now"}
          <i className="bx bx-chevrons-down font-size-18 align-middle" />
        </Button>
      )

    if (props.status === "pending")
      return (
        <div>
          <Tooltip
            placement="left"
            isOpen={tleft}
            target="TooltipLeft"
            toggle={() => {
              if (!tleft) settleft(true)
            }}
            trigger="click"
          >
            {/* Please complete the full points assessment first */}
            This feature is coming soon
          </Tooltip>
          <Button
            color="secondary"
            className="btn btn-primary waves-effect waves-light shadow btn-lg font-size-14 ml-1"
            style={{
              whiteSpace: "nowrap",
              background: "rgb(162,165,180)",
              border: "rgb(162,165,180)",
            }}
            id="TooltipLeft"
          >
            {props.number === 3 ? "Generate" : "Complete Now"}
            <i className="bx bx-chevrons-down font-size-18 align-middle" />
          </Button>
        </div>
      )
  }

  const RightSideButtonOpen = () => {
    if (props.status === "done")
      return (
        <span className="btn btn-light waves-effect waves-light btn-sm shadow-md">
          <i className="mdi mdi-chevron-up"></i>
        </span>
      )

    if (props.status === "todo")
      return (
        <>
          <span className="btn btn-light waves-effect waves-light btn-sm shadow-md">
            <i className="mdi mdi-chevron-up"></i>
          </span>
        </>
      )

    if (props.status === "pending")
      return (
        <>
          <span className="btn btn-light waves-effect waves-light btn-sm shadow-md">
            View Less <i className="mdi mdi-chevron-up"></i>
          </span>
        </>
      )
  }

  return (
    <Card className="mb-4 shadow-none border border-light p-0 m-0">
      <CardHeader
        className={`border-bottom border-light bg-light ${
          col1 === false ? "rounded" : "rounded-top"
        } p-1`}
        style={{ minHeight: props.status === "todo" && "64px" }}
      >
        <h6 className="m-2 font-14">
          <span
            onClick={handleClick}
            style={{ cursor: "pointer" }}
            className="text-muted d-flex justify-content-between align-items-center"
          >
            <div className="d-flex align-items-center">
              {NumberCircle()}
              {Description()}
              {StatusBadge()}
            </div>
            <span style={{ whiteSpace: "nowrap" }}>
              {col1 ? RightSideButtonOpen() : RightSideButtonClosed()}
            </span>
          </span>
        </h6>
      </CardHeader>
      <Collapse isOpen={(assessmentBar === props.number || col1)}>
        <Card className="mb-0 shadow-none bg-transparent ">
          <CardBody className="rounded bg-transparent">
            {React.cloneElement(props.children, {
              setcol1: setcol1,
              isOpen: (assessmentBar === props.number || col1),
            })}
          </CardBody>
        </Card>
      </Collapse>
    </Card>
  )
}

const mapStatetoProps = state => {
  const { assessmentBar } = state.Layout
  return {
    ...state.Forms.present,
    assessmentBar
  }
}

export default connect(mapStatetoProps, { showAssessmentBar })(withRouter(AssessmentBar))

