import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { resetFormData } from "../../store/actions"
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  Button,
  Media,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import classnames from "classnames"

import InfoCard from "./InfoCard"

const AboutUs = props => {
  useEffect(() => {
    // props.resetFormData()
  }, [])

  const [customActiveTab, setcustomActiveTab] = useState("1")

  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  return (
    <div className="page-content">
      <Container>
        <Breadcrumbs title={"Home"} breadcrumbItem={"Services"} />

        <Row>
          <Col xl="12">
            <Card>
              {/* <CardHeader className="text-center">
                <h6>Our services</h6>
              </CardHeader> */}
              <CardBody>
                <Nav tabs className="nav-tabs-custom text-center">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={
                        classnames({
                          active: customActiveTab === "1",
                        }) + " font-size-16 "
                      }
                      onClick={() => {
                        toggleCustom("1")
                      }}
                    >
                      <span className="d-none d-sm-block">For employers</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={
                        classnames({
                          active: customActiveTab === "2",
                        }) + " font-size-16"
                      }
                      onClick={() => {
                        toggleCustom("2")
                      }}
                    >
                      <span className="d-none d-sm-block">For applicants</span>
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={customActiveTab}>
                  <TabPane tabId="1" className="p-0 mb-0">
                    <CardBody>
                      <p className="mb-4 mt-1 text-muted text-center">
                        Without needing to read hundreds of pages of rules and
                        guidance, we can help you…
                      </p>
                      <Row>
                        <InfoCard
                          text={
                            "Find out whether a role qualifies for a Skilled Worker visa"
                          }
                        />
                        <InfoCard
                          text={"Determine the minimum salary for the role"}
                        />
                        <InfoCard
                          text={
                            "Establish whether a current or prospective employee is eligible for a Skilled Worker visa"
                          }
                        />
                        <InfoCard
                          text={"Make an application for a Sponsor Licence"}
                        />
                        <InfoCard
                          text={
                            "Make an application to sponsor a migrant worker"
                          }
                        />
                        <InfoCard
                          text={
                            "Support your employee through the process of applying for a visa"
                          }
                        />
                      </Row>
                    </CardBody>
                  </TabPane>
                  <TabPane tabId="2" className="p-0 mb-0">
                    <CardBody>
                      <p className="mb-4 mt-1 text-muted text-center">
                        Without needing to read hundreds of pages of rules and
                        guidance, we can help you…
                      </p>
                      <Row>
                        <InfoCard
                          text={
                            "Explain the process and costs of sponsorship to your employer or prospective employer in an easy to understand way"
                          }
                        />
                        <InfoCard
                          text={
                            "Validate that the role is eligible for sponsorship and that you meet the requirements"
                          }
                        />
                        <InfoCard
                          text={
                            "Support the employer with an application for a Sponsor Licence"
                          }
                        />
                        <InfoCard
                          text={
                            "Make it easier for your employer to assign a Certificate of Sponsorship"
                          }
                        />
                        <InfoCard
                          text={
                            "Work your way through the process of applying for a visa"
                          }
                        />
                      </Row>
                    </CardBody>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default connect(null, { resetFormData })(withTranslation()(AboutUs))
