import React, { useEffect } from "react"
import { connect } from "react-redux"
import { resetFormData } from "../../store/actions"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

// Pages Components
import BigSearchCard from "./BigSearchCardNew"
import SOCTableResponsive from "./SOCTableResponsive"

//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const DashboardVisa = props => {
  useEffect(() => {
    props.resetFormData()
  }, [])

  return (
    <div className="page-content">
      <Container>
        <Breadcrumbs
          title={"Home"}
          breadcrumbItem={"Search occupation codes"}
        />

        <Row>
          <Col xl="12">
            <Card>
              <CardBody className='text-info w3-animate-right'>
              <i className="bx bx-info-circle font-size-14 mr-1" />As a first step, we'll help you find an appropriate occupation
                code for your job. Try searching for your job title or area of
                work
              </CardBody>
            </Card>
            <BigSearchCard />

            <SOCTableResponsive />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default connect(null, { resetFormData })(
  withTranslation()(DashboardVisa)
)
