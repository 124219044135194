import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap"
import { changeFormData, resetFormData } from "../../store/actions"
import Login from "./Login"
import Register from "./Register"

const DashBoardSocSummary = props => {
  const [authType, setAuthType] = useState("register")

  useEffect(() => {
    if (authType === "login") return
    if (localStorage.getItem("authUser")) {
      setAuthType("logged-in")
    }
  }, [props.user])

  return (
    <div className="page-content">
      <Container>
        <Breadcrumbs
          title={"Home"}
          breadcrumbItem={"Basic Assessment complete"}
        />
        <Row>
          <Col>
            <Card className="w3-animate-right">
              <CardBody className="text-info">
                <i className="bx bx-check font-size-18 align-top mb-0"></i>{" "}
                Thanks for confirming{" "}
                {props.socForm && props.socForm.minSalary
                  ? `the job will pay at least a minimum salary of ${props.socForm.minSalary}`
                  : "the minimum salary for this job"}
                . You have now completed your basic assessment.
                {authType === "register" && (
                  <>
                    <p className="mb-0 mt-3 text-info text-center">
                      Create a free What Visa? account to:
                    </p>
                    <ul>
                      <li>View a summary of your assessment</li>
                      <li>
                        Complete a full points assessment to verify that the
                        applicant meets the fundamental requirements to apply
                        for a Skilled Worker visa
                      </li>
                      <li>
                        Download reports explaining eligibility under the
                        Skilled Worker immigration route and the next steps to
                        apply for a visa
                      </li>
                    </ul>
                  </>
                )}
              </CardBody>
            </Card>

            <div className="d-flex justify-content-center">
              {authType === "register" && (
                <Register setAuthType={setAuthType} />
              )}

              {authType === "login" && <Login setAuthType={setAuthType} />}

              {authType === "logged-in" && (
                <Card
                  className="w3-animate-right"
                  style={{ maxWidth: "500px" }}
                >
                  <CardBody>
                    <p className="text-secondary mb-4">
                      Welcome back. You're currently logged in as{" "}
                      <strong>
                        {JSON.parse(localStorage.getItem("authUser")).email}
                      </strong>
                      . If this is correct, click the button below to continue
                      to My Assessments.
                    </p>
                    <Link to="dashboard-soc-assessments">
                      <Button
                        color="primary"
                        className="waves-effect waves-light btn-block shadow btn-lg"
                      >
                        Continue to My Assessments
                        <i className="bx bx-chevrons-right ml-1 align-middle font-size-24"></i>
                      </Button>
                    </Link>

                    <p className="text-center mt-3">
                      <i className="bx bx-log-in mr-1"></i> Wrong Account?{" "}
                      <Link to="#" onClick={() => setAuthType("login")}>
                        Log in as a different user
                      </Link>
                    </p>
                  </CardBody>
                  <CardFooter className="bg-transparent border-top text-left">
                    <Button
                      color="light"
                      className="waves-effect waves-light mr-2 btn-sm text-primary"
                      outline
                      onClick={() => props.history.goBack()}
                    >
                      <i className="bx bx-undo align-middle font-size-18"></i>{" "}
                      Previous
                    </Button>
                  </CardFooter>
                </Card>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return {
    ...state.Forms.present,
    ...state.Search,
    ...state.Account,
    error,
    success,
  }
}

export default connect(mapStatetoProps, {
  changeFormData,
  resetFormData,
})(withRouter(DashBoardSocSummary))
