const getMinSalary = props => {
  // turn off logs in this function
  let clo,
    turnOffLogs = true
  if (turnOffLogs) {
    clo = console.log
    console.log = () => {}
  }

  let defaultMinimumSalary,
    defaultMinimumSalaryNoSol,
    defaultMinimumSalaryPhd,
    defaultMinimumSalaryStem,
    defaultMinimumSalarySol,
    defaultMinimumSalaryNewEntrant,
    goingRateCalculated

  const {
    socData,
    socData: {
      soc,
      socTitle,
      getsGoingRateFromPayScale,
      goingRates,
      isPhdeligible,
      isPostDoceligible,
      isEducationCode,
      solRolesUK,
      solRolesScotland,
    },
    socForm,
    socForm: {
      isSolJob,
      jobLocation,
      rateBand,
      jobHoursPerWeek,
      jobPercentFTE,
    },
  } = props

  if (!socForm || !socData) return "loading salary data..."

  console.log("")
  console.log(">>>>>> Get-Min-Salary:")
  console.log(">>>>>> job:", soc + " " + socTitle)
  console.log("isEducationCode", isEducationCode)
  console.log("has Sol roles:", solRolesUK.length + solRolesScotland.length > 0)
  console.log("getsGoingRateFromPayScale:", getsGoingRateFromPayScale)
  console.log("isPhdeligible:", isPhdeligible)
  console.log("isPostDoceligible:", isPostDoceligible)
  console.log("goingRates", goingRates)
  console.log("Standard hours for this job:", goingRates[0].standardWeeklyHours)

  console.log(">>>>>> answers:")
  console.log("isSolJob:", isSolJob === true)
  console.log("jobHoursPerWeek:", jobHoursPerWeek || "unanswered")
  console.log("jobLocation:", jobLocation)
  console.log("rateBand:", rateBand)
  console.log("jobPercentFTE:", jobPercentFTE || "unanswered")

  console.log(">>>>>> calculations:")

  // Get relevant goingRate based on indicated jobLocation
  let goingRate = null
  if (!getsGoingRateFromPayScale) goingRate = goingRates[0].nationalRate
  if (getsGoingRateFromPayScale && rateBand && jobLocation) {
    goingRate = [...goingRates].filter(rate => rate.rateBand === rateBand)[0][
      jobLocation
    ]
  }
  console.log("goingRate for standard hours:", goingRate)

  // Calculate multiple value
  let multiple = null
  if (!isEducationCode)
    multiple = jobHoursPerWeek / goingRates[0].standardWeeklyHours
  if (isEducationCode) multiple = jobPercentFTE / 100
  console.log("multiple:", multiple)

  // Calculate threshholdMultiple value
  let threshholdMultiple = Math.max(1, jobHoursPerWeek / 48)
  console.log("threshholdMultiple:", threshholdMultiple)

  // Calculate baseline default minimum salaries for this job:
  // ...if job is SOL role
  if (isSolJob && !getsGoingRateFromPayScale) {
    console.log("Summary: job is SOL role and is not a pay scale code")
    defaultMinimumSalary = Math.max(
      20480 * threshholdMultiple,
      goingRate * multiple * 0.8
    )
    defaultMinimumSalaryNoSol = defaultMinimumSalary
    goingRateCalculated = goingRate * multiple * 0.8
  }

  // ...if job is non-SOL and has bands/rates for different locations
  if (
    // !isSolJob &&
    getsGoingRateFromPayScale
  ) {
    console.log("Summary: job has bands/rates for different locations!")
    defaultMinimumSalary = Math.max(
      20480 * threshholdMultiple,
      goingRate * multiple
    )
    goingRateCalculated = goingRate * multiple
  }

  // ...if job is non-SOL and only has 1 national rate
  if (!isSolJob && !getsGoingRateFromPayScale) {
    console.log("Summary: job is non-SOL and has only has 1 national rate!")
    defaultMinimumSalary = Math.max(
      25600 * threshholdMultiple,
      goingRate * multiple
    )
    goingRateCalculated = goingRate * multiple
  }
   let generalSalaryAdjusted = 25600 * threshholdMultiple

  // Calculate default minimum salaries for special circumstances:
  // no sol reduction
  if (isSolJob) {
    defaultMinimumSalaryNoSol = Math.max(
      20480 * threshholdMultiple,
      goingRate * multiple
    )
  } else defaultMinimumSalaryNoSol = defaultMinimumSalary

  // sol
  defaultMinimumSalarySol = Math.max(
    20480 * threshholdMultiple,
    goingRate * multiple * 0.8
  )
  let generalSalaryAdjustedSol = 20480 * threshholdMultiple
  let goingRateCalculatedSol = goingRate * multiple * 0.8

  // phd
  defaultMinimumSalaryPhd = Math.max(
    23040 * threshholdMultiple,
    goingRate * multiple * 0.9
  )
  let generalSalaryAdjustedPhd = 23040 * threshholdMultiple
  let goingRateCalculatedPhd = goingRate * multiple * 0.9

  // stem
  defaultMinimumSalaryStem = Math.max(
    20480 * threshholdMultiple,
    goingRate * multiple * 0.8
  )
  let generalSalaryAdjustedStem = 20480 * threshholdMultiple
  let goingRateCalculatedStem = goingRate * multiple * 0.8

  // new entrant
  defaultMinimumSalaryNewEntrant = Math.max(
    20480 * threshholdMultiple,
    goingRate * multiple * 0.7
  )
  let generalSalaryAdjustedNewEntrant = 20480 * threshholdMultiple
  let goingRateCalculatedNewEntrant = goingRate * multiple * 0.7

  const applyFormatting = value => {
    // round up to 2 decimal places
    let res = Math.ceil(value * 100) / 100
    // add comma and 2 decimal places
    if (res % 1 !== 0) {
      res = res.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    }
    if (res % 1 === 0) {
      res = res.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    }
    return res
  }

  defaultMinimumSalary = applyFormatting(defaultMinimumSalary)
  defaultMinimumSalaryNoSol = applyFormatting(defaultMinimumSalaryNoSol)
  defaultMinimumSalarySol = applyFormatting(defaultMinimumSalarySol)
  defaultMinimumSalaryPhd = applyFormatting(defaultMinimumSalaryPhd)
  defaultMinimumSalaryStem = applyFormatting(defaultMinimumSalaryStem)
  defaultMinimumSalaryNewEntrant = applyFormatting(
    defaultMinimumSalaryNewEntrant
  )
  goingRate = applyFormatting(goingRate)
  goingRateCalculated = applyFormatting(goingRateCalculated)
  goingRateCalculatedNewEntrant = applyFormatting(goingRateCalculatedNewEntrant)
  goingRateCalculatedPhd = applyFormatting(goingRateCalculatedPhd)
  goingRateCalculatedStem = applyFormatting(goingRateCalculatedStem)
  goingRateCalculatedSol = applyFormatting(goingRateCalculatedSol)
  generalSalaryAdjusted = applyFormatting(generalSalaryAdjusted)
  generalSalaryAdjustedSol = applyFormatting(generalSalaryAdjustedSol)
  generalSalaryAdjustedPhd = applyFormatting(generalSalaryAdjustedPhd)
  generalSalaryAdjustedStem = applyFormatting(generalSalaryAdjustedStem)
  generalSalaryAdjustedNewEntrant = applyFormatting(generalSalaryAdjustedNewEntrant)


  console.log(">>>>>> minimum salaries:")
  console.log("defaultMinimumSalary:", defaultMinimumSalary)
  console.log("defaultMinimumSalaryNoSol:", defaultMinimumSalaryNoSol)
  console.log("defaultMinimumSalarySol:", defaultMinimumSalarySol)
  console.log("defaultMinimumSalaryPhd:", defaultMinimumSalaryPhd)
  console.log("defaultMinimumSalaryStem:", defaultMinimumSalaryStem)
  console.log("defaultMinimumSalaryNewEntrant:", defaultMinimumSalaryNewEntrant)
  console.log("goingRateCalculated:", goingRateCalculated)
  console.log("goingRateCalculatedNewEntrant:", goingRateCalculatedNewEntrant)
  console.log("goingRateCalculatedPhd:", goingRateCalculatedPhd)
  console.log("goingRateCalculatedStem:", goingRateCalculatedStem)
  console.log("goingRateCalculatedSol:", goingRateCalculatedSol)

  if (clo) {
    console.log = clo
  }

  return {
    default: `£${defaultMinimumSalary}`,
    noSol: `£${defaultMinimumSalaryNoSol}`,
    sol: `£${defaultMinimumSalarySol}`,
    phd: `£${defaultMinimumSalaryPhd}`,
    stem: `£${defaultMinimumSalaryStem}`,
    newEntrant: `£${defaultMinimumSalaryNewEntrant}`,
    goingRate: `£${goingRate}`,
    goingRateCalculated: `£${goingRateCalculated}`,
    goingRateCalculatedNewEntrant: `£${goingRateCalculatedNewEntrant}`,
    goingRateCalculatedPhd: `£${goingRateCalculatedPhd}`,
    goingRateCalculatedStem: `£${goingRateCalculatedStem}`,
    goingRateCalculatedSol: `£${goingRateCalculatedSol}`,
    generalSalaryAdjusted: `£${  generalSalaryAdjusted}`,
    generalSalaryAdjustedSol: `£${  generalSalaryAdjustedSol}`,
    generalSalaryAdjustedPhd: `£${  generalSalaryAdjustedPhd}`,
    generalSalaryAdjustedStem: `£${  generalSalaryAdjustedStem}`,
    generalSalaryAdjustedNewEntrant: `£${  generalSalaryAdjustedNewEntrant}`,
    multiple: applyFormatting(multiple),
    threshholdMultiple: applyFormatting(threshholdMultiple),
  }
}

export default getMinSalary
