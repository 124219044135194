import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import {
  changeFormData,
  resetFormData,
  showAssessmentBar,
} from "../../store/actions"
import { Button, Table, Badge, Modal } from "reactstrap"
import QuestionNationality from "./question-nationality"
import YesOrNoQuestion from "../Dashboard-soc-questions/question-yes-no"
import QuestionEnglish from "./question-english"
import "../Dashboard-soc-questions/styles.css"
import getMinSalary from "../../helpers/get-min-salary"
import { englishTeachingCountries } from "../../common/data/index"
import {
  getSalaryBoxText,
  getOtherBoxText,
  getSalaryBoxPoints,
  getOtherBoxPoints,
} from "../../helpers/get-tradeable"

const FullPointsAssessment = props => {
  const [mandatoryTotalBadge, setMandatoryTotalBadge] = useState(0)
  const [mandatoryTotal, setMandatoryTotal] = useState(0)

  const {
    soc,
    socData: { hasEligibleRoles },
    socForm: {
      isSolJob,
      employerisARated,
      employerWillGetLicence,
      isOver65,
      hasPhysicalMentalCondition,
      gotPastVisaWithB1,
      nationality,
      applicantCountrySpeaksEnglish,
      hasUKDegree,
      hasEnglishGcseEquivalent,
      studiedAtForeignUniInEnglish,
      hasForeignDegree,
      hasOrWillPassLanguageTest,
      hasUKDegreeDocs,
      hasEnglishGcseEquivalentDocs,
      hasForeignDegreeDocs,
      degreeCountry,
    },
    socForm,
    socData,
    socData: { socTitle },
    assessmentBar,
    changeFormData,
    showAssessmentBar,
    isOpen,
  } = props

  const [modal_center, setmodal_center] = useState(false)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const getEnglishResult = () => {
    if (
      // applicant meets language requirements
      isOver65 === true ||
      hasPhysicalMentalCondition === true ||
      gotPastVisaWithB1 === true ||
      applicantCountrySpeaksEnglish === true ||
      hasUKDegreeDocs === true ||
      hasEnglishGcseEquivalentDocs === true ||
      hasForeignDegreeDocs === true ||
      hasOrWillPassLanguageTest === true
    ) {
      return true
    }
    // applicant does not meet language requirements
    if (
      isOver65 === false &&
      hasPhysicalMentalCondition === false &&
      gotPastVisaWithB1 === false &&
      applicantCountrySpeaksEnglish === false &&
      (hasUKDegree === false || hasUKDegreeDocs === false) &&
      (hasEnglishGcseEquivalent === false ||
        hasEnglishGcseEquivalentDocs === false) &&
      (hasForeignDegree === false ||
        hasForeignDegreeDocs === false ||
        studiedAtForeignUniInEnglish === false) &&
      hasOrWillPassLanguageTest === false
    ) {
      return false
    } else {
      return ""
    }
  }

  const hasEnglishB1 = getEnglishResult()

  useEffect(() => {
    //Change status if all questions were answered
    let res = { ...socForm, hasEnglishB1 }
    if (hasEnglishB1 !== "" && res.status === "basic assessment complete") {
      res.status = "full points assessment complete"
      changeFormData(res)
      showAssessmentBar(2)
    }
    return
  }, [hasEnglishB1])

  useEffect(() => {
    if (assessmentBar === 2 && isOpen) {
      props.showAssessmentBar(null)
      props.setcol1(true)
    }
  }, [assessmentBar])

  useEffect(() => {
    let total = 0
    if (employerisARated) total = total + 20
    if (employerWillGetLicence) total = total + 20
    if (employerisARated === true || employerWillGetLicence !== "")
      total = total + 20 //Job at an appropriate skill level points
    if (hasEnglishB1) total = total + 10
    setMandatoryTotal(total)

    if (total === 50)
      total = (
        <>
          <Badge color="light" className="font-size-12">
            50
          </Badge>
          <i className="bx bx-check text-success align-bottom font-size-20" />
        </>
      )
    if (total < 50)
      total = (
        <>
          <Badge color="light" className="font-size-12">
            {total}
          </Badge>
          {hasEnglishB1 !== "" && (
            <i className="bx bx-x text-danger align-bottom font-size-20" />
          )}
        </>
      )
    setMandatoryTotalBadge(total)
    return
  }, [employerisARated, employerWillGetLicence, hasEnglishB1])

  const handleReset = () => {
    let res = { ...props.socForm }
    res.status = "basic assessment complete"
    res.employerisARated = ""
    res.employerWillGetLicence = ""
    res.isOver65 = ""
    res.hasPhysicalMentalCondition = ""
    res.gotPastVisaWithB1 = ""
    res.nationality = ""
    res.applicantCountrySpeaksEnglish = ""
    res.hasUKDegree = ""
    res.hasUKDegreeDocs = ""
    res.hasEnglishGcseEquivalent = ""
    res.hasEnglishGcseEquivalentDocs = ""
    res.studiedAtForeignUniInEnglish = ""
    res.hasForeignDegree = ""
    res.hasForeignDegreeDocs = ""
    res.hasOrWillPassLanguageTest = ""
    res.degreeCountry = ""
    res.showEnglishOptions = true
    res.hasEnglishB1 = ""
    changeFormData(res)
    props.scrollToTitle()
    // showAssessmentBar(2)
  }

  const getNextQuestion = () => {
    if (employerisARated === "")
      return (
        <YesOrNoQuestion
          key={3}
          question={
            <>
              <div className="font-size-24 pb-3">
                Does the employer offering sponsorship have an A-Rated Sponsor
                Licence covering the Skilled Worker route?
              </div>
              <div className="font-size-18 pb-2 pt-2">
                <i className="bx bx-info-circle text-primary align-middle" /> If
                you are unsure, you can check this{" "}
                <a
                  href="https://www.gov.uk/government/publications/register-of-licensed-sponsors-workers"
                  target="_blank"
                >
                  <u>
                    here
                    <i className="bx bx-link-external ml-1 font-size-14 align-middle" />
                  </u>
                </a>
              </div>
            </>
          }
          property={"employerisARated"}
        />
      )

    if (employerisARated === false && employerWillGetLicence === "")
      return (
        <YesOrNoQuestion
          key={2}
          question={
            <>
              <div className="font-size-22 pb-4">
                The employer will need to obtain a Sponsor Licence <br />
                before this application is possible.
              </div>
              <div className="font-size-22 pb-2">
                Do you wish to continue with the assessment on the basis
                <br /> that the employer will obtain a Sponsor Licence?
              </div>
            </>
          }
          property={"employerWillGetLicence"}
        />
      )

    if (hasEnglishB1 !== "") return

    if (isOver65 === "")
      return (
        <YesOrNoQuestion
          key={3}
          question={
            <>
              <div className="font-size-24 pb-3">
                Is the applicant 65 or over?
              </div>
              <div className="font-size-18 pb-2">
                <i className="bx bx-info-circle text-primary align-middle" />{" "}
                Those aged 65 or over are exempt from the English language
                requirement
              </div>
            </>
          }
          property={"isOver65"}
        />
      )

    if (hasPhysicalMentalCondition === "")
      return (
        <YesOrNoQuestion
          key={4}
          question={
            <>
              <div className="font-size-24 pb-3">
                Does the applicant have a physical or mental condition which
                prevents them from meeting the English language requirement?
                <span
                  onClick={() => {
                    tog_center()
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <i className="bx bx-info-circle font-size-18 ml-1 text-info align-middle" />
                </span>
              </div>
            </>
          }
          property={"hasPhysicalMentalCondition"}
        />
      )

    if (gotPastVisaWithB1 === "")
      return (
        <YesOrNoQuestion
          key={5}
          question={
            "Has the applicant demonstrated English Language skills at level B1 or above in a previous visa application for the UK?"
          }
          property={"gotPastVisaWithB1"}
        />
      )

    if (nationality === "") return <QuestionNationality key={6} />

    if (hasEnglishB1 === "") return <QuestionEnglish key={7} />

    return
  }

  return (
    <>
      <p className="mb-3 mt-2 text-info">
        <i className="bx bx-info-circle font-size-14 mr-1" />
        {hasEnglishB1 === "" && "Please answer the questions below. "} A total
        of 70 points is needed to qualify for the Skilled Worker visa
      </p>
      <div className="table-responsive">
        <Table className="table rounded mb-0 bt-0 b-0" hover>
          <thead>
            <tr className="mb-0">
              <td className="d-flex border-top-0 pb-0 pt-0 mt-0 p-0">
                <div className="border-bottom pb-1 mr-2 flex-fill align-self-end">
                  <strong>Mandatory points</strong> - 50 points required
                </div>
                <div className="border rounded p-1 pl-2 pr-2">
                  Points gained: {mandatoryTotalBadge}
                  {employerWillGetLicence && " (incl. 20 conditional)"}
                </div>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="border-top-0 pl-0 pr-0">
                <h6 className="mb-2 mt-2">
                  Offer of a job by an approved sponsor:
                </h6>

                {employerisARated === false && employerWillGetLicence === "" && (
                  <div className="w3-animate-top">
                    <p className="mb-2 text-muted">
                      You have told us that the employer offering sponsorship{" "}
                      <strong>does not</strong> have an A-Rated Sponsor Licence
                      covering the Skilled Worker route
                    </p>
                    <p>
                      <Badge className="badge-soft-danger ">0 points</Badge>
                    </p>
                  </div>
                )}

                {employerisARated === true && (
                  <div className="w3-animate-top">
                    <p className="mb-2 text-muted">
                      You have told us that the employer offering sponsorship
                      has an A-Rated Sponsor Licence covering the Skilled Worker
                      route
                    </p>
                    <p>
                      <Badge className="badge-soft-primary ">+ 20 points</Badge>
                    </p>
                  </div>
                )}

                {employerWillGetLicence === true && (
                  <div className="w3-animate-top">
                    <p className="mb-2 text-muted">
                      You have told us that the employer offering sponsorship{" "}
                      <strong>does not</strong> have an A-Rated Sponsor Licence
                      covering the Skilled Worker route
                    </p>
                    <p className="mb-2 text-muted">
                      You have answered that you wish to continue with the
                      assessment. You are eligible for 20 points, conditional on
                      the employer obtaining a Sponsor Licence before this
                      application for sponsorship is submitted.
                    </p>
                    <p>
                      <Badge className="badge-soft-primary ">
                        + 20 points (conditional)
                      </Badge>
                    </p>
                  </div>
                )}

                {employerWillGetLicence === false && (
                  <div className="w3-animate-top">
                    <p className="mb-2 text-muted">
                      You have told us that the employer offering sponsorship{" "}
                      <strong>does not</strong> have an A-Rated Sponsor Licence
                      covering the Skilled Worker route
                    </p>
                    <p className="mb-2 text-muted">
                      You have answered that you <strong>do not</strong> wish to
                      continue with the assessment on the basis that the
                      employer will obtain a Sponsor Licence before this
                      application for sponsorship is submitted.
                    </p>
                    <p>
                      <Badge className="badge-soft-danger ">0 points</Badge>
                    </p>
                  </div>
                )}

                {(employerWillGetLicence === "" ||
                  employerWillGetLicence === "") && (
                  <div className="pb-1 mt-1 w3-animate-top"> </div>
                )}
              </td>
            </tr>

            {(employerisARated === true || employerWillGetLicence !== "") && (
              <tr>
                <td className="border-top-0  pl-0 pr-0 w3-animate-top">
                  <h6>Job at an appropriate skill level:</h6>
                  <div>
                    <p className="mb-2 text-muted">
                      You have told us that {soc} - {socTitle} is the most
                      appropriate occupation code for the job
                      {
                        // Extra text to use if SOC code is only eligible if role is on SOL
                        hasEligibleRoles === "solOnly" &&
                          isSolJob &&
                          ` and that the job is on the Shortage Occupation List`
                      }
                      . The job is therefore at an appropriate skill level.
                    </p>
                    <p>
                      <Badge className="badge-soft-primary ">+ 20 points</Badge>
                    </p>
                  </div>
                </td>
              </tr>
            )}

            {(employerWillGetLicence !== "" || employerisARated === true) && (
              <tr>
                <td className="border-top-0  pl-0 pr-0 w3-animate-top">
                  <h6>English language skills at level B1 (intermediate):</h6>

                  {hasEnglishB1 !== "" && hasEnglishB1 && (
                    <div className="w3-animate-top">
                      <p className="mb-2 text-muted">
                        {isOver65 &&
                          `You have told us that the applicant is aged 65 or over, and so is exempt from this requirement
                        `}
                        {hasPhysicalMentalCondition &&
                          `You have told us that the applicant has a physical or mental condition which prevents them from meeting this requirement, and so is exempt`}
                        {gotPastVisaWithB1 &&
                          `You have told us that the applicant has demonstrated English Language skills at level B1 or above in a previous successful visa application for the UK, and so meets this requirement automatically
                        `}
                        {applicantCountrySpeaksEnglish &&
                          `You have told us that the applicant is a national of a majority English language speaking country
                        `}
                        {hasUKDegreeDocs &&
                          `You have told us that the applicant has an eligible degree awarded in the UK
                        `}
                        {hasEnglishGcseEquivalentDocs &&
                          `You have told us that the applicant has an eligible qualification awarded in the UK
                        `}
                        {hasForeignDegreeDocs &&
                          englishTeachingCountries.includes(degreeCountry) &&
                          `You have told us that the applicant has an eligible degree or degree level qualification that was taught in a majority English language speaking country`}
                        {hasForeignDegreeDocs &&
                          !englishTeachingCountries.includes(degreeCountry) &&
                          `You have told us that the applicant has an eligible degree or degree level qualification that was taught in
                          English`}
                        {hasOrWillPassLanguageTest &&
                          `You have told us that the applicant has passed or will pass an approved English Language test
                        `}
                      </p>
                      <p>
                        <Badge className="badge-soft-primary ">
                          + 10 points
                        </Badge>
                      </p>
                    </div>
                  )}

                  {hasEnglishB1 === false && (
                    <div className="w3-animate-top">
                      <p className="mb-2 text-muted">
                        You have told us that the applicant is unable to meet
                        this requirement via one of the specified methods
                      </p>
                      <p>
                        <Badge className="badge-soft-danger ">0 points</Badge>
                      </p>
                    </div>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {hasEnglishB1 === "" && <div>{getNextQuestion()}</div>}

      <div className="table-responsive">
        <Table className="table rounded mb-0 bt-0 b-0" hover>
          {hasEnglishB1 !== "" && (
            <thead>
              <tr className="mb-0 table-responsive">
                <td className="d-flex border-top-0 pb-0  pl-0 pr-0">
                  <div className="border-bottom pb-1 mr-2 flex-fill align-self-end w3-animate-top">
                    <strong>Tradable points</strong> - 20 points required
                  </div>
                  <div className="border rounded p-1 pl-2 pr-2">
                    Points gained:{" "}
                    <Badge color="light" className="font-size-12">
                      20
                    </Badge>
                    <i className="bx bx-check text-success align-bottom font-size-20" />
                  </div>
                </td>
              </tr>
            </thead>
          )}

          <tbody>
            {hasEnglishB1 !== "" && (
              <tr className="w3-animate-top">
                <td className="border-top-0  pl-0 pr-0 w3-animate-top">
                  <h6>Salary:</h6>
                  {hasEnglishB1 !== "" && (
                    <>
                      <div className="mb-2 text-muted">
                        {getSalaryBoxText(socData, socForm)}
                      </div>
                      <p>
                        {getSalaryBoxPoints(socData, socForm) > 0 ? (
                          <Badge className="badge-soft-primary">
                            + {getSalaryBoxPoints(socData, socForm)} points
                          </Badge>
                        ) : (
                          <Badge className="badge-soft-primary">
                            {getSalaryBoxPoints(socData, socForm)} points
                          </Badge>
                        )}
                      </p>
                    </>
                  )}
                </td>
              </tr>
            )}

            {hasEnglishB1 !== "" && getOtherBoxText(socData, socForm) !== null && (
              <tr className="w3-animate-top">
                <td className="border-top-0 pl-0 pr-0">
                  <h6>Other:</h6>
                  {hasEnglishB1 !== "" && (
                    <>
                      <p className="mb-2 text-muted">
                        {getOtherBoxText(socData, socForm)}
                      </p>
                      <p>
                        <Badge className="badge-soft-primary">
                          + {getOtherBoxPoints(socData, socForm)} points
                        </Badge>
                      </p>
                    </>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {
        //Applicant passes points assessment
        hasEnglishB1 === true &&
          (employerisARated === true || employerWillGetLicence === true) && (
            <>
              <div className="pb-0 mr-2 flex-fill align-self-end w3-animate-top mt-2">
                The <strong>total number</strong> of points gained is:
                <p>
                  <Badge color="light" className="font-size-14">
                    {20 + mandatoryTotal}
                  </Badge>
                  <i className="bx bx-check text-success align-bottom font-size-20" />{" "}
                  {employerWillGetLicence && " (incl. 20 conditional)"}
                </p>
              </div>

              <div className="text-success">
                <p>
                  From the information provided, the applicant appears to gain
                  enough points to be eligible to apply for a Skilled Worker
                  visa
                </p>
              </div>
              <div>
                <p>
                  You can now download PDF reports providing further details on
                  your assessment below
                </p>
              </div>
            </>
          )
      }

      {
        //Applicant fails points assessment
        hasEnglishB1 !== "" &&
          (hasEnglishB1 === false || employerWillGetLicence === false) && (
            <>
              <div className="pb-0 mr-2 flex-fill align-self-end w3-animate-top mt-2">
                The <strong>total number</strong> of points gained is:
                <p>
                  <Badge color="light" className="font-size-14">
                    {20 + mandatoryTotal}
                  </Badge>
                  <i className="bx bx-x text-danger align-bottom font-size-20" />{" "}
                  {employerWillGetLicence && " (incl. 20 conditional)"}
                </p>
              </div>
              <div className="text-danger">
                <p>
                  From the information provided, the applicant does not appear
                  to gain enough points to be eligible to apply for a Skilled
                  Worker visa
                </p>
              </div>
            </>
          )
      }

      {hasEnglishB1 !== "" && (
        <>
          <Button
            color="light"
            className="waves-effect waves-light btn-sm"
            onClick={handleReset}
            outline
          >
            <i className="mdi mdi-close align-middle font-size-14"></i> Reset
            Answers
          </Button>
        </>
      )}

      <Modal isOpen={modal_center} toggle={() => tog_center()} centered={true}>
        <div className="modal-header">
          The Home Office publishes the following examples
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>An applicant is exempt is where they: </p>
          <p>
            - are suffering from a long-term or ongoing illness or disability
            (which may last for years) that severely restricts their ability to
            learn English or to take the test
          </p>
          <p>
            {" "}
            - are suffering from a serious or life threatening illness such as
            cancer, which may involve treatment over the course of several
            months that severely restricts their ability to learn English or to
            take the test
          </p>
          <p className="mb-0">
            - have a mental condition which prevents them from speaking or
            learning English to the required standard
          </p>
        </div>
      </Modal>
    </>
  )
}

const mapStatetoProps = state => {
  const { past } = state.Forms
  const { assessmentBar } = state.Layout
  return { ...state.Forms.present, ...state.Search, past, assessmentBar }
}

export default connect(mapStatetoProps, {
  changeFormData,
  resetFormData,
  showAssessmentBar,
})(withRouter(FullPointsAssessment))
