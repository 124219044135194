import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { Card, CardBody, Col, Media, Button } from "reactstrap"
import PDFRenderer from "./PDFRenderer"

const PricingCard = props => {

  return (
    <>
      <Col xl="4" md="6">
        <Card className="border plan-box">
          <CardBody className="p-4">
            <Media>
              <Media body>
                <h5>{props.pricing.title}</h5>
                <p className="text-muted">{props.pricing.description}</p>
              </Media>
              <div className="ml-3">
                <i
                  className={"bx " + props.pricing.icon + " h1 text-primary"}
                />
              </div>
            </Media>
            <div className="py-4">
              <h2>
                <sup>
                  <small>£</small>
                </sup>{" "}
                {props.pricing.price}{" "}
                <span className="font-size-13">{props.pricing.duration}</span>
              </h2>
            </div>
            <div className="text-center plan-btn">
              {props.pricing.id === 1 ? (
                <PDFRenderer
                  key={props.pricing.id}
                  socData={props.socData}
                  socForm={props.socForm}
                  isOpen={props.isOpen}
                />
              ) : <div className="btn btn-light btn-sm waves-effect waves-light w-lg z-depth-1 disabled" style={{ opacity: 1}}>
              Coming soon
            </div>}
            </div>

            <div className="plan-features mt-5">
              {props.pricing.features.map((feature, key) => (
                <p key={"_feature_" + key}>
                  <i className="bx bx-checkbox-square text-primary mr-2" />{" "}
                  {feature.title}
                </p>
              ))}
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

PricingCard.propTypes = {
  pricing: PropTypes.object,
}

const mapStatetoProps = state => {
  return {
    ...state.Forms.present,
    ...state.Search,
  }
}

export default connect(mapStatetoProps)(withRouter(PricingCard))
