import {
	CHANGE_FORM_DATA,
	RESET_FORM_DATA,
} from "./actionTypes";

export const changeFormData = formData => ({
	type: CHANGE_FORM_DATA,
	payload: formData
});

export const resetFormData = formData => ({
	type: RESET_FORM_DATA,
	payload: formData
});

