import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"

// import images
import img1 from "../../assets/images/small/img-6.jpg"

const Tier2isNoMore = () => {
  return (
    <React.Fragment>
      <Row className="pt-5 mt-5">
        <Col lg={12}>
          <Card>
            <CardBody>
              <div className="pt-3 mt-3">
                <div className="row justify-content-center">
                  <div className="col-xl-8">
                    <div>
                      <div className="text-center">
                        <div className="mb-4">
                          <Link
                            to="#"
                            className="badge badge-light font-size-12"
                          >
                            <i className="bx bx-purchase-tag-alt align-middle text-muted mr-1"></i>{" "}
                            Skilled Worker visas
                          </Link>
                        </div>
                        <h4>
                          Tier 2 is no more – what does the new Skilled Worker
                          route means for employers and applicants?
                        </h4>
                        <p className="text-muted mb-4">
                        <i className="bx bx-compass mr-1"></i>From the What Visa<sup>?</sup> blog
                        </p>
                      </div>

                      <hr />
                      <div className="text-center">
                        <Row>
                          <Col sm={4}>
                            <div>
                              <p className="text-muted mb-2">Categories</p>
                              <h4 className="font-size-15">
                                Skilled Worker visas
                              </h4>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="mt-4 mt-sm-0">
                              <p className="text-muted mb-2">Date</p>
                              <h4 className="font-size-15">1 Mar, 2021</h4>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="mt-4 mt-sm-0">
                              <p className="text-muted mb-2">Post by</p>
                              <h4 className="font-size-15">Tim Whittaker</h4>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <hr />

                      <div className="my-5">
                        <img
                          src={img1}
                          alt=""
                          className="img-thumbnail mx-auto d-block"
                          style={{maxWidth: 800}}
                        />
                      </div>

                      <hr />

                      <div className="mt-4">
                        <div className="text-muted font-size-14">
                          <p>
                            On 1 December 2020 the Skilled Worker route replaced
                            Tier 2 of the UK’s old Points-Based System. As part
                            of the Government’s new post-Brexit points-based
                            immigration system, the{" "}
                            <strong>Skilled Worker route</strong> enables UK
                            based employers to sponsor workers from outside the
                            UK to fill skilled roles in the UK. There remain
                            only a few narrow options for international workers
                            to obtain their own visas, and as a result, reliance
                            on the Skilled Worker route is likely to continue
                            for some time.
                          </p>
                          <blockquote className="p-4 border-light border rounded mb-4">
                            <div className="d-flex">
                              <div className="mr-3">
                                <i className="bx bxs-quote-alt-left text-dark font-size-24"></i>
                              </div>
                              <div>
                                <p className="mb-0 font-size-16 text-primary">
                                  {" "}
                                  All this complexity can make the question ‘are
                                  we paying enough to sponsor this person?’ a
                                  real pain to answer. Thankfully, the What
                                  Visa? assessment engine has all of this nuance
                                  built-in, and can give you an answer to this
                                  question in less than 5 minutes...
                                </p>
                              </div>
                            </div>
                          </blockquote>
                          <p>
                            Over the last two years, over 102,000 Tier 2 visa
                            and Skilled Worker visas were granted to applicants
                            outside of the UK. This made up 41% of all work
                            visas granted Jan 2019 – Dec 2020.
                          </p>
                          <p>
                            In this article we highlight the key differences
                            between the old Tier 2 route and the new Skilled
                            Worker route. Both routes rely on employer
                            sponsorship – for more details on the basics of how
                            this works, take a look at our FAQs.{" "}
                          </p>
                          <h4 className="mb-3">
                            EU nationals now need permission to work in the UK
                          </h4>
                          <p>
                            With the exception of those who moved to the UK
                            before 31 December 2020, the vast majority of EU,
                            EEA and Swiss nationals now need permission to work
                            in the UK in exactly the same way that non-EU
                            nationals have required for years. The Skilled
                            Worker route is largely nationality-blind in this
                            respect – a French national is no more eligible for
                            a Skilled Worker route than a US national – both
                            must meet the same set of requirements.
                          </p>
                          <h4 className="mb-3">A wider range of jobs</h4>
                          <p className="mb-3">
                            The skill level threshold has been lowered from RQF
                            6 to RQF 3. In practice, this means that <strong>a much
                            wider range of jobs are now eligible for
                            sponsorship</strong>. Where previously, Tier 2 was mostly
                            limited to managerial and professional roles, the
                            Skilled Worker route is now open to associate
                            professional, administrative and skilled trade
                            occupations. Examples of newly eligible jobs
                            include:</p>
                            <ul className="mt-2">
                              <li>
                                Teaching assistants (305,000 jobs in the UK)
                              </li>
                              <li>
                                Metal workers and maintenance fitters (267,000
                                jobs in the UK)
                              </li>
                              <li>
                                Nursery assistants (162,000 jobs in the UK)
                              </li>
                              <li>Electricians (122,000 jobs in the UK)</li>
                            </ul>
                            <p>At What Visa?, we’ve built a comprehensive search
                            engine that allows employers and applicants to
                            search quickly and easily for the relevant job using
                            job title or key words, showing at a glance whether
                            the role is eligible for sponsorship.
                          </p>
                          <h4 className="mb-3">Lower salary thresholds</h4>
                          <p>
                            The ‘headline’ salary threshold has been lowered
                            from £30,000 to £25,600 per annum – this is the
                            amount you see in Government press releases. The
                            reality is far more nuanced. If you’re under 26 or
                            otherwise qualify as a ‘New Entrant’, you must be
                            paid at least £20,480, or at least 70% of the ‘Going
                            Rate’ for the relevant occupation code, whichever is
                            higher. If you’re not a New Entrant, you must
                            normally be paid £25,600 or 100% of the Going Rate
                            for the occupation code, whichever is higher.
                            Another salary threshold exists for those with
                            relevant PhDs, and yet another for those who have a
                            relevant PhD in a STEM subject. And if you’re
                            recruiting for a public sector job? Well most of
                            these have specified ‘payscale’ salary thresholds.
                          </p>
                          <p>
                            All this complexity can make the question ‘are we
                            paying enough to sponsor this person?’ a real pain
                            to answer. Thankfully, the What Visa? assessment
                            engine has all of this nuance built-in, and can give
                            you an answer to this question in less than five
                            minutes, just by answering a few easy questions
                            about the job and the applicant.
                          </p>
                          <p>
                            In another relevant change, allowances and
                            guaranteed bonuses can no longer be counted towards
                            meeting these salary thresholds, which must be met
                            by the applicant’s basic salary alone.
                          </p>
                          <h4 className="mb-3">
                            A widened definition of ‘New Entrant’
                          </h4>
                          <p>
                            In addition to applicants aged 25 and under, and
                            those switching from a student visa, the definition
                            now includes those working towards recognised
                            professional qualifications or moving directly into
                            postdoctoral positions. This change is important
                            (and positive!) because <strong>New Entrants only need to
                            meet a much lower salary threshold</strong>. In addition, New
                            Entrants can now benefit from the lower salary
                            threshold for up to four years, rather than the
                            three permitted under the old Tier 2 rules.
                          </p>
                          <h4 className="mb-3">Procedural improvements</h4>
                          <p className="mb-4">
                            In addition to the main policy changes outlined
                            above, the Home Office has also made a number of
                            positive procedural changes which streamline the
                            process of sponsoring an applicant as a Skilled
                            Worker:
                            </p>
                                a) The requirement to advertise the job for 28
                                days to show that there are no suitable settled
                                workers (the ‘Resident Labour Market Test’) has
                                been abolished entirely.
                                <br /><br />
                                b) There is no longer a ‘cap’ or annual limit on
                                the number of Tier 2 (General) applications that
                                can be made. The Home Office now distinguishes
                                between ‘Defined’ Certificates of Sponsorship
                                (for applications submitted outside of the UK)
                                and ‘Undefined’ Certificates of Sponsorship (for
                                applications submitted inside the UK). Like
                                Restricted CoS under the old system, employers
                                must still apply for Defined CoS via their
                                Sponsor Management System, but unlike the old
                                system, applications for Defined CoS are
                                normally decided within 24 hours. Undefined CoS,
                                just like the old Unrestricted CoS can be
                                assigned as needed.
                                <br /><br />
                                c) Tier 5 (Youth Mobility Scheme) visa holders
                                can now switch to a Skilled Worker visa from
                                within the UK, rather than needing to return to
                                their home country to submit the application
                                from there.
                                <br /><br />
                            <p>These changes combine to create an immigration route
                            which on the whole, is far improved from the old
                            Tier 2 (General) system, both for employers and
                            applicants. Unfortunately, like the old system, the
                            Skilled Worker route is still complex, particularly
                            for first-time and infrequent users. What Visa?
                            takes the complexity out of the equation by enabling
                            you to quickly and easily assess the viability of an
                            application without having to spend hours trawling
                            through Home Office guidance or pay hundreds or
                            thousands to an immigration lawyer. </p> <p>Try it out for
                            free <Link to="/dashboard-soc-search">here</Link>.
                          </p>
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Tier2isNoMore
