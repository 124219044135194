import React from "react"
import { connect } from "react-redux"
import { changeFormData } from "../../store/actions"
import { Card, CardBody, CardFooter, Button } from "reactstrap"
import { Link } from "react-router-dom"

const SalaryCtaCard = props => {
  if (props.socData.hasEligibleRoles !== "none") {
    return (
      <Card>
        <CardBody>
          <h3 className="text-primary mb-3">
            Is this the most appropriate occupation code?
          </h3>
          <Link to="dashboard-soc-questions">
            <Button
              color="primary"
              className="waves-effect waves-light btn-block shadow btn-lg font-size-14"
            >
              Yes, get a minimum salary for a job in this role
              <i className="bx bx-chevrons-right bx-fade-right-hover ml-1 align-middle font-size-24"></i>
            </Button>
          </Link>
        </CardBody>
        <CardFooter className="bg-transparent border-top">
          <Link to="dashboard-soc-search">
            <Button
              color="secondary"
              className="waves-effect waves-light mr-2"
              outline
            >
              <i className="bx bx-chevrons-left align-middle font-size-20"></i>
              No, choose a different occuption code
            </Button>
          </Link>
        </CardFooter>
      </Card>
    )
  } else {
    return (
      <Card>
        <CardBody>
          <div className="pb-3">
            <h3 className="text-primary mb-3">
              Is this the most appropriate occupation code?
            </h3>
            <p className="text-secondary mb-3">
              <i className="bx bx-info-circle text-primary" /> This occupation
              code has no roles that are eligible for sponsorship through the
              Skilled Worker immigration route. You may wish to review other
              occupation codes to see if there is a more suitable code for your
              job, but please be aware that it is a breach of the Immigration
              Rules to use an inappropriate occupation code just to enable a
              Skilled Worker application.
            </p>
            <div className="text-muted">
              <p className="mb-1">
                <Link to="dashboard-soc-search">
                  <Button
                    color="secondary"
                    className="waves-effect waves-light mr-2"
                    outline
                  >
                    <i className="bx bx-chevrons-left align-middle font-size-20"></i>{" "}
                    No, choose a different occupation code
                  </Button>
                </Link>
              </p>
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }
}

const mapStatetoProps = state => {
  return { ...state.Forms, ...state.Search }
}

export default connect(mapStatetoProps, {
  changeFormData,
})(SalaryCtaCard)
