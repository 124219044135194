import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { resetFormData } from "../../store/actions"
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  Button,
  Media,
} from "reactstrap"
import classnames from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import InfoCard from "./InfoCard"
import BlogGrid from "../Dashboard-visa-blog/BlogGrid/BlogGrid"

const DashboardVisa = props => {
  const [customActiveTab, setcustomActiveTab] = useState("1")

  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  return (
    <>
      <div className="page-content" >
        <Container>
          <Card className="bg-transparent shadow-none text-muted font-size-12 text-right">
            Updated at: Mar-22, 09:22
          </Card>
          <Row>
            <Col xl="12">
              <Card className="pb-0">
                <CardBody style={{zIndex: 1}}>
                  <Col lg="8">
                    <h1 className="text-muted w3-animate-opacity">
                      <i className="bx bxs-quote-left font-size-18 align-top " />
                      Why do visas have to be so
                      <span className="text-success">
                        <strong> complicated?</strong>
                        <i className="bx bxs-quote-right font-size-18 align-top text-secondary" />
                      </span>
                    </h1>
                  </Col>
                  <div className="text-center" style={{marginTop: 120}}>
                    <h5 className="text-muted mb-3 pt-1">
                      After working with the UK’s immigration system for over a
                      decade, we grew tired of hearing that question...
                    </h5>
                  </div>
                  <div className="text-center" style={{paddingBottom: 120}}>
                    <h5 className="text-muted">So we fixed it</h5>
                  </div>
                  <CardFooter className="bg-transparent border-top pb-0">
                    <div className="text-right text-muted w3-animate-right">
                      <h4>
                        What Visa<sup>?</sup> Visas made{" "}
                        <span className="text-primary">simple</span>
                      </h4>
                    </div>
                  </CardFooter>
                </CardBody>
                
              </Card>

              <Card>
                <CardHeader className="text-center">
                  <h6>What we do...</h6>
                </CardHeader>
                <CardBody>
                  <Col>
                    <h5 className="text-muted mb-4 mt-3 text-center">
                      We break down the UK’s system for <strong><span className='font-size-20'>sponsoring migrant
                      workers</span></strong> into bite-size chunks and use the information you
                      provide to help you quickly understand what you need to do to apply
                    </h5>
                  </Col>

                  <Col>
                    <p className="text-muted mb-3">
                      <i className="bx bx-user-circle text-primary mr-2" />
                      You might be an <strong>employer</strong> who hasn’t
                      sponsored anyone before and wants a DIY solution
                    </p>
                    <p className="text-muted mb-3">
                      <i className="bx bx-user-circle text-primary mr-2" />
                      Maybe you’re an <strong>applicant </strong>who wants to
                      support an employer with the process of sponsoring you
                    </p>
                    <p className="text-muted mb-3">
                      <i className="bx bx-user-circle text-primary mr-2" />
                      You could be an <strong>SME or large organisation</strong> who sponsors hundreds of
                      workers a year, but you want to allow your{" "}
                      <strong>HR</strong> and mobility staff to
                      take an early view of whether sponsorship is viable for a
                      given job or individual...
                    </p>
                    <div className="text-left">
                      <p className="text-muted mb-3">
                      <i className="bx bx-comment-check text-primary mr-2 font-size-14" />
                        Our intelligent online service helps you with all of this, so  <strong><span className='font-size-18'>you can avoid the
                        need to spend hours & hours reading through
                        pages & pages of immigration rules and guidance</span></strong>. Best
                        of all, using the core functionality is completely <span className='text-success'>FREE</span> 
                      </p>
                    </div>
                  </Col>
                  <div className="text-center mb-5 mt-5 ">
                    <Link to={"/dashboard-soc-search"}>
                      <Button
                        // outline
                        color="primary"
                        className="btn btn-lg p-3"
                      >
                        Let's get started
                      </Button>
                    </Link>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardHeader className="text-center">
                  <h6>How does it work?</h6>
                </CardHeader>
                <CardBody>
                  <h5 className="mt-3 mb-4 text-center">
                   Just follow these few simple steps (and get a decision in under 60 secs)...
                  </h5>

                  <div>
                    <ul className="verti-timeline list-unstyled">
                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <i className="bx bx-down-arrow-circle" />
                        </div>
                        <Media>
                          <div className="mr-3">
                            <i className="mdi mdi-account-search h2 text-success" />
                          </div>
                          <Media body>
                            <div>
                              <h5 className="font-size-14">
                                Find the correct occupation code for your job
                              </h5>
                              <p className="text-muted">
                                Search for your job title and use the example
                                job titles and tasks to identify the closest SOC
                                code for your job
                              </p>
                            </div>
                          </Media>
                        </Media>
                      </li>
                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <i className="bx bx-down-arrow-circle" />
                        </div>
                        <Media>
                          <div className="mr-3">
                            <i className="mdi mdi-currency-gbp h2 text-warning" />
                          </div>
                          <Media body>
                            <div>
                              <h5 className="font-size-14">
                                Calculate the minimum salary requirement
                              </h5>
                              <p className="text-muted">
                                Tell us the weekly working hours and answer a
                                few simple questions about the job and the
                                applicant and we’ll calculate the relevant
                                minimum salary that complies with the
                                immigration rules and the new Points Based
                                System
                              </p>
                            </div>
                          </Media>
                        </Media>
                      </li>
                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <i className="bx bx-down-arrow-circle" />
                        </div>
                        <Media>
                          <div className="mr-3">
                            <i className="mdi mdi-crosshairs-question h2 text-info" />
                          </div>
                          <Media body>
                            <div>
                              <h5 className="font-size-14">
                                Complete a full points assessment
                              </h5>
                              <p className="text-muted">
                                Answer a few more questions and we’ll generate a
                                full PBS points assessment detailing whether the
                                applicant gains enough points to qualify for a
                                Skilled Worker visa
                              </p>
                            </div>
                          </Media>
                        </Media>
                      </li>

                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle" />
                        </div>
                        <Media>
                          <div className="mr-3">
                            <i className="mdi mdi-file-download-outline h2 text-success" />
                          </div>
                          <Media body>
                            <div>
                              <h5 className="font-size-14">
                                Instantly download a free Assessment Pack
                              </h5>
                              <p className="text-muted pb-4">
                                Get a free basic assessment which explains the
                                process of employer sponsorship for your role
                              </p>
                            </div>
                          </Media>
                        </Media>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="text-center mb-3 mt-3">
                    <Link to={"/dashboard-soc-search"}>
                      <Button
                        outline
                        color="primary"
                        className="btn btn-lg p-3"
                      >
                        Let's get started
                      </Button>
                    </Link>
                  </div> */}
                </CardBody>
              </Card>

              <h6 className="text-center mb-3 pt-2">Our services...</h6>
              <Card>
                <CardBody className="rounded border">
                  <Nav tabs className="nav-tabs-custom text-center">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={
                          classnames({
                            active: customActiveTab === "1",
                          }) + " font-size-16 "
                        }
                        onClick={() => {
                          toggleCustom("1")
                        }}
                      >
                        <span className="d-none d-sm-block w3-hover-zoom text-primary">For employers</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={
                          classnames({
                            active: customActiveTab === "2",
                          }) + " font-size-16"
                        }
                        onClick={() => {
                          toggleCustom("2")
                        }}
                      >
                        <span className="d-none d-sm-block w3-hover-zoom text-success">
                          For applicants
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={customActiveTab}>
                    <TabPane tabId="1" className="p-0 mb-0">
                      <CardBody>
                        <p className="mb-4 mt-1 text-center text-dark font-size-16">
                          Without needing to read hundreds of pages of rules and
                          guidance, we can help you…
                        </p>
                        <Row>
                          <InfoCard
                            text={
                              "Find out whether a role qualifies for a Skilled Worker visa"
                            }
                          />
                          <InfoCard
                            text={"Determine the minimum salary for the role"}
                          />
                          <InfoCard
                            text={
                              "Establish whether a current or prospective employee is eligible for a Skilled Worker visa"
                            }
                          />
                          <InfoCard
                            text={"Make an application for a Sponsor Licence"}
                          />
                          <InfoCard
                            text={
                              "Make an application to sponsor a migrant worker"
                            }
                          />
                          <InfoCard
                            text={
                              "Support your employee through the process of applying for a visa"
                            }
                          />
                        </Row>
                      </CardBody>
                    </TabPane>
                    <TabPane tabId="2" className="p-0 mb-0">
                      <CardBody>

                      <p className="mb-4 mt-1 text-center text-dark font-size-16">
                        Without needing to read hundreds of pages of rules and
                          guidance, we can help you…
                        </p>
                        <Row>
                          <InfoCard
                            text={
                              "Explain the process and costs of sponsorship to your employer or prospective employer in an easy to understand way"
                            }
                          />
                          <InfoCard
                            text={
                              "Validate that the role is eligible for sponsorship and that you meet the requirements"
                            }
                          />
                          <InfoCard
                            text={
                              "Support the employer with an application for a Sponsor Licence"
                            }
                          />
                          <InfoCard
                            text={
                              "Make it easier for your employer to assign a Certificate of Sponsorship"
                            }
                          />
                          <InfoCard
                            text={
                              "Work your way through the process of applying for a visa"
                            }
                          />
                        </Row>
                      </CardBody>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
              <BlogGrid />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default connect(null, { resetFormData })(
  withTranslation()(DashboardVisa)
)
