
import React from "react"
import RegisterInner from '../Dashboard-soc-summary/Register'
import { Container, Col } from "reactstrap";

const Register = props => {

  return (
    <React.Fragment>
      <div className="page-content">
      <Container>
        <Col>
          <div className="d-flex justify-content-center pt-4">
            <RegisterInner />
        </div>
        </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
