import React from "react";
import { connect } from "react-redux";
import { changeFormData, changeSearchTerm } from "../../store/actions";
import { Card, CardBody, CardFooter, Button } from "reactstrap";
import { Link } from "react-router-dom";

const CreateNewAssessmentCard = (props) => {

  const handleClick = () => {
    props.changeSearchTerm("")
  }
    
    return (
          <Link to="dashboard-soc-search" >
            <Button
              color="light"
              className="waves-effect waves-light shadow btn font-size-12 btn-sm m-0 shadow-none text-muted bx-pulse-hover"
              outline
              onClick={handleClick}
            >
              Start new assessment
             <i className="mdi mdi-plus bx-fade-right-hover ml-0 pr-0 align-middle font-size-16"></i>
            </Button>
          </Link>
    )
};

const mapStatetoProps = (state) => {
  return { ...state.Forms, ...state.Search };
};

export default connect(mapStatetoProps, {
  changeFormData,
  changeSearchTerm
})(CreateNewAssessmentCard);
