const getMinSalaryRange = socData => {
  const {
    hasEligibleRoles,
    getsGoingRateFromPayScale,
    goingRates,
    solRolesUKDesc,
    isEducationCode,
    soc,
  } = socData

  if (!socData.soc) return "loading salary range data..."

  if (hasEligibleRoles === "none") {
    return "not applicable"
  } else {
    let res, min, max
    if (
      // national rate codes and no roles are on SOL
      !getsGoingRateFromPayScale &&
      solRolesUKDesc === "none"
    ) {
      min = Math.max(goingRates[0].nationalRate * 0.7, 20480)
      max = Math.max(goingRates[0].nationalRate, 25600)
    }

    if (
      // national rate codes and all roles are on SOL
      !getsGoingRateFromPayScale &&
      solRolesUKDesc === "all"
    ) {
      min = Math.max(goingRates[0].nationalRate * 0.7, 20480)
      max = Math.max(goingRates[0].nationalRate * 0.8, 20480)
    }

    if (
      // national rate codes with individual SOL roles
      !getsGoingRateFromPayScale &&
      solRolesUKDesc === "some"
    ) {
      min = Math.max(goingRates[0].nationalRate * 0.7, 20480)
      max = Math.max(goingRates[0].nationalRate, 25600)
    }

    if (getsGoingRateFromPayScale && isEducationCode && soc !== 2312) {
      // education codes
      min = Math.max(goingRates[0].northernIrelandRate, 20480)
      max =
        goingRates[5] &&
        Math.max(goingRates[goingRates.length - 1].londonInnerRate, 25600)
    }

    if (getsGoingRateFromPayScale && isEducationCode && soc === 2312) {
      // education codes (edge case: 2312 Further education teaching professionals)
      min = Math.max(goingRates[0].nationalRate, 20480)
      max = Math.max(goingRates[goingRates.length - 1].nationalRate, 25600)
    }

    if (getsGoingRateFromPayScale && !isEducationCode) {
      // pay-scale codes
      min = Math.max(
        goingRates[0].northernIrelandRate || goingRates[0].nationalRate,
        20480
      )
      max = Math.max(
        goingRates[goingRates.length -1].scotlandRate ||
          goingRates[goingRates.length -1].nationalRate,
        20480
      )
    }

    min = min / 1000
    max = max / 1000
    min = Math.round(min * 10) / 10
    max = Math.round(max * 10) / 10
    if (min === max) res = <>£{min}k</>
    else
      res = (
        <>
          £{min}k –⁠ £{max}k
        </>
      )
    return res
  }
}

export default getMinSalaryRange
