import React from "react"
import { Container, Row, Col } from "reactstrap"
import { Link } from "react-router-dom"

//Import Components
import FooterLink from "./footer-link"

const Features = () => {
  const footerLinks = [
    {
      title: "Company",
      links: [
        { title: "About Us", link: "/about-us" },
        { title: "Sevices", link: "/services" },
        { title: "Get Started", link: "/dashboard-soc-search" },
        // { title: "News", link: "#" },
        { title: "Terms & Conditions", link: "#" },
      ],
    },
    {
      title: "Resources",
      links: [
        { title: "Blog", link: "/blog" },
        { title: "FAQs", link: "/faqs" },
        { title: "Disclaimer", link: "/disclaimer" },
        // { title: "Token sales", link: "#" },
        { title: "Privacy Policy", link: "#" },
        
      ],
    },
    {
      title: "Links",
      links: [
        { title: "My Assessments", link: "/dashboard-soc-assessments" },
        { title: "Login", link: "/login" },
        { title: "Sign up", link: "/register" },
      ],
    },
  ]

  return (
    <React.Fragment>
      <footer className="landing-footer">
        <Container>
          <Row>
            {footerLinks.map((footerLink, key) => (
              <Col lg="3" sm="6" key={key}>
                <div className="mb-4 mb-lg-0">
                  <h5 className="mb-3 footer-list-title">{footerLink.title}</h5>
                  <ul className="list-unstyled footer-list-menu">
                    {footerLink.links.map((Flink, key) => (
                      <li key={key}>
                        <Link to={Flink.link}>{Flink.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            ))}

            <Col lg="3" sm="6">
              <div className="mb-4 mb-lg-0">
                <h5 className="mb-3 footer-list-title">Latest News</h5>
                <div className="blog-post">
                  <Link to="/blog/how-to-choose-an-occupation-code" className="post">
                    <div className="badge badge-soft-success font-size-11 mb-3">
                      Skilled Worker visas 
                    </div>
                    <h5 className="post-title">How to choose an occupation code when making a Skilled Worker application?</h5>
                    <p className="mb-0">
                      <i className="bx bx-calendar mr-1"/> 23 Feb, 2021
                    </p>
                  </Link>
                  <Link to="/blog/tier-2-is-no-more" className="post">
                    <div className="badge badge-soft-success font-size-11 mb-3">
                      UK visa applications
                    </div>
                    <h5 className="post-title">Tier 2 is no more – what does the new Skilled Worker route means for employers and applicants?</h5>
                    <p className="mb-0">
                      <i className="bx bx-calendar mr-1"/> 1 Mar, 2021
                    </p>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <hr className="footer-border my-5" />

          <FooterLink />
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Features
