import React, { useState, useEffect } from "react"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import {
  changeFormData,
  resetFormData,
  changeSocCode,
} from "../../store/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card, Row, Col, CardBody, Container } from "reactstrap"
import SavedJobsTable from "./SavedJobsTable"
import AssessmentCard from "./AssessmentCard"
import CreateNewAssessmentCard from "./CreateNewAssessmentCard"

import firebase from "firebase/app"

const DashBoardSocAssessments = props => {
  const [loading, setLoading] = useState(false)

  const db = firebase.firestore()

  const {
    socForm,
    socForm: { id, soc, status },
    changeFormData,
    resetFormData,
  } = props

  // Create/save assessment
  useEffect(() => {
    if (status === "") return
    if (firebase.auth().currentUser && !socForm.id) {
      setLoading(!loading)
    } else if (firebase.auth().currentUser && socForm.id) {
      db.collection("users")
        .doc(firebase.auth().currentUser.uid)
        .set(
          {
            assessments: {
              [socForm.id]: socForm,
            },
          },
          { merge: true }
        )
        .then(() => {
            setLoading(!loading)
          }
        )
        .catch(err => {
          console.log("Firestore error:", err)
        })
    }
  }, [socForm])

  const handleDelete = id => {
    if (firebase.auth().currentUser && id) {
      db.collection("users")
        .doc(firebase.auth().currentUser.uid)
        .set(
          {
            assessments: {
              [id]: firebase.firestore.FieldValue.delete(),
            },
          },
          { merge: true }
        )
        .then(() => {
          resetFormData()
          changeSocCode()
        })
        .catch(err => {
          console.log("err", err)
        })
    }
  }

  if (!props.socForm) return <>Loading</>

  return (
    <div className="page-content">
      <Container>
        <Breadcrumbs title={"Home"} breadcrumbItem={"My assessments"} />
        <Row>
          <Col>
          {/* <div id='capture'> */}
            <Card className="w3-animate-right">
              <CardBody className="text-info">
                <i className="bx bx-check font-size-18 align-top"></i> You can
                see your saved assessments below. Select an assessment to review
                progress so far and move forward with the assessment
              </CardBody>
            </Card>
            {/* </div> */}

            <SavedJobsTable
              // assessments={assessments}
              setLoading={!loading}
            />
           <AssessmentCard handleDelete={handleDelete} key={id} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return {
    ...state.Forms.present,
    ...state.Search,
    ...state.Account,
    error,
    success,
  }
}

export default connect(mapStatetoProps, {
  changeFormData,
  resetFormData,
  changeSocCode,
})(withRouter(DashBoardSocAssessments))
