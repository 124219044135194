import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Container, Card, CardBody, Row, Col } from "reactstrap"
import { changeSocCode, resetFormData } from "../../store/actions"
import CardSocTitle from "./card-soc-title"
import RelatedJobTitles from "./related-job-titles"
import JobDescription from "./job-description"
import ExampleTasks from "./example-tasks"
import MinimumSalaryRange from "./min-salary-range"
import ShortageOccupationList from "./shortage-occupation-list"
import SocInfoCta from "./soc-info-cta"
import SocSpecialInfo from "./soc-special-info"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./styles.css"

const DashboardSocInfo = props => {
  useEffect(() => {
    props.resetFormData()
  }, [])

  if (!props.socData) return <p>No occupation code selected</p>

  const {
    soc,
    socData: { solRolesUKDesc },
  } = props

  return (
    <div className="page-content">
      <Container>
        <Breadcrumbs
          title={"Home"}
          breadcrumbItem={"Occupation code details"}
        />

        <Row>
          <Col xl="12">
          <Card>
              <CardBody className='text-info w3-animate-right'>
              <i className="bx bx-info-circle font-size-14 mr-1" />Here are the full details of this code. Use them to check if this is the most appropriate code for your job, then answer the question below
              </CardBody>
            </Card>

            <CardSocTitle soc={soc} />
          </Col>
        </Row>

        <Row>
          <Col sm="12" lg="5">
            <JobDescription soc={soc} />
            {props.socData.hasEligibleRoles !== "none" && (
              <MinimumSalaryRange socData={props.socData} />
            )}
          </Col>
          <Col lg="4">
            <ExampleTasks soc={soc} />
          </Col>
          <Col>
            <RelatedJobTitles />
            
          </Col>
        </Row>

        {solRolesUKDesc !== "none" && (
          <Row>
            <Col>
              <ShortageOccupationList soc={soc} />
            </Col>
          </Row>
        )}

        <SocSpecialInfo soc={soc} />

        <Row>
          <Col>
            <SocInfoCta />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const mapStatetoProps = state => {
  return { ...state.Search }
}

export default connect(mapStatetoProps, {
  changeSocCode,
  resetFormData,
})(DashboardSocInfo)
