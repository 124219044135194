import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, CardFooter, Button } from "reactstrap"
import { changeFormData } from "../../store/actions"
import getMinSalary from "../../helpers/get-min-salary"
import UndoRedo from "./UndoRedo"

const ResultsCard = props => {
  const {
    socForm,
    socForm: {
      phdIsRelevant,
      phdIsStem,
      willApplicantBeUnder26,
      // willApplicantBeSwitching,
      isApplicantBeingQualified,
      isApplicantNewGraduate,
      isApplicantSwitchingFromTier1,
      applicantWillBeGettingQualified,
      applicantWillBeGettingRegistered,
      isApplicantMovingIntoPhd,
    },
    handleNextStep,
  } = props

  const minSalary = getMinSalary(props)

  // const handleNextStep = (salary, newEntrant = false) => {
  //   let res = { ...socForm }
  //   res.minSalary = salary
  //   res.status = 'basic assessment complete'
  //   if (willApplicantBeUnder26 === true) res.isOver65 = false
  //   res.agreedToProceedAsNewEntrant = newEntrant
  //   props.changeFormData(res)
  //   props.history.push("/dashboard-soc-summary");
  // };

  const getCardBody = () => {
    if (phdIsStem === true) {
      if (
        willApplicantBeUnder26 === true ||
        // willApplicantBeSwitching === true ||
        isApplicantBeingQualified === true ||
        isApplicantNewGraduate === true ||
        isApplicantSwitchingFromTier1 === true ||
        applicantWillBeGettingQualified === true ||
        applicantWillBeGettingRegistered === true ||
        isApplicantMovingIntoPhd === true
      )
        return (
          <>
            <p>
              You have confirmed that the applicant has a PhD in a STEM subject
              that is relevant to the role and the applicant is eligible as a
              New Entrant.
            </p>
            <p className="mb-1">
              This means you can choose between 2 minimum salaries:{" "}
            </p>
            {minSalary.stem === minSalary.newEntrant && (
              <p className="mb-1 mt-3">
                Since the minimum salaries are the same in both scenarios, you
                may wish to choose the first option (PhD STEM), as it permits the
                applicant to apply for a visa of up to 5 years, rather than the
                4 years permitted as a New Entrant.
              </p>
            )}
            <h2 className="text-secondary mb-0 mt-2 pt-2 pb-0">
              {minSalary.stem}
            </h2>
            <p className="mb-1">
              If you pay at least this salary you will be able to apply for a
              visa of up to 5 years
            </p>
            <p>
              <Button
                color="primary"
                className="waves-effect waves-light shadow mt-0 btn-lg"
                onClick={() => handleNextStep(minSalary.stem, false)}
              >
                We’ll pay at least {minSalary.stem}
                <i className="bx bx-chevrons-right align-middle ml-1"></i>
              </Button>
            </p>
            <p className="mt-3 mb-1"></p>
            <h2 className="text-secondary mb-0 mt-2 pt-2 pb-0">
              {minSalary.newEntrant}
            </h2>
            {minSalary.stem === minSalary.newEntrant && (
              <p className="mb-1">
                If the employer sponsors the applicant as a New Entrant, you will be
                able to apply for a visa of up to 4 years, assuming the
                applicant hasn’t held a Tier 2 or Skilled Worker visa before.
              </p>
            )}
            {minSalary.stem !== minSalary.newEntrant && (
              <p className="mb-1">
                If you pay at least this salary, but less than the amount above,
                you will only be able to apply for a visa of up to 4 years,
                assuming the applicant hasn’t held a Tier 2 or Skilled Worker
                visa before
              </p>
            )}
            <p>
              <Button
                color="primary"
                className="waves-effect waves-light shadow mt-0 btn-lg"
                onClick={() => handleNextStep(minSalary.newEntrant, true)}
              >
                We’ll pay at least {minSalary.newEntrant}
                <i className="bx bx-chevrons-right align-middle ml-1"></i>
              </Button>
            </p>
            <p className="mb-0 mt-4">
              <i className="bx bx-info-circle text-info" />{" "}
              <strong>
                Whichever option you pick, you’ll need to pay the applicant at
                least this amount as a basic salary
              </strong>{" "}
              – you can give the applicant additional allowances or benefits in
              kind but they cannot be counted towards this minimum salary.
            </p>
          </>
        )
      else
        return (
          <>
            <p>
              You have confirmed that the applicant has a PhD in a STEM subject
              that is relevant to the role.
            </p>
            <p className="mb-1">The minimum salary for this position is: </p>
            <h2 className="text-secondary mb-0 mt-2 pt-2 pb-0">
              {minSalary.stem}
            </h2>
            <p className="mb-1">
              If you will pay at least this amount, click here to proceed to the
              next stage of the process <br />
            </p>
            <p>
              <Button
                color="primary"
                className="waves-effect waves-light shadow mt-0 btn-lg"
                onClick={() => handleNextStep(minSalary.stem, false)}
              >
                We’ll pay at least {minSalary.stem}
                <i className="bx bx-chevrons-right align-middle ml-1"></i>
              </Button>
            </p>
            <p className="mb-0 mt-4">
              <i className="bx bx-info-circle text-info" />{" "}
              <strong>
                You’ll need to pay the applicant at least this amount as a basic
                salary
              </strong>{" "}
              – you can give the applicant additional allowances or benefits in
              kind but they cannot be counted towards this minimum salary.
            </p>
          </>
        )
    } else {
      if (phdIsRelevant) {
        if (
          willApplicantBeUnder26 === true ||
          // willApplicantBeSwitching === true ||
          isApplicantBeingQualified === true ||
          isApplicantNewGraduate === true ||
          isApplicantSwitchingFromTier1 === true ||
          applicantWillBeGettingQualified === true ||
          applicantWillBeGettingRegistered === true ||
          isApplicantMovingIntoPhd === true
        )
          return (
            <>
              <p>
                You have confirmed that the applicant has a PhD that is relevant
                to the role and the applicant is eligible as a New Entrant.
              </p>
              <p className="mb-1">
                This means you can choose between 2 minimum salaries:{" "}
              </p>
              <h2 className="text-secondary mb-0 mt-2 pt-2 pb-0">
                {minSalary.phd}
              </h2>
              <p className="mb-1">
                If you pay at least this salary you will be able to apply for a
                visa of up to 5 years
              </p>
              <p>
                <Button
                  color="primary"
                  className="waves-effect waves-light shadow mt-0 btn-lg"
                  onClick={() => handleNextStep(minSalary.phd, false)}
                >
                  We’ll pay at least {minSalary.phd}
                  <i className="bx bx-chevrons-right align-middle ml-1"></i>
                </Button>
              </p>
              <p className="mt-3 mb-1" />
              <h2 className="text-secondary mb-0 mt-2 pt-2 pb-0">
                {minSalary.newEntrant}
              </h2>
              <p className="mb-1">
                If you pay at least this salary, but less than the amount above,
                you will only be able to apply for a visa of up to 4 years,
                assuming the applicant hasn’t held a Tier 2 or Skilled Worker
                visa before.
              </p>
              <p>
                <Button
                  color="primary"
                  className="waves-effect waves-light shadow mt-0 btn-lg"
                  onClick={() => handleNextStep(minSalary.newEntrant, true)}
                >
                  We’ll pay at least {minSalary.newEntrant}
                  <i className="bx bx-chevrons-right align-middle ml-1"></i>
                </Button>
              </p>
              <p className="mb-0 mt-4">
                <i className="bx bx-info-circle text-info" />{" "}
                <strong>
                  Whichever option you pick, you’ll need to pay the applicant at
                  least this amount as a basic salary
                </strong>{" "}
                – you can give the applicant additional allowances or benefits
                in kind but they cannot be counted towards this minimum salary.
              </p>
            </>
          )
        else
          return (
            <>
              <p>
                You have confirmed that the applicant has a PhD that is relevant
                to the role.
              </p>
              <p className="mb-1">The minimum salary for this position is: </p>
              <h2 className="text-secondary mb-0 mt-2 pt-2 pb-0">
                {minSalary.phd}
              </h2>
              <p className="mb-1">
                If you will pay at least this amount, click here to proceed to
                the next stage of the process <br />
              </p>
              <p>
                <Button
                  color="primary"
                  className="waves-effect waves-light shadow mt-0 btn-lg"
                  onClick={() => handleNextStep(minSalary.phd, false)}
                >
                  We’ll pay at least {minSalary.phd}
                  <i className="bx bx-chevrons-right align-middle ml-1"></i>
                </Button>
              </p>
              <p className="mb-0 mt-4">
                <i className="bx bx-info-circle text-info" />{" "}
                <strong>
                  You’ll need to pay the applicant at least this amount as a
                  basic salary
                </strong>{" "}
                – you can give the applicant additional allowances or benefits
                in kind but they cannot be counted towards this minimum salary.
              </p>
            </>
          )
      } else {
        if (
          willApplicantBeUnder26 === true ||
          // willApplicantBeSwitching === true ||
          isApplicantBeingQualified === true ||
          isApplicantNewGraduate === true ||
          isApplicantSwitchingFromTier1 === true ||
          applicantWillBeGettingQualified === true ||
          applicantWillBeGettingRegistered === true ||
          isApplicantMovingIntoPhd === true
        )
          return (
            <>
              <p>
                You have confirmed that the applicant is eligible as a New
                Entrant.
              </p>
              <p className="mb-1">
                This means you can choose between 2 minimum salaries:{" "}
              </p>
              <h2 className="text-secondary mb-0 mt-2 pt-2 pb-0">
                {minSalary.default}
              </h2>
              <p className="mb-1">
                If you pay at least this salary you will be able to apply for a
                visa of up to 5 years
              </p>
              <p>
                <Button
                  color="primary"
                  className="waves-effect waves-light shadow mt-0 btn-lg"
                  onClick={() => handleNextStep(minSalary.default, false)}
                >
                  We’ll pay at least {minSalary.default}
                  <i className="bx bx-chevrons-right align-middle ml-1"></i>
                </Button>
              </p>
              <p className="mt-3 mb-1" />
              <h2 className="text-secondary mb-0 mt-2 pt-2 pb-0">
                {minSalary.newEntrant}
              </h2>
              <p className="mb-1">
                If you pay at least this salary, but less than the amount above,
                you will only be able to apply for a visa of up to 4 years,
                assuming the applicant hasn’t held a Tier 2 or Skilled Worker
                visa before
              </p>
              <p>
                <Button
                  color="primary"
                  className="waves-effect waves-light shadow mt-0 btn-lg"
                  onClick={() => handleNextStep(minSalary.newEntrant, true)}
                >
                  We’ll pay at least {minSalary.newEntrant}
                  <i className="bx bx-chevrons-right align-middle ml-1"></i>
                </Button>
              </p>
              <p className="mb-0 mt-4">
                <i className="bx bx-info-circle text-info" />{" "}
                <strong>
                  Whichever option you pick, you’ll need to pay the applicant at
                  least this amount as a basic salary
                </strong>{" "}
                – you can give the applicant additional allowances or benefits
                in kind but they cannot be counted towards this minimum salary.
              </p>
            </>
          )
        else
          return (
            <>
              <p>No additional salary reductions apply.</p>
              <p className="mb-1">The minimum salary for this position is: </p>
              <h2 className="text-secondary mb-0 mt-2 pt-2 pb-0">
                {minSalary.default}
              </h2>
              <p className="mb-1">
                If you will pay at least this amount, click here to proceed to
                the next stage of the process <br />
              </p>
              <p>
                <Button
                  color="primary"
                  className="waves-effect waves-light shadow mt-0 btn-lg"
                  onClick={() => handleNextStep(minSalary.default, false)}
                >
                  We’ll pay at least {minSalary.default}
                  <i className="bx bx-chevrons-right align-middle ml-1"></i>
                </Button>
              </p>
              <p className="mb-0 mt-4">
                <i className="bx bx-info-circle text-info" />{" "}
                <strong>
                  You’ll need to pay the applicant at least this amount as a
                  basic salary
                </strong>{" "}
                – you can give the applicant additional allowances or benefits
                in kind but they cannot be counted towards this minimum salary.
              </p>
            </>
          )
      }
    }
  }

  return (
    <Card className="w3-animate-right">
      <CardBody>{getCardBody()}</CardBody>
      <CardFooter className="bg-transparent border-top">
        {/* <UndoRedo /> */}
        {/* <Button
          color="light"
          className="waves-effect waves-light"
          type="submit"
          onClick={props.handleReset}
        >
          <i className="mdi mdi-sync align-middle font-size-14"></i> Answer questions again
        </Button> */}
      </CardFooter>
    </Card>
  )
}

const mapStatetoProps = state => {
  return { ...state.Forms.present, ...state.Search }
}

export default connect(mapStatetoProps, {
  changeFormData,
})(withRouter(ResultsCard))
