import React, { useEffect, useState } from "react"
import { Card, CardTitle, CardBody, Badge, Collapse } from "reactstrap"
import axios from "axios"

const ExampleTasks = ({ soc }) => {
  const [appState, setAppState] = useState({ loading: false })
  const [col1, setcol1] = useState(false);

  useEffect(() => {
    setAppState({ loading: true })
    const apiUrl = `https://api.lmiforall.org.uk/api/v1/soc/code/${soc}`
    axios.get(apiUrl).then(res => {
      setAppState({ loading: false, ...res.data })
    })
  }, [])

  let tasksArray = appState.tasks && appState.tasks.split(";")

  if (!tasksArray) return <p>Loading...</p>

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle>Example job tasks</CardTitle>
          <ul className='pl-4 pt-2 mb-0'>
          {tasksArray
            .filter((task, i) => i < 7)
            .map((task, i) => (
              <li className="mb-2 text-muted" key={i}>
                {/* <i className="mdi mdi-circle-medium align-middle text-primary mr-1"></i>{" "} */}
                {task}
              </li>
            ))}
          </ul>  

          {tasksArray.length > 7 && (
            <div id="accordion">
              <Collapse isOpen={col1}>
                <ul className='pl-4 '>
                {tasksArray
                  .filter((task, i) => i > 6)
                  .map((task, i) => (
                    <li className="mb-2 text-muted" key={i}>
                      {/* <i className="mdi mdi-circle-medium align-middle text-primary mr-1"></i>{" "} */}
                      {task}
                    </li>
                  ))}
                 </ul> 
              </Collapse>
            </div>
          )}

{tasksArray.length > 7 && <h6 className="mt-2 mt-3 font-14" id="headingOne">
                <span
                  onClick={() => {
                    setcol1(!col1)
                  }}
                  style={{ cursor: "pointer" }}
                  className="text-dark"
                >
                  <span className="btn btn-light waves-effect waves-light btn-sm">
                    {col1 ? (
                      <>
                        View Less <i className="mdi mdi-chevron-up"></i>
                      </>
                    ) : (
                      <div>
                        View More <i className="mdi mdi-chevron-down"></i>
                      </div>
                    )}
                  </span>
                </span>
              </h6>}

        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ExampleTasks
