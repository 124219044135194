import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, Table } from "reactstrap";
import { changeFormData } from "../../store/actions";
import getMinSalary from "../../helpers/get-min-salary";

const AssessmentSalaryTable = (props) => {

  const {
    socData: { solRolesUK, solRolesScotland, isPhdeligible, getsGoingRateFromPayScale },
    socForm: {
      isSolJob,
      phdIsRelevant,
      phdIsStem,
      willApplicantBeUnder26,
      isApplicantBeingQualified,
      isApplicantMovingIntoPhd,
      solTitle,
      isApplicantNewGraduate,
      isApplicantSwitchingFromTier1,
      applicantWillBeGettingQualified,
      applicantWillBeGettingRegistered,
      agreedToProceedAsNewEntrant
    },
  } = props;

  const MinSalary = getMinSalary(props)

  return (
    <React.Fragment>
          <div className="table-responsive">
            <Table className="table border-top border-bottom mb-0" hover >
              <tbody>
                <tr>
                  <td>Default rate</td>
                  <td style={{ whiteSpace: "nowrap" }}>{MinSalary.noSol}</td>
                  <td>
                      <i className="bx bx-check align-top font-size-24 text-success "/>
                  </td>
                  <td>
                    This is the ‘default’ minimum salary that applies where no
                    other reductions are relevant
                  </td>
                </tr>

                {getsGoingRateFromPayScale === false && solRolesUK.length + solRolesScotland.length > 0 && (
                  <tr>
                    <td>The role is on the Shortage Occupation list</td>
                    <td style={{ whiteSpace: "nowrap" }}>{MinSalary.sol}</td>
                    <td>
                      {isSolJob ? (
                        <i className="bx bx-check align-top font-size-24 text-success "/>
                      ) : (
                        <i className="bx bx-x align-top font-size-24 text-warning"/>
                      )}
                    </td>
                    <td>
                      {solTitle ? (
                        <>
                          You have told us that the applicant will be working as
                          a {solTitle}. This role is on the Shortage Occupation
                          List
                        </>
                      ) : (
                        <>
                          {" "}
                          You have told us that the applicant{" "}
                          <strong>will not</strong> be performing one of the
                          roles on the Shortage Occupation List
                        </>
                      )}
                      {solRolesUK.length + solRolesScotland.length < 1 && (
                        <>
                          No roles within the occupation code you have selected are on
                          the Shortage Occupation List.
                        </>
                      )}
                    </td>
                  </tr>
                )}

                {/* don't show PhD options if user selects SOL role, because SOL roles are equivalent to STEM PhD */}
                {isPhdeligible && isSolJob !== true && (
                  <tr>
                    <td>The applicant has a PhD that is relevant to the role
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>{MinSalary.phd}</td>
                    <td>
                      {phdIsRelevant ? (
                        <i className="bx bx-check align-top font-size-24 text-success "/>
                      ) : (
                        <i className="bx bx-x align-top font-size-24 text-warning"/>
                      )}
                    </td>
                    <td>
                      {phdIsRelevant ? (
                        <>
                          You have told us that the applicant has a PhD that is
                          relevant to the role
                        </>
                      ) : (
                        <>
                          You have told us that the applicant{" "}
                          <strong>does not</strong> have a PhD that is relevant
                          to the role
                        </>
                      )}
                    </td>
                  </tr>
                )}

                {/* don't show PhD options if user selects SOL role, because SOL roles are equivalent to STEM PhD */}
                {isPhdeligible && isSolJob !== true && (
                  <tr>
                    <td>The applicant has a STEM PhD that is relevant to the role
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {MinSalary.stem}
                    </td>
                    <td>
                      {phdIsStem ? (
                        <i className="bx bx-check align-top font-size-24 text-success "/>
                      ) : (
                        <i className="bx bx-x align-top font-size-24 text-warning"/>
                      )}
                    </td>
                    <td>
                      {phdIsStem ? (
                        <>
                          You have told us that the applicant has a PhD in a
                          STEM subject that is relevant to the role
                        </>
                      ) : (
                        <>
                          You have told us that the applicant{" "}
                          <strong>does not</strong> have a PhD in a STEM subject
                          that is relevant to the role
                        </>
                      )}
                    </td>
                  </tr>
                )}

                {/* don't show New Entrant option for pay-scale codes */}
                {getsGoingRateFromPayScale === false &&
                  <tr>
                  <td>The applicant is a New Entrant and is applying for up to four years
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {MinSalary.newEntrant}
                  </td>
                  <td>
                    {willApplicantBeUnder26 === true ||
                    isApplicantBeingQualified === true ||
                    isApplicantNewGraduate === true ||
                    isApplicantSwitchingFromTier1 === true ||
                    applicantWillBeGettingQualified === true ||
                    applicantWillBeGettingRegistered === true ||
                    isApplicantMovingIntoPhd === true ? (
                      <i className="bx bx-check align-top font-size-24 text-success "/>
                    ) : agreedToProceedAsNewEntrant === '' ? (
                      <i className="bx bx-question-mark align-top font-size-20 text-info"/>
                    ): (
                      <i className="bx bx-x align-top font-size-24 text-warning"/>
                    )}
                  </td>
                  <td>
                    {willApplicantBeUnder26 === true ||
                    isApplicantBeingQualified === true ||
                    isApplicantNewGraduate === true ||
                    isApplicantSwitchingFromTier1 === true ||
                    applicantWillBeGettingQualified === true ||
                    applicantWillBeGettingRegistered === true ||
                    isApplicantMovingIntoPhd === true ? (
                      <>
                        You have told us that the applicant meets the criteria
                        to apply as a New Entrant
                      </>
                    ) : agreedToProceedAsNewEntrant === '' ? (
                      <>
                        You have not told us whether the applicant meets the criteria to apply as
                        a New Entrant
                      </>
                    ) : (
                      <>
                        You have told us that the applicant{" "}
                        <strong>does not</strong> meet the criteria to apply as
                        a New Entrant{" "}
                      </>
                    )
                  }
                  </td>
                </tr>}
              </tbody>
            </Table>
          </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  return { ...state.Forms.present, ...state.Search };
};

export default connect(mapStatetoProps, {
  changeFormData,
})(AssessmentSalaryTable);
